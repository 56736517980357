<footer>
    <div class="grid">
        <div>
            <div class="logo-grid">

            </div>
        </div>
        <div>
            <nav>
                <!-- <a routerLink="/about" matRipple>About Us</a> -->
                <a routerLink="/contact" matRipple>Contact Us</a>
                <a routerLink="/pp" matRipple>Privacy Policy</a>
                <a routerLink="/tos" matRipple>Terms of Service</a>
                <a routerLinkActive="active" routerLink="/login" matRipple [hidden]="IsAuthenticated">Login</a>
                <a (click)="logout()" matRipple [hidden]="!IsAuthenticated">Logout</a>
                <a routerLink="/admin" *ngIf="IsTopAdmin" matRipple>Admin Area</a>
            </nav>
        </div>
        <div>
            <p>
                Madison Missions Australia Ltd. <br> 19 Westleys Rd <br> Faulkland NSW 2422
            </p>
            <p>Ph: 0490 364 018</p>
        </div>
    </div>
    <p>
        &copy; 2020 Madison Missions Australia Ltd. &nbsp; | &nbsp; <br> ABN: 93 637 957 085 &nbsp; | &nbsp; Address: 19 Westleys Rd, Faulkland NSW 2422
    </p>
</footer>