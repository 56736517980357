import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-canvassing',
  templateUrl: './canvassing.component.html',
  styleUrls: ['./canvassing.component.scss']
})
export class CanvassingComponent implements OnInit {

  constructor(
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: 'Youth Canvassing Programs with Eastward Missions',
      Title: 'Youth Canvassing Programs with Eastward Missions',
      Description: 'The Literature Evangelism (canvassing) program is an opportunity for youth and young adults to experience front-line mission work here in Australia. Presenting our books of truth to the people is the very work the Lord would have us do at this time in world history.',
      Image: 'https://cdn2.tda.website/beehive/20201106/2017-Program-Group-Photo.jpg' + this.utils.defaultImageMetaSettings,
    });
  }

}
