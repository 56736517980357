<div class="grid" [ngStyle]="gridStyle" *ngIf="IsGallery">
    <div [routerLink]="baseUrl+'/'+img.id" (click)="saveScrollPos()" class="img mat-elevation-z5" *ngFor="let img of images" [ngStyle]="imageStyles[img.id]">
        <div class="icon">
            <mat-icon>launch</mat-icon>
        </div>
    </div>
</div>

<div class="image-viewer" *ngIf="!IsGallery">
    <div class="app-bar" style="color: #fff;">
        <a mat-button [routerLink]="baseUrl">
            Back to Gallery
        </a>
        <div class="spacer"></div>
        <button mat-icon-button [routerLink]="baseUrl">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="image-area">
        <div class="bg" [ngStyle]="{'background-image':'url(\''+imgSrc(image)+'\')'}"></div>
        <button *ngIf="previousImage" (click)="previous()" mat-icon-button class="previous">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <button *ngIf="nextImage" (click)="next()" mat-icon-button href="" class="next">
            <mat-icon>arrow_forward_ios</mat-icon>
        </button>
        <div class="previous-area" *ngIf="previousImage" (click)="previous()"></div>
        <div class="next-area" *ngIf="nextImage" (click)="next()"></div>
        <div class="image-slide-area" [ngClass]="slideClass" (touchend)="swipe($event, 'end')" (touchstart)="swipe($event, 'start')" (touchmove)="swipe($event, 'move')" [ngStyle]="{transform: sliderPosition ? 'translateX(calc(-100vw + '+sliderPosition+'px))' : null}">
            <div class="img-container" *ngFor="let img of [previousImage, image, nextImage]">
                <img [src]="imgSrc(img)" [ngClass]="{ current: image === img, prev: previousImage === img, next: nextImage === img }" [ngStyle]="imgStyle()" alt="">
            </div>
        </div>
    </div>
    <div class="action-bar" [ngStyle]="{background: image?.color || '#222'  }">
        <a mat-flat-button [ngStyle]="{background: image?.color || '#222', color: '#fff'  }" (click)="download()">
            <mat-icon>get_app</mat-icon>
            Download
        </a>
        <a mat-flat-button [ngStyle]="{background: image?.color || '#222', color: '#fff' }" (click)="share()">
            <mat-icon style="transform: scaleX(-1);">reply</mat-icon>
            Share
        </a>
    </div>
</div>