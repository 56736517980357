import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { CallResponse, DbService } from 'src/app/services/db.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ChooseAvatarComponent } from 'src/app/dialogs/choose-avatar/choose-avatar.component';
import { BlogCommentType, BlogCommentsFor, BlogLikeType, DataStore, MadisonBlogPostType, setTimeout$ } from 'src/app/services/DataStore';
import { LoginProvidersDialogComponent } from 'src/app/dialogs/login-providers-dialog/login-providers-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DOCUMENT } from '@angular/common';
import { UtilsService } from 'src/app/services/utils.service';
import { PromptComponent } from 'src/app/dialogs/prompt/prompt.component';
import { ActivatedRoute } from '@angular/router';

interface NestedComment extends BlogCommentType {
  Replies?: BlogCommentType[];
}

interface TypingIndicator {
  Comment: string;
  Post: string;
  Owner: string;
  ID: string;
  Type: string;
  Avatar: string;
}

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {

  @Input() for!: BlogCommentsFor;

  sort = 'newest';
  authenticating = false;
  comment = '';
  isPostingComment = false;
  showReplyBox: { [id: string]: boolean } = {};
  totalNum = 0;

  commentMenuIsOwner = false;
  currentContexComment: NestedComment | null = null;

  defaultAvatar = 'https://cdn2.tda.website/beehive/20200826/adult-2221018.svg';

  Comments: NestedComment[] = [];

  authorNames: string[] = [];
  mentionIsopen = false;
  socket: WebSocket | null = null;
  typingIndicatorTimeout: NodeJS.Timeout | null = null;
  typingIndicatorsNow: { [commentID: string]: TypingIndicator[] } = {};

  CommentToHighlight: string | null = null;

  tabIndex = 0;

  logout = () => this.auth.logout();

  constructor(
    public auth: AuthService,
    public db: DbService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private utils: UtilsService,
    private route: ActivatedRoute,
  ) { }

  onAuthChange = (loggedIn: boolean) => {

    if (this.socket) {
      this.socket.close();
    }

    if (loggedIn) {

      if (!this.auth.person?.Avatar) {
        this.pickAvatar();
      }

      // BlogCommentType|TypingIndicator
      this.socket = this.db.Subscribe<any>(['BlogComment', 'TypingIndicator'], (comment) => {

        if (comment.Key === this.for.Key) {
          comment = comment as BlogCommentType;
          if (comment.ParentComment) {
            const ParentComment = DataStore.singletons[comment.ParentComment] as NestedComment;
            if (ParentComment) {
              ParentComment.Replies = ParentComment.Replies || [];
              if (!ParentComment.Replies.includes(comment) && comment.Owner !== this.auth.person?.ID) {
                ParentComment.Replies.push(comment);
                ParentComment.Replies.sort((a, b) => {
                  return a.Time - b.Time;
                });
              }
            }
          } else {
            if (!this.Comments.includes(comment) && comment.Owner !== this.auth.person?.ID) {
              this.Comments.push(comment);
              this.Comments.sort((a, b) => {
                return a.Time - b.Time;
              });
            }
          }
        } else if (comment.Type === 'ti') {
          const indicator = comment as TypingIndicator;
          if (indicator.Owner !== this.auth.person?.ID && indicator.Post === this.for.Key) {
            const key = indicator.Comment || '_';
            this.typingIndicatorsNow[key] = this.typingIndicatorsNow[key] || [];
            for (const i of [...this.typingIndicatorsNow[key]]) {
              if (i.Owner === indicator.Owner) {
                this.utils.delete(this.typingIndicatorsNow[key], i);
              }
            }
            this.typingIndicatorsNow[key].push(indicator);
            setTimeout$(() => {
              this.utils.delete(this.typingIndicatorsNow[key], indicator);
            }, 2500);
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.loadComments();

    this.auth.onStateChange(this.onAuthChange);

    this.route.queryParamMap.subscribe(map => {
      const commentID = map.get('comment');
      if (commentID) {
        this.CommentToHighlight = commentID;
      }
    });

  }

  ngOnDestroy(): void {
    this.auth.removeStateChangeCallback(this.onAuthChange);
    if (this.socket) {
      this.socket.close();
    }
  }

  mentionOpened(): void {
    this.mentionIsopen = true;
  }

  mentionClosed(): void {
    setTimeout$(() => {
      this.mentionIsopen = false;
    }, 0);
  }

  checkCommentMenu(c: BlogCommentType): void {
    this.commentMenuIsOwner = this.auth.isTopAdmin() || this.auth.person?.ID === c.Owner;
    this.currentContexComment = c;
  }

  async report(): Promise<void> {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: 'Report this comment to moderators?',
    });

    ref.afterClosed().subscribe(async yes => {
      if (yes) {
        await this.db.q('reportComment', {
          comment: this.currentContexComment?.ID,
        });
      }
    });
  }

  edit(): void {
    const ref = this.dialog.open(PromptComponent, {
      data: {
        label: 'Edit comment',
        value: this.currentContexComment?.TXT,
        multiLine: true,
      },
      width: '500px',
    });

    ref.afterClosed().subscribe(val => {
      if (val && this.currentContexComment !== null) {
        this.currentContexComment.TXT = val;
        this.db.put(this.currentContexComment);
      }
    });
  }

  delete(): void {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: 'Delete this comment?',
    });

    ref.afterClosed().subscribe(yes => {
      if (yes) {
        if (this.currentContexComment?.Replies && this.currentContexComment.Replies.length) {

          this.currentContexComment.Deleted = 'true';
          this.currentContexComment.TXT = '(deleted)';
          this.db.put(this.currentContexComment);

        } else {

          this.db.RemoveFromDataStore(this.currentContexComment);
          this.db.q('deleteBlogComment', {
            comment: this.currentContexComment?.ID,
          });
          const Post = DataStore.singletons[this.for.Key] as MadisonBlogPostType;
          Post.Comments = Post.Comments || 0;
          Post.Comments--;
          this.totalNum--;

          if (this.currentContexComment?.ParentComment) {
            for (const c of this.Comments) {
              if (c.ID === this.currentContexComment.ParentComment && typeof c.Replies !== 'undefined') {
                this.utils.delete(c.Replies, this.currentContexComment);
                break;
              }
            }
          } else {
            this.utils.delete(this.Comments, this.currentContexComment);
          }
        }
      }
    });
  }

  async loadComments(): Promise<void> {
    if (this.for.for === 'blog') {
      const Comments: NestedComment[] = Array.from(
        await this.db.query('BlogComment', 'Key', this.for.Key, false, 0)
      ) || [];
      this.totalNum = Comments.length;
      const commentIDs: string[] = [];
      const replies: { [id: string]: NestedComment[] } = {};
      for (const reply of Comments) {
        if (typeof reply.ID !== 'undefined') {
          commentIDs.push(reply.ID);
          replies[(reply.ParentComment || '_')] = replies[(reply.ParentComment || '_')] || [];
          replies[(reply.ParentComment || '_')].push(reply);
        }
      }
      for (const comment of Comments) {
        if (typeof comment.ID !== 'undefined' && replies[comment.ID]) {
          comment.Replies = replies[comment.ID];
        }
      }

      for (const key of Object.keys(replies)) {
        replies[key].sort((a, b) => {
          return a.Time - b.Time;
        });
      }

      if (replies._) {
        this.Comments = replies._;
      }

      this.setTabIndexes(this.Comments, 10);

      const OwnerIDs: string[] = [...new Set(Comments.map(o => o.Owner || ''))];
      let Owners = (await this.db.q('getCommentAuthors', { Owners: JSON.stringify(OwnerIDs) })).Data;
      if (Owners === 'null') {
        Owners = [];
      }

      for (const o of Owners) {
        this.db.authors[o.ID] = o;
        if (!this.authorNames.includes(o.First + o.Last)) {
          this.authorNames.push(o.First + o.Last);
        }
      }
      this.loadLikes();
    }
  }

  setTabIndexes(comments: NestedComment[], index: number): number {
    for (const c of comments) {
      c.TabIndex = index;
      index++;
      if (c.Replies) {
        index = this.setTabIndexes(c.Replies, index);
      }
    }
    return index;
  }

  checkKey(ev: KeyboardEvent): void {
    if (ev.key === 'Enter' && !ev.shiftKey && !this.mentionIsopen) {
      ev.preventDefault();
      if (this.auth.getSessionValue()) {
        this.postComment();
      } else {
        const ref = this.dialog.open(LoginProvidersDialogComponent, {
          width: '500px',
        });
        ref.afterClosed().subscribe(async val => {
          if (this.auth.authChecked) {
            this.postComment();
          }
        });
      }
    }
  }

  checkReplyKey(ev: KeyboardEvent, c: BlogCommentType): void {
    if (ev.key === 'Enter' && !ev.shiftKey && !this.mentionIsopen) {
      const v = (ev.target as HTMLInputElement).value;
      ev.preventDefault();
      if (this.auth.getSessionValue()) {
        this.postReply(c, v);
        (ev.target as HTMLInputElement).value = '';
      } else {
        const ref = this.dialog.open(LoginProvidersDialogComponent, {
          width: '500px',
        });
        ref.afterClosed().subscribe(async val => {
          if (this.auth.authChecked) {
            this.postReply(c, v);
            (ev.target as HTMLInputElement).value = '';
          }
        });
      }
    }
    this.sendTypingIndicator(c);
  }

  sendTypingIndicator(c?: BlogCommentType): void {
    if (!this.typingIndicatorTimeout) {
      this.typingIndicatorTimeout = setTimeout(() => {
        if (this.auth.authChecked) {

          const indicator: TypingIndicator = {
            Comment: c?.ID || '',
            Post: this.for.Key,
            Owner: this.auth.person?.ID || '',
            ID: this.utils.uid(),
            Type: 'ti',
            Avatar: this.auth.person?.Avatar || this.defaultAvatar,
          };

          this.db.q('blogTypingIndicator', {
            obj: JSON.stringify(indicator),
          });
        }
        this.typingIndicatorTimeout = null;
      }, 1500);
    }
  }

  async postReply(c: NestedComment, value: string): Promise<void> {
    if (value.trim() !== '') {
      const comment: BlogCommentType = {
        ID: this.utils.uid(),
        Key: this.for.Key,
        Time: (new Date()).getTime(),
        TXT: value,
        ParentComment: c.ID,
      };
      if (this.auth.person) {
        this.db.authors[this.auth.person?.ID || ''] = this.auth.person;
      }
      c.Replies = c.Replies || [];
      c.Replies.push(comment);
      await this.writeComment(comment);
    }
  }

  async postComment(): Promise<void> {
    if (this.comment.trim() !== '') {
      const comment: BlogCommentType = {
        ID: this.utils.uid(),
        Key: this.for.Key,
        Time: (new Date()).getTime(),
        TXT: this.comment,
      };
      this.comment = '';
      if (this.auth.person) {
        this.db.authors[this.auth.person?.ID || ''] = this.auth.person;
      }
      this.Comments.unshift(comment);

      await this.writeComment(comment);
    }
  }

  async writeComment(c: BlogCommentType): Promise<CallResponse> {

    const Post = DataStore.singletons[this.for.Key] as MadisonBlogPostType;
    Post.Comments = Post.Comments || 0;
    Post.Comments++;
    this.totalNum++;

    return await this.db.q('writeBlogComment', {
      comment: JSON.stringify(this.db.addRowDefaults(c, 'BlogComment')),
      id: c.ID,
      uniq: c.ParentComment || c.ID,
      key: this.for.Key,
    });
  }

  async loadLikes(): Promise<void> {
    if (this.auth.getSessionValue()) {
      let Likes: BlogLikeType[];
      try {
        Likes = Array.from((await this.db.q('getBlogCommentLikes', {
          key: this.for.Key
        }))?.Data);
        if (Likes && Likes.length) {
          for (const like of Likes) {
            this.db.likedComments[like.Key] = true;
          }
        }
      } catch (e) { }
    }
  }

  async likeComment(c: BlogCommentType): Promise<void> {
    if (typeof c.ID === 'undefined') {
      return;
    }
    if (!this.auth.getSessionValue()) {
      const ref = this.dialog.open(LoginProvidersDialogComponent, {
        width: '500px',
      });
      ref.afterClosed().subscribe(async val => {
        if (this.auth.authChecked) {
          await this.loadLikes();
          if (c.ID && !this.db.likedComments[c.ID]) {
            this.likeComment(c);
          }
        }
      });
    } else {
      this.db.likedComments[c.ID] = !this.db.likedComments[c.ID];
      c.Likes = c.Likes || 0;
      if (this.db.likedComments[c.ID]) {
        c.Likes++;
      } else {
        c.Likes--;
      }
      await this.db.q('likeBlogComment', { id: c.ID });
    }
  }

  async replyToComment(c: NestedComment, c2?: NestedComment): Promise<void> {
    if (!c.ID) {
      return;
    }
    this.showReplyBox[c.ID] = true;
    setTimeout(() => {
      const el = this.document.getElementById('reply_input_' + c.ID) as HTMLInputElement;
      if (c2 && c2.Owner && c2.Owner !== this.auth.person?.ID) {
        el.value = '@' + this.db.authors[c2.Owner].First + this.db.authors[c2.Owner].Last + '  ';
      }
      el.focus();
    });
  }

  pickAvatar(): void {
    if (this.auth.authChecked) {
      const dialogRef = this.dialog.open(ChooseAvatarComponent, {
        width: '800px',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (typeof res === 'string' && this.auth.person && this.auth.person.ID) {
          this.auth.person.Avatar = res;
          this.db.addSchemaKey('Person', 'Avatar');
          this.db.put('Person', this.auth.person);
          this.db.authors[this.auth.person.ID] = this.auth.person;
        }
      });
    }
  }

}
