<app-eastward-header-nav></app-eastward-header-nav>
<div theme="transparent" style="text-align: center;" id="canvassing-header">
    <header>
        <h1>Youth Canvassing Programs</h1>
        <p>Dec / Jan every year</p>
    </header>
</div>
<div id="info-grid">
    <div class="item green">
        <i class="icon"><!--?xml version="1.0" encoding="UTF-8"?--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M4,2C2.9,2 2,2.9 2,4V16C2,17.1 2.9,18 4,18H8V21C8,21.55 8.45,22 9,22H9.5V22C9.75,22 10,21.9 10.2,21.71L13.9,18H20C21.1,18 22,17.1 22,16V4C22,2.89 21.1,2 20,2H4M4,4H20V16H13.08L10,19.08V16H4V4M12.19,5.5C11.3,5.5 10.59,5.68 10.05,6.04C9.5,6.4 9.22,7 9.27,7.69C0.21,7.69 6.57,7.69 11.24,7.69C11.24,7.41 11.34,7.2 11.5,7.06C11.7,6.92 11.92,6.85 12.19,6.85C12.5,6.85 12.77,6.93 12.95,7.11C13.13,7.28 13.22,7.5 13.22,7.8C13.22,8.08 13.14,8.33 13,8.54C12.83,8.76 12.62,8.94 12.36,9.08C11.84,9.4 11.5,9.68 11.29,9.92C11.1,10.16 11,10.5 11,11H13C13,10.72 13.05,10.5 13.14,10.32C13.23,10.15 13.4,10 13.66,9.85C14.12,9.64 14.5,9.36 14.79,9C15.08,8.63 15.23,8.24 15.23,7.8C15.23,7.1 14.96,6.54 14.42,6.12C13.88,5.71 13.13,5.5 12.19,5.5M11,12V14H13V12H11Z"></path></svg></i>
        <h2>What?</h2>
        <p> <strong>Intensive, 10 week Literature Evangelism Program.</strong></p>
        <p>Fully catered & structured. Students are encouraged to attend for the full program, but may attend for shorter segments.</p>
    </div>
    <div class="item">
        <i class="icon"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
            <path d="M14.7,17.1C13.9,17.7,13,18,12,18s-1.9-0.3-2.7-0.9c-0.2-0.2-0.5-0.1-0.7,0.1s-0.1,0.5,0.1,0.7C9.6,18.6,10.8,19,12,19  s2.4-0.4,3.3-1.1c0.2-0.2,0.3-0.5,0.1-0.7C15.2,17,14.9,16.9,14.7,17.1z"></path>
            <circle cx="8.5" cy="12.5" r="1"></circle>
            <path d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M20,14.8c-1.1,3.7-4.3,6.5-8,6.5c-3.8,0-7-2.7-8-6.5  c-1.2-0.1-2.1-1.2-2.1-2.5c0-1.3,0.8-2.3,2-2.5c2.1-1.5,3.8-3.5,4.1-5.1v0c1.4,2.6,6.3,5.2,11.8,5.1l0.3,0c1.3,0,2.3,1.1,2.3,2.5  C22.2,13.7,21.2,14.8,20,14.8z"></path>
            <circle cx="15.5" cy="12.5" r="1"></circle>
            </svg>
        </i>
        <h2>For Who?</h2>
        <p>Any young person (15+) who would like to experience front-line mission work with a vibrant team of like-minded young people.</p>
    </div>
    <div class="item green">
        <i class="icon"><!--?xml version="1.0" encoding="UTF-8"?--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,11.5C10.62,11.5 9.5,10.38 9.5,9C9.5,7.62 10.62,6.5 12,6.5C13.38,6.5 14.5,7.62 14.5,9C14.5,10.38 13.38,11.5 12,11.5M12,2C8.13,2 5,5.13 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9C19,5.13 15.87,2 12,2Z"></path></svg></i>
        <h2>Next Location</h2>
        <p>South Australia</p>
    </div>
    <div class="item">
        <i class="icon"><!--?xml version="1.0" encoding="UTF-8"?--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M9,10H7V12H9V10M13,10H11V12H13V10M17,10H15V12H17V10M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.9 20.1,3 19,3M19,19H5V8H19V19Z"></path></svg></i>
        <h2>Next Dates</h2>
        <p>December 2022 - January 2023</p>
    </div>
</div>

<div id="more-info">
    <div class="txt">
        <h2>More Info on the Canvassing Program</h2>
        <p><span style="font-size:18px;">The Literature Evangelism (canvassing)&nbsp;program&nbsp;is an opportunity for youth and young adults to experience front-line mission work here in Australia. Presenting our books of truth to the people is the very work the Lord would have us do at this time in world&nbsp;history.</span></p>
        <p><strong>Costs</strong><br> No up-front fee is required to attend the program. Students receive a commission of donations raised, minus book, food and fuel expenses.</p>
        <p><strong>What is Literature Evangelism?</strong><br> Literature Evangelism is all about making a difference in communities through books that promote health, Christian family values, the good news of the gospel and prophecy.</p>
        <p>The top priority of the program is to leave books with as many people as are willing to read them. Books are left with people on a donation basis. Donations cover book costs, program costs and help out the student canvassers.</p>
        <p>Going door to door doing this kind of work&nbsp;is an excellent form of missionary work and great preparation for anyone wanting to devote their life to God in ministry service.</p>
        <p><strong>Extras to Bring</strong><br> The book "Colporteur Ministry"&nbsp;<br> Sleeping mat and bedding</p>
        <p><strong>Invitation</strong><br> We would like to extend an invitation to all interested youth and young adults to fill in an&nbsp;application. We look forward to working with you.</p>
    </div>
    <div class="image">
        <img src="https://cdn2.tda.website/beehive/20201106/IMG-20180822-120446-01.jpeg?h=500&w=500&fit=crop&auto=format" alt="">
    </div>
</div>

<div id="gallery">
    <a mat-flat-button routerLink="/Canvassing-Photos">View Photo Gallery</a>
</div>