import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {

  prompt: FormGroup | null = null;

  constructor(
    public dialogRef: MatDialogRef<PromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      label: string,
      value?: string,
      multiLine?: boolean,
    }) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {

    this.prompt = new FormGroup({

      input: new FormControl(this.data.value || '', [Validators.required]),

    });

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.prompt?.controls[controlName].hasError(errorName) || false;
  }

  confirm(): void {

    const Value = this.prompt?.value.input;

    this.dialogRef.close(Value);

  }

}
