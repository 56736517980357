import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TosComponent {

  siteTitle = 'Eastward Missions';
  websiteDomain = 'www.eastward.edu.au';
  sitePhone1 = '0420 746 255';
  siteEmail = 'pr@eastward.edu.au';
  siteAddress = '19 Westleys Rd, Faulkland NSW 2422';


}
