<app-back-header title="My Addresses" theme="grey50" back="/my-account"></app-back-header>

<div style="max-width: 1100px; padding: 5%;">
    <div class="address-list">
        <div class="address-box" *ngFor="let adr of MyAddresses">
            <div class="inner">
                <app-show-address [adr]="adr"></app-show-address>
                <div class="btns">
                    <app-button theme="grey50" label="Edit" (click)="edit(adr)"></app-button>
                    <app-button theme="grey50" label="Delete" (click)="delete(adr)"></app-button>
                </div>
            </div>
        </div>
    </div>

    <form [formGroup]="addressForm" *ngIf="addressForm" autocomplete="off" novalidate (ngSubmit)="save()" [hidden]="!AddAddress && MyAddresses.length">

        <h2>{{ (Editing ? 'Edit' : 'Add') + ' Address' }}</h2>

        <mat-form-field class="field">
            <input matInput tabindex="1" formControlName="name" placeholder="Your Name" type="text">
            <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
        </mat-form-field>

        <mat-form-field class="field">
            <input matInput tabindex="3" formControlName="mobile" placeholder="Mobile" type="text">
            <mat-error *ngIf="hasError('mobile', 'required')">Mobile is required</mat-error>
        </mat-form-field>

        <mat-form-field class="field">
            <input matInput tabindex="4" formControlName="instructions" placeholder="Delivery Instructions" type="text">
        </mat-form-field>

        <mat-form-field class="field">
            <mat-label>Address line 1</mat-label>
            <input matInput tabindex="6" formControlName="address1" placeholder="Address line 1" type="text">
            <mat-error *ngIf="hasError('address1', 'required')">Address is required</mat-error>
        </mat-form-field>

        <mat-form-field class="field">
            <mat-label>Address line 2</mat-label>
            <input matInput tabindex="7" formControlName="address2" placeholder="Address line 2" type="text">
        </mat-form-field>

        <mat-form-field class="field">
            <mat-label>Suburb</mat-label>
            <input matInput tabindex="8" formControlName="suburb" placeholder="Suburb" type="text">
            <mat-error *ngIf="hasError('suburb', 'required')">Suburb is required</mat-error>
        </mat-form-field>

        <mat-form-field class="field">
            <mat-label>State</mat-label>
            <mat-select tabindex="9" formControlName="state">
                <mat-option value="VIC">VIC</mat-option>
                <mat-option value="NSW">NSW</mat-option>
                <mat-option value="WA">WA</mat-option>
                <mat-option value="TAS">TAS</mat-option>
                <mat-option value="QLD">QLD</mat-option>
                <mat-option value="SA">SA</mat-option>
                <mat-option value="ACT">ACT</mat-option>
                <mat-option value="NT">NT</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="field">
            <input matInput tabindex="10" formControlName="postcode" placeholder="Postcode" type="number">
            <mat-error *ngIf="hasError('postcode', 'required')">Postcode is required</mat-error>
        </mat-form-field>

    </form>

    <div class="address-btns">
        <button [hidden]="!AddAddress" (click)="cancel()">Cancel</button>
        <button [hidden]="AddAddress" (click)="AddAddress = true">Add Address</button>
        <app-button [hidden]="!AddAddress && MyAddresses.length" label="Save" [loading]="isProcessing" theme="primary" (click)="save()"></app-button>
    </div>
</div>