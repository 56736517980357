<div id="thanks">
    <h1>Thank you for your donation!</h1>
    <p>We are so thankful for your support.</p>
</div>
<h1 *ngIf="!Donation" class="loading">Loading...</h1>
<div class="outer" *ngIf="Donation">
    <div class="wrapper">
        <div class="head">
            <div class="left">
                <div class="vert">Donation</div>
                <div class="txt">
                    Receipt #: {{ Donation.DonationID }} <br> {{ Donation.createdAt | date:'mediumDate' }}
                </div>
            </div>
            <div class="right mobile-hide">
                <img src="https://cdn2.tda.website/beehive/20200607/Madison-Missions-Logo.svg" class="logo">
            </div>
        </div>
        <div class="head" style="align-items: flex-start;">
            <div class="a">
                <h2 class="grey">Customer Details</h2>
                <div>
                    {{ Donation.First }} {{ Donation.Last }} <br> &lt;{{ Donation.Email }}&gt;
                </div>
            </div>
            <div class="b">
                <h2 class="grey">Payment to</h2>
                <div>
                    Madison Missions Australia Ltd. <br> ABN: 93 637 957 085 <br> 19 Westleys Rd <br> Faulkland NSW 2422 <br> mail@madison.org.au <br>
                </div>
            </div>
        </div>

        <div class="body">
            <table cellspacing="0">
                <thead>
                    <th>
                        <h2 class="grey align-left">Description</h2>
                    </th>
                    <th>
                        <h2 class="grey align-right">Price</h2>
                    </th>
                </thead>
                <tr>
                    <td class="align-left">Donation</td>
                    <td class="align-right">{{ Donation.Amount | currency }}</td>
                </tr>
                <tr>
                    <td class="align-right">TOTAL:</td>
                    <td class="align-right">{{ Donation.Amount | currency }}</td>
                </tr>
                <tr>
                    <td class="align-right">GST:</td>
                    <td class="align-right">{{ 0 | currency }}</td>
                </tr>
            </table>
            <hr>
            <div class="foot">
                <div>Status: Paid</div>
                <div>Paid by {{ Donation.CardBrand }} ending in {{ Donation.CardLast4 }}</div>
            </div>
        </div>
        <div class="print-btn no-print"><app-button theme="lightGrey" label="print" (click)="print()"></app-button></div>
        <!-- <div style="padding: 20px; text-align: center;">
            <app-button theme="grey50" (click)="delete()" label="Delete Donation" style="display: inline-block;"></app-button>
        </div> -->
    </div>