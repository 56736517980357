import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-ambassadors',
  templateUrl: './ambassadors.component.html',
  styleUrls: ['./ambassadors.component.scss']
})
export class AmbassadorsComponent implements OnInit {

  constructor(
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    // this.utils.updateMeta({});
  }

}
