import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { PersonType, AddressType } from 'src/app/services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StripeToken, StripeCard } from 'stripe-angular';
import { UtilsService } from 'src/app/services/utils.service';
import { DonationPmtService } from 'src/app/services/donationPmt.service';

@Component({
  selector: 'app-account-payment-sources',
  templateUrl: './account-payment-sources.component.html',
  styleUrls: ['./account-payment-sources.component.scss']
})
export class AccountPaymentSourcesComponent implements OnInit, OnDestroy {

  @ViewChild('stripeCard') stripeCard!: StripeCard;

  Person: PersonType | null = null;
  AddPaymentSource = false;
  isProcessing = false;
  selectedAddress: AddressType | null = null;
  cardToken!: StripeToken;
  cardProcessing: any;
  stripeOptions: any = {
    hidePostalCode: true,
  };

  onAuthChange = () => {
    this.Person = this.auth.person;
  }

  constructor(
    private auth: AuthService,
    private payments: DonationPmtService,
    public snackBar: MatSnackBar,
    private db: DbService,
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    this.auth.onStateChange(this.onAuthChange);
    this.loadAdr();
  }

  async loadAdr(): Promise<void> {
    const adrs = await this.db.list('Address');
    this.selectedAddress = Array.from(adrs)[0];
  }

  ngOnDestroy(): void {
    this.auth.removeStateChangeCallback(this.onAuthChange);
  }

  handleStripeError(obj: any): boolean {
    if (obj.type === 'StripeCardError') {

      const Error = obj.raw as any;

      this.snackBar.open('Card Error: ' + Error.message, 'Dismiss', {
        duration: 12000,
      });

      return true;

    }
    return false;
  }

  async remove(card: any): Promise<void> {

    if (confirm('Remove ' + card.brand + '?')) {
      this.cardProcessing[card.id] = true;
      const res = await this.payments.removeCard(card.id) as any;

      if (res.Person) {
        this.auth.person = res.Person as PersonType;
        this.Person = this.auth.person;
      }

    }

  }

  async save(): Promise<void> {

    if (this.isProcessing) {
      return;
    }

    if (!this.selectedAddress) {
      this.snackBar.open('Please add an address to your account before adding a payment source.', 'Dismiss', {
        duration: 6000,
      });
      return;
    }

    this.isProcessing = true;

    try {

      let StripeAdrObj = {
        name: this.selectedAddress.name,
        address_city: this.selectedAddress.suburb,
        address_line1: this.selectedAddress.address1,
        address_line2: this.selectedAddress.address2,
        address_state: this.selectedAddress.state,
        address_zip: this.selectedAddress.postcode.toString(),
      };

      StripeAdrObj = this.utils.replaceValue(StripeAdrObj, null, '');

      const CardToken = await this.stripeCard.createToken(StripeAdrObj);

      if (CardToken) {

        const res = await this.payments.saveCard(CardToken, {
          line1: this.selectedAddress.address1,
          city: this.selectedAddress.suburb,
          line2: this.selectedAddress.address2,
          state: this.selectedAddress.state,
          country: 'AU',
          postal_code: this.selectedAddress.postcode.toString(),
        }) as any;

        if (!this.handleStripeError(res)) {

          this.auth.person = res.Person as PersonType;
          this.Person = this.auth.person;

          this.snackBar.open('Payment source added.', 'Dismiss', {
            duration: 6000,
          });

          this.AddPaymentSource = false;

          this.isProcessing = false;

        } else {
          this.isProcessing = false;
        }

      } else {

        this.isProcessing = false;

        this.snackBar.open('Invalid card details.', 'Dismiss', {
          duration: 6000,
        });

      }

    } catch (e) {

      this.isProcessing = false;

      if (e.message) {
        this.snackBar.open('ERROR: ' + e.message, 'Dismiss', {
          duration: 12000,
        });
      } else {
        this.snackBar.open('ERROR: Card error.', 'Dismiss', {
          duration: 12000,
        });
      }

    }

  }

}
