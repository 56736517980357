<router-outlet></router-outlet>
<div class="loading" *ngIf="loading">
    <mat-spinner [strokeWidth]="2"></mat-spinner>
</div>
<div class="posts" *ngIf="!loading">
    <div class="Logic_Wrapper">
        <div class="advert post more" style="padding-top: 40px;padding-bottom: 12px;">
            <div class="picture">
                <div class="stem" [ngStyle]="{'background-color': categoryMeta.Madison.color}"></div>
                <div class="stem-post" *ngIf="posts[posts.length-1]" [ngStyle]="stemJoin({Category: 'Madison'}, posts[0])"></div>
            </div>
            <div class="body">
                <div class="actions load" style="position: relative;">
                    <a routerLink="/all-posts" class="view-all">View all posts</a>
                </div>
            </div>
        </div>
    </div>
    <div class="Logic_Wrapper" *ngFor="let post of posts; let i = index;" [ngSwitch]="post.isAdvert">
        <div class="advert post" *ngSwitchCase="true">
            <div class="bg"></div>
            <div class="time" [ngStyle]="{'border-color': categoryMeta[post.Category].color, 'color': categoryMeta[post.Category].txt || categoryMeta[post.Category].color}">
                <div class="arrow" [ngStyle]="{'border-color': 'transparent transparent transparent ' + categoryMeta[post.Category].color}"></div>
                <img [src]="post.advertIcon" alt="">
            </div>
            <div class="picture click" (click)="advertAction(post)">
                <div class="stem-pre" *ngIf="posts[i-1]" [ngStyle]="stemJoin(posts[i-1], post)"></div>
                <div class="stem" [ngStyle]="{'background-color': categoryMeta[post.Category].color}"></div>
                <div class="stem-post" *ngIf="posts[i+1]" [ngStyle]="stemJoin(post, posts[i+1])"></div>
                <img *ngIf="post.Images" [src]="post.Images[0] + '?w=150&h=150&fit=crop'">
            </div>
            <div class="body">
                <h1 (click)="advertAction(post)" class="click advert">{{ post.Title }}</h1>
                <div class="actions">
                    <button mat-stroked-button (click)="advertAction(post)">{{ post.CallToActionTitle }}</button>
                </div>
            </div>
        </div>
        <div class="post" *ngSwitchCase="false">
            <div class="bg"></div>
            <div class="ago">
                <div class="txt">{{ ((post.createdAt || '') | toDate) | timeAgo }}</div>
            </div>
            <div class="time" [ngStyle]="{'border-color': categoryMeta[post.Category].color, 'color': categoryMeta[post.Category].txt || categoryMeta[post.Category].color}">
                <div class="arrow" [ngStyle]="{'border-color': 'transparent transparent transparent ' + categoryMeta[post.Category].color}"></div>
                <img [src]="categoryMeta[post.Category].image" alt="">
            </div>
            <div class="picture click" (click)="open(post)">
                <div class="stem-pre" *ngIf="posts[i-1]" [ngStyle]="stemJoin(posts[i-1], post)"></div>
                <div class="stem" [ngStyle]="{'background-color': categoryMeta[post.Category].color}"></div>
                <div class="stem-post" *ngIf="posts[i+1]" [ngStyle]="stemJoin(post, posts[i+1])"></div>
                <img *ngIf="post.Images && post.Images.length && !post.Videos?.length" [src]="post.Images[0] + '?w=150&h=150&fit=crop'">
                <div class="vid-image" *ngIf="post.Videos && post.Videos?.length" [ngStyle]="{'background-image': videoUrl(post.Videos[0])}"></div>
            </div>
            <div class="body">
                <h1 (click)="open(post)" class="click">{{ post.Title }}</h1>
                <div class="txt" (click)="open(post)">
                    {{ post.Content }}
                    <div class="ellipse"></div>
                </div>
                <div class="actions">
                    <button mat-button (click)="open(post)">READ MORE</button>
                    <button mat-button color="primary" *ngIf="post.CallToActionLink" [ngStyle]="{color: categoryMeta[post.Category].txt || categoryMeta[post.Category].color}" (click)="cta(post)">{{ post.CallToActionTitle || post.CallToActionLink }}</button>
                </div>
                <div class="div"></div>
                <app-news-social-summary [post]=post></app-news-social-summary>
            </div>
        </div>
    </div>
    <div class="Logic_Wrapper" *ngIf="isMore">
        <div class="advert post more">
            <div class="picture">
                <div class="stem-pre" *ngIf="posts[posts.length-1]" [ngStyle]="stemJoin(posts[posts.length-1], {Category: 'Madison'})"></div>
                <div class="stem" [ngStyle]="{'background-color': categoryMeta.Madison.color}"></div>
            </div>
            <div class="body">
                <div class="actions load" style="position: relative;">
                    <button appInView (intersect)="intersected($event)" [ngStyle]="{visibility: isLoadingMore ? 'hidden' : 'visible'}" mat-stroked-button (click)="loadMore()">
                        <mat-spinner *ngIf="autoLoadPosts" color="primary" diameter="20"></mat-spinner>&nbsp;&nbsp;
                        <mat-icon *ngIf="!autoLoadPosts">add</mat-icon>
                        {{ autoLoadPosts ? 'LOADING' : 'MORE POSTS'}}
                    </button>
                    <mat-spinner [strokeWidth]="2" diameter="36" style="position: absolute; top:0; left:0;" *ngIf="isLoadingMore"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</div>