import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.hasSessionValue()) {
      this.router.navigate(['login'], { queryParams: { return: state.url } });
      return false;
    }
    return true;
  }
}

@Injectable()
export class AdminAuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.auth.getSessionValue() || !this.auth.person) {
      await this.auth.checkSession();
    }
    if (!this.auth.isTopAdmin()) {
      this.router.navigate(['login'], { queryParams: { return: state.url } });
      return false;
    }
    return true;
  }
}
