import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef, PLATFORM_ID, InjectionToken } from '@angular/core';
import * as typeformEmbed from '@typeform/embed';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit, AfterViewInit {

  @ViewChild('form') div!: ElementRef;
  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    if (this.isBrowser) {

      typeformEmbed.makeWidget(this.div.nativeElement, 'https://madisonmissions.typeform.com/to/lf2tm9', {
        hideFooter: true,
        hideHeaders: true,
        opacity: 0,
      });

    }

  }

}
