<app-eastward-header-nav></app-eastward-header-nav>
<!-- <div id="volunteer-application" class="mat-elevation-z6">
    <div class="txt">Volunteer opportunities <br> available</div><br>
    <button mat-button (click)="volunteerApp()">Apply Online</button>
</div> -->

<!-- WELCOME TO Eastward  -->
<!-- src="https://cdn2.tda.website/beehive/20200607/Australia-Globe-fixed.jpg?fp-x=0.58&fp-y=0.49&fp-z=1.1&q=36&crop=focalpoint&h=1080&w=1920&fit=crop&blend-color=42000000&blend-mode=normal&auto=format" -->
<div id="welcome" style="background:#222;">
    <video #video autoplay loop muted playsinline></video>
    <div class="outer-wrapper">
        <h2 class="madison">Eastward Missions</h2>
        <div class="home-wrapper">
            <div class="main-top">
                <h1>Youth Ministry <br> in Australia</h1>
                <p>Establishing a 21st-century Madison College</p>
                <!-- <div class="btns2">
                    <app-button theme="transparent" (click)="watchStory()" label="Watch the Story"></app-button>
                    <app-button theme="transparent" (click)="open('https://gofundmadison.com')" label="Donate"></app-button>
                    <app-button theme="transparent" (click)="open('https://madisonmissions.typeform.com/to/lf2tm9')" label="Watch the Story"></app-button>
                </div> -->
                <div class="btns">
                    <button>
                    <img src="https://cdn2.tda.website/beehive/20201106/NFnpZaKC-Madison-logo-home.svg" title="Madison Missions Australia Ltd.">
                    </button>
                    <button>
                        <img src="https://cdn2.tda.website/beehive/20201106/XqpoZTON-Eastward-logo-home.svg" title="Eastward Missions">
                    </button>
                    <button>
                        <img src="https://cdn2.tda.website/beehive/20201106/qZOlDopt-Beehive-logo-home.svg" title="Beehive Community Services">
                    </button>
                    <button>
                        <img src="https://cdn2.tda.website/beehive/20201106/ggDWuGdP-Maker-Heals-logo-home.svg" title="The Maker Heals">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div id="lightOnaHill">
    <div class="inner">
        <img src="https://cdn2.tda.website/beehive/20201126/Light-on-a-Hill_1.svg" alt="Light on a Hill - Camp Meeting">
        <div class="info mobile-hide">
            <div class="col">
                <h2>Camp Meeting</h2>
                <p>(COVID permitting)</p>

            </div>
            <div class="col">
                <div class="venue">Abide Wellness Retreat</div>
                <div class="dates">January 14-17</div>
            </div>
            <div class="col" style="padding-left: 40px;">
                Ministry & business in action <br> Interactive workshops <br> Inspiring messages & music
            </div>
        </div>
        <div class="action mobile-hide">
            <a mat-stroked-button routerLink="/Light-on-a-Hill-Camp-Meeting">More Information</a> &nbsp;
            <a mat-stroked-button href="https://madisonmissions.typeform.com/to/FqED9S8l" target="_blank">Register</a>
        </div>
    </div>
    <div class="mobile-info mobile-show">
        <div class="info">
            <div class="col">
                <h2>Camp Meeting</h2>
                <p>(COVID permitting)</p>

            </div>
            <div class="col">
                <div class="venue">Abide Wellness Retreat</div>
                <div class="dates">January 14-17</div>
            </div>
            <div class="col" style="padding-left: 40px;">
                Ministry & business in action <br> Interactive workshops <br> Inspiring messages & music
            </div>
        </div>
        <div class="action">
            <a mat-stroked-button routerLink="/Light-on-a-Hill-Camp-Meeting">More Information</a> &nbsp;
            <a mat-stroked-button href="https://madisonmissions.typeform.com/to/FqED9S8l" target="_blank">Register</a>
        </div>
    </div>
</div> -->

<div id="event-grid">
    <div [ngClass]="{ open: GridState == 'mc' }" (click)="setGridState('mc')" id="mc" mat-ripple [matRippleDisabled]="GridState == 'mc'" [ngStyle]="cardImg('20201106/DSC-4353.jpg')">
        <div class="inner">
            <h2>Music Camp 2024</h2>
            <div class="card" (click)="GridState == 'mc' && $event.stopPropagation();">
                <p>Do you enjoy making music and are you interested in being part of a Christian music film production? Receive professional training & awesome fellowship.</p>
                <mat-divider></mat-divider>
                <div class="event-details">
                    <div class="date">
                        <mat-icon>event</mat-icon>
                        <div class="txt">Dec 31 2023 - Jan 14, 2024</div>
                    </div>
                    <div class="location">
                        <mat-icon>place</mat-icon>
                        <div class="txt">Bethshan Camp & Conference Centre <br> 70 Wyee Rd, Wyee NSW 2259</div>
                    </div>
                    <div class="location">
                        <mat-icon>attach_money</mat-icon>
                        <div class="txt">
                            From $520
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="actions">
                    <a mat-stroked-button href="https://mus.camp/" target="_blank">More Info</a>
                    <a mat-stroked-button href="https://www.youtube.com/playlist?list=PLTQwl81WrpQxg1In9jIKubHGhoMrHp5_J" target="_blank">Videos</a>
                    <a mat-stroked-button routerLink="/Music-Camp-Photos">Photos</a>
                </div>
            </div>
        </div>
        <button mat-icon-button class="indicator">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
    </div>
    <div [ngClass]="{ open: GridState == 'le' }" (click)="setGridState('le')" mat-ripple [matRippleDisabled]="GridState == 'le'" id="canvassing" [ngStyle]="cardImg('20201106/viber-image-2019-12-20-17-54-37.jpg')">
        <div class="inner">
            <h2>Literature Evangelism</h2>
            <div class="card" (click)="GridState == 'le' && $event.stopPropagation();">
                <p>For any young person, 15+ who would like to experience front-line mission work with an evangelistic team of like-minded young people.</p>
                <mat-divider></mat-divider>
                <div class="event-details">
                    <div class="date">
                        <mat-icon>event</mat-icon>
                        <div class="txt">Date TBA</div>
                    </div>
                    <div class="location">
                        <mat-icon>place</mat-icon>
                        <div class="txt">Location TBA</div>
                    </div>
                    <div class="location">
                        <mat-icon>attach_money</mat-icon>
                        <div class="txt">FREE</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="actions">
                    <a mat-stroked-button routerLink="/Canvassing">More Info</a>
                    <a mat-stroked-button routerLink="/Canvassing-Photos">Photos</a>
                </div>
            </div>
        </div>
        <button mat-icon-button class="indicator">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
    </div>
    <div [ngClass]="{ open: GridState == 'md' }" (click)="setGridState('md')" id="md" mat-ripple [matRippleDisabled]="GridState == 'md'" [ngStyle]="cardImg('20201124/Madison-Banner-Vision.jpg')">
        <div class="inner">
            <h2>Madison College</h2>
            <div class="card" (click)="GridState == 'md' && $event.stopPropagation();">
                <p>Eastward is working on establishing a 21st century Madison Missionary College here in Australia. Check out the vision and see what you can do to help!</p>
                <div class="actions">
                    <a mat-stroked-button href="https://www.madison.org.au/vision" target="_blank">Discover the Vision</a>
                    <a mat-stroked-button href="https://www.madison.org.au/" target="_blank">www.madison.org.au</a>
                </div>
            </div>
        </div>
        <button mat-icon-button class="indicator">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
    </div>
    <div [ngClass]="{ open: GridState == 'mk' }" (click)="setGridState('mk')" id="mk" mat-ripple [matRippleDisabled]="GridState == 'mk'" [ngStyle]="cardImg('20201124/Madison-Promo.00_16_49_11.Still006.jpg')">
        <div class="inner">
            <h2>The Maker Heals</h2>
            <div class="card" (click)="GridState == 'mk' && $event.stopPropagation();">
                <p>A revolutionary healthy lifestyle program with an emphasis on spiritual wellbeing and renewal.</p>
                <div class="actions">
                    <a mat-stroked-button href="https://www.makerheals.org.au/" target="_blank">www.makerheals.org.au</a>
                </div>
            </div>
        </div>
        <button mat-icon-button class="indicator">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
    </div>
    <div [ngClass]="{ open: GridState == 'mg' }" (click)="setGridState('mg')" id="mg" mat-ripple [matRippleDisabled]="GridState == 'mg'" [ngStyle]="cardImg('20201125/MagabooksRender2.jpg')">
        <div class="inner">
            <h2>Magabooks</h2>
            <div class="card" (click)="GridState == 'mg' && $event.stopPropagation();">
                <p>View Eastward's flagship magabooks: 'The Great Controversy' and 'Wholesome Homemade'. Available for Literature Evangelists and organisations online at wholesale rates.</p>
                <div class="actions">
                    <a mat-stroked-button routerLink="/Magabooks">View Prices</a>
                </div>
            </div>
        </div>
        <button mat-icon-button class="indicator">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
    </div>
    <div [ngClass]="{ open: GridState == 'bv' }" (click)="setGridState('bv')" id="bv" mat-ripple [matRippleDisabled]="GridState == 'bv'" [ngStyle]="cardImg('20200529/0-02-07-1eabfefff66bec1c17c8378737d98a552b4f81f279f9914719c34f1e19d8bd9e-d44846ec.jpg')">
        <div class="inner">
            <h2>Beehive Services</h2>
            <div class="card" (click)="GridState == 'bv' && $event.stopPropagation();">
                <p>An industry model to support ministry and to train young people.</p>
                <div class="actions">
                    <a mat-stroked-button href="https://beehive.services/" target="_blank">beehive.services</a>
                    <a mat-stroked-button href="https://beehivetree.services/" target="_blank">beehivetree.services</a>
                </div>
            </div>
        </div>
        <button mat-icon-button class="indicator">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
    </div>
    <div [ngClass]="{ open: GridState == 'ag' }" (click)="setGridState('ag')" mat-ripple [matRippleDisabled]="GridState == 'ag'" id="cultivate" [ngStyle]="cardImg('20201110/AdobeStock-231566167.jpeg')">
        <div class="inner">
            <h2>Cultivate 2021</h2>
            <div class="card" (click)="GridState == 'ag' && $event.stopPropagation();">
                <p>Home gardeners, farmers, tree changers, educators, parents, home schoolers, young people - whether you're an absolute beginner with a brown thumb, planning your move to the country, an experienced agriculturalist, or just interested in
                    the healthiest of lifestyles - this conference is for you.</p>
                <mat-divider></mat-divider>
                <div class="event-details">
                    <div class="date">
                        <mat-icon>event</mat-icon>
                        <div class="txt">Date TBA</div>
                    </div>
                    <div class="location">
                        <mat-icon>place</mat-icon>
                        <div class="txt">Location TBA</div>
                    </div>
                    <div class="location">
                        <mat-icon>attach_money</mat-icon>
                        <div class="txt">Cost TBA</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="actions">
                    <a mat-stroked-button href="https://cultivateconference.com.au/" target="_blank">More Info</a>
                </div>
            </div>
        </div>
        <button mat-icon-button class="indicator">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
    </div>
    <div [ngClass]="{ open: GridState == 'dz' }" (click)="setGridState('dz')" mat-ripple [matRippleDisabled]="GridState == 'dz'" id="dsozo" [ngStyle]="cardImg('20201106/DSozo-WA-2017-006.jpg')">
        <div class="inner">
            <h2>D'Sozo Camp WA</h2>
            <div class="card" (click)="GridState == 'dz' && $event.stopPropagation();">
                <p>D'Sozo camp is a very unique opportunity for youth to be involved in mission service. The primary focus of camp is christian help-work, music production, canvassing (literature evangelism) and door-to-door community surveys.</p>
                <mat-divider></mat-divider>
                <div class="event-details">
                    <div class="date">
                        <mat-icon>event</mat-icon>
                        <div class="txt">Date TBA</div>
                    </div>
                    <div class="location">
                        <mat-icon>place</mat-icon>
                        <div class="txt">Location TBA</div>
                    </div>
                    <div class="location">
                        <mat-icon>attach_money</mat-icon>
                        <div class="txt">Cost TBA</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="actions">
                    <a mat-stroked-button href="https://dsozo.camp/" target="_blank">More Info</a>
                    <a mat-stroked-button href="https://www.youtube.com/playlist?list=PLTQwl81WrpQzJD1SXBNNwE_GYde4_fQmM" target="_blank">Videos</a>
                    <a mat-stroked-button routerLink="/Dsozo-Camp-Photos">Photos</a>
                </div>
            </div>
        </div>
        <button mat-icon-button class="indicator">
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>
    </div>
</div>

<app-section theme="lightGreyOrange">
    <img src="https://cdn2.tda.website/beehive/20201117/Madison-updates-4x.png?w=400" style="max-width: 300px; display: block; margin: auto; height: 300px;" alt="">
    <div style="text-align: center;">
        <app-button theme="transparent" style="display: inline-block;" (click)="open('https://madisonmissions.typeform.com/to/hzful2Nj')" label="Join the Mailing List"></app-button>
    </div>
</app-section>

<!-- <app-ambassador-program></app-ambassador-program> -->

<app-activity-stream [embedded]="true"></app-activity-stream>