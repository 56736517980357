<div mat-dialog-content class="mat-typography">
    <form  [formGroup]="prompt" *ngIf="prompt" (ngSubmit)="confirm()">
        <mat-form-field style="width: 100%;" appearance="outline">
            <input matInput formControlName="input" cdkFocusInitial #input="matInput" [placeholder]="data.label" *ngIf="!data.multiLine">
            <mat-label *ngIf="data.multiLine">{{ data.label }}</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="input" *ngIf="data.multiLine" cdkFocusInitial #input="matInput" [placeholder]="data.label"> </textarea>
            <mat-error *ngIf="hasError('input', 'required')">This is required</mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button color="primary" [disabled]="!prompt?.valid" (click)="confirm()">Confirm</button>
</div>