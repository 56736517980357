import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-light-on-a-hill-camp-meeting',
  templateUrl: './light-on-a-hill-camp-meeting.component.html',
  styleUrls: ['./light-on-a-hill-camp-meeting.component.scss']
})
export class LightOnAHillCampMeetingComponent implements OnInit {

  constructor(private utils: UtilsService) {
  }

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: 'Light on a Hill - Camp Meeting',
      Title: 'Light on a Hill - Camp Meeting',
      Description: '',
      Image: 'https://cdn2.tda.website/beehive/20201126/Light-on-a-Hill-80.jpg' + this.utils.defaultImageMetaSettings
    });
  }

}
