import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-watch-story',
  templateUrl: './watch-story.component.html',
  styleUrls: ['./watch-story.component.scss']
})
export class WatchStoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
