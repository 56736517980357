import { isPlatformBrowser } from '@angular/common';
import { Directive, ViewContainerRef, AfterViewInit, Output, EventEmitter, PLATFORM_ID, Inject, InjectionToken, OnDestroy } from '@angular/core';

export interface AppIntersection {
  isVisible: boolean;
  firstTimeVisible: boolean;
  scrollingDirection: 'up' | 'down';
  scrollY: number;
  scrollX: number;
}

@Directive({
  selector: '[appInView]'
})
export class InViewDirective implements AfterViewInit, OnDestroy {

  isBrowser: boolean;
  observer: IntersectionObserver | undefined;

  @Output() intersect: EventEmitter<AppIntersection> = new EventEmitter();
  firstTime = true;
  lastYAxis: number | null = null;

  constructor(
    private vcRef: ViewContainerRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void {
    const commentEl = this.vcRef.element.nativeElement; // template
    const elToObserve = commentEl.parentElement;
    if (this.isBrowser) {
      this.observer = new IntersectionObserver(entries => {
        entries.map((entry) => {
          const intersection: AppIntersection = {
            firstTimeVisible: this.firstTime,
            scrollingDirection: this.lastYAxis === null ? 'down' : (this.lastYAxis < entry.boundingClientRect.y ? 'up' : 'down'),
            isVisible: false,
            scrollY: entry.boundingClientRect.y,
            scrollX: entry.boundingClientRect.x,
          };
          this.lastYAxis = entry.boundingClientRect.y;
          if (entry.isIntersecting) {
            this.firstTime = false;
            intersection.isVisible = true;
          } else {
            intersection.isVisible = false;
          }
          this.intersect.emit(intersection);
        });
      }, { threshold: [0, .1, .9, 1] });
      this.observer.observe(elToObserve);
    }
  }

  ngOnDestroy(): void {
    if (this.isBrowser && this.observer) {
      this.observer.disconnect();
    }
  }

}
