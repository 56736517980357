import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { logging } from 'protractor';
import { AuthService } from 'src/app/services/auth.service';
import { InvoiceRowType, InvoiceType } from 'src/app/services/DataStore';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-view-receipt',
  templateUrl: './view-receipt.component.html',
  styleUrls: ['./view-receipt.component.scss']
})
export class ViewReceiptComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthService,
    private db: DbService,
    private route: ActivatedRoute,
  ) { }

  id = '';
  Inv: InvoiceType | null = null;
  Rows: InvoiceRowType[] = [];
  authenticated = false;

  onAuthChange = (loggedIn: boolean) => {
    console.log('auth state', loggedIn);
    this.authenticated = loggedIn;
    if (loggedIn) {
      this.load();
    }
  }

  ngOnInit(): void {
    this.auth.onStateChange(this.onAuthChange);
    this.route.queryParamMap.subscribe(map => {
      this.id = map.get('id') || '';
      if (this.authenticated) {
        this.load();
      }
    });
    this.load();
  }

  ngOnDestroy(): void {
    this.auth.removeStateChangeCallback(this.onAuthChange);
  }

  async load(): Promise<void> {

    if (this.auth.authChecked) {

      const res1 = await this.db.query('Invoice', 'InvoiceID', this.id);

      this.Inv = Array.from(res1)[0] as InvoiceType;

      const res2 = await this.db.query('InvoiceRow', 'Invoice', this.id);

      this.Rows = Array.from(res2);

      this.Rows.sort((a, b) => {
        return (a.Sort || 0) - (b.Sort || 0);
      });

    }

  }

}
