import { Router } from '@angular/router';
// import { LoginData } from './../screens/auth/login/login.component';
import { Injectable } from '@angular/core';
import { CallResponse, DbService } from './db.service';
import { UtilsService } from './utils.service';
// import { SignupData } from '../screens/auth/signup/signup.component';
import { PersonType } from './DataStore';

export interface SessionStore {
  Expires: number;
  Expired: boolean;
  Person: string;
  PersonObj: PersonType;
  Email: string;
  SessionID: string;
  TopAdmin: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authChecked = false;
  personID: string | null = null;
  sessionObj!: SessionStore;
  person: PersonType | null = null;

  constructor(
    private db: DbService,
    private utils: UtilsService,
    private router: Router,
  ) { }

  stateChangeCallbacks: Array<(AuthState: boolean) => void> = [];

  isTopAdmin(): boolean {

    return this.authChecked && this.sessionObj && this.sessionObj.TopAdmin;

  }

  logout(): Promise<any> {

    const sessionID = this.getSessionValue();

    this.db.likedComments = {};
    this.db.likedPosts = {};

    this.destroySession();

    return this.db.q('auth:logout', { session: sessionID });

  }

  async login(data: any): Promise<boolean> {

    const LoginResponse = await this.db.q('auth:login', data);

    if (LoginResponse.Success) {

      const SessionObj: SessionStore = LoginResponse.Data;

      localStorage.setItem('session', SessionObj.SessionID);

      this.db.AuthSession = this.sessionObj;

      this.authChecked = true;

      await this.checkSession();

    }

    return LoginResponse.Success;

  }

  async checkSession(): Promise<boolean> {

    if (!this.getSessionValue()) {

      return false;

    }
    const AuthCheckResponse = await this.db.q('auth:check', { session: this.getSessionValue() });

    if (AuthCheckResponse.internetConnection) {

      this.sessionObj = AuthCheckResponse.Data;

      if (AuthCheckResponse.Success) {

        this.db.AuthSession = this.sessionObj;

        this.personID = AuthCheckResponse.Data.Person;

        const PersonObj = await this.db.get('Person', this.personID || '') as PersonType;

        if (!PersonObj.__typename) {
          await this.db.put('Person', PersonObj);
        }

        this.person = PersonObj;
        this.sessionObj.PersonObj = PersonObj;

        this.authChecked = true;
        this.runStateChangeCallbacks();

      } else {

        this.destroySession();

        if (!this.router.url.includes('/login')) {
          this.router.navigateByUrl('/login?return=' + this.router.url);
        }

      }

    }

    return AuthCheckResponse.Success;

  }

  hasSessionValue(): boolean {

    return this.getSessionValue() !== null;

  }

  getSessionValue(): string | null {

    return localStorage.getItem('session');

  }

  destroySession(): void {

    localStorage.removeItem('session');
    this.authChecked = false;
    this.person = null;
    this.personID = null;

    this.runStateChangeCallbacks();

  }

  signup(data: any): Promise<CallResponse> {

    data.ID = this.utils.uid();

    return this.db.q('auth:signup', data);

  }

  runStateChangeCallbacks(): void {
    const Authenticated = this.authChecked && this.hasSessionValue();
    for (const fn of this.stateChangeCallbacks) {
      fn(Authenticated);
    }
  }

  onStateChange(callback: (AuthState: boolean) => void): void {

    this.stateChangeCallbacks.push(callback);

    callback(this.authChecked && this.hasSessionValue());

  }

  removeStateChangeCallback(callback: (AuthState: boolean) => void): void {
    this.utils.delete(this.stateChangeCallbacks, callback);
  }

}
