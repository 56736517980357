import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-open-post-image',
  templateUrl: './open-post-image.component.html',
  styleUrls: ['./open-post-image.component.scss']
})
export class OpenPostImageComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public image: string,
    @Inject(WINDOW) private window: Window,
  ) { }

  screenWidth = 0;
  screenHeight = 0;
  imageWidth = 0;
  imageHeight = 0;
  padding = 30;

  ngOnInit(): void {
    this.screenWidth = this.window.innerWidth;
    this.screenHeight = this.window.innerHeight;
    this.imageWidth = this.window.innerWidth + (this.window.innerWidth * 0.25);
    this.imageHeight = this.window.innerHeight + (this.window.innerHeight * 0.25);
  }

}
