import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {

  constructor(
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: `404 Error`,
      Title: `404 Error`,
      Description: `404 Error`,
    });
  }

}
