import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DbService } from 'src/app/services/db.service';
import { detect } from 'detect-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-recover-pwd',
  templateUrl: './recover-pwd.component.html',
  styleUrls: [
    '../login/login.component.scss',
    './recover-pwd.component.scss'
  ]
})
export class RecoverPwdComponent implements OnInit {

  activity = false;
  recoverForm: FormGroup | null = null;

  constructor(
    private db: DbService,
    public snackBar: MatSnackBar,
  ) { }


  ngOnInit(): void {
    this.recoverForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  async recover(value: any): Promise<void> {
    this.activity = true;
    const Email = value.email;
    const result = detect() as any;
    await this.db.q('auth:recoverPwd', {
      Email,
      Browser: result.name,
      OS: result.os,
    });

    this.snackBar.open('If your email address is in the system, you will receive a password reset email.', 'Dismiss', {
      duration: 6000,
    });

    this.activity = false;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.recoverForm?.controls[controlName].hasError(errorName) || false;
  }

}
