<div class="comments">
    <div class="header">
        <div class="a">{{ totalNum }} Comment{{ totalNum === 1 ? '' : 's' }}</div>
        <div class="b"><button mat-stroked-button (click)="logout()" [ngStyle]="{visibility: auth.authChecked ? null : 'hidden'}">LOGOUT</button></div>
    </div>
    <div class="main-comment">
        <div class="avatar" (click)="pickAvatar()" matRipple>
            <img [title]="auth.person?.First + ' ' + auth.person?.Last" [src]="auth.person?.Avatar || defaultAvatar" alt="">
        </div>
        <div class="box">
            <mat-form-field appearance="outline" class="discussion" [ngClass]="{loggedIn: auth.authChecked}">
                <mat-label>{{ auth.authChecked ? auth.person?.First + ', join the discussion!' : 'Join the Discussion' }}</mat-label>
                <textarea cols="30" rows="2" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" matInput placeholder=" Type your comment here..." (keydown)="checkKey($event)" [(ngModel)]="comment" *ngIf="auth.authChecked" [mention]="authorNames" (opened)="mentionOpened()"
                    (closed)="mentionClosed()"></textarea>
                <input matInput type="text" placeholder=" Type your comment here..." *ngIf="!auth.authChecked" [(ngModel)]="comment">
            </mat-form-field>
        </div>
    </div>
    <div class="login" *ngIf="!auth.authChecked">
        <div class="authenticating" *ngIf="authenticating">
            <mat-spinner color="primary" diameter="30"></mat-spinner>
        </div>
        <div class="oauth">
            <app-login-with-provider></app-login-with-provider>
        </div>
    </div>
    <mat-menu #commentMenu="matMenu" [overlapTrigger]="true">
        <div *ngIf="commentMenuIsOwner">
            <button mat-menu-item (click)="edit()">Edit</button>
            <button mat-menu-item (click)="delete()">Delete</button>
        </div>
        <div *ngIf="!commentMenuIsOwner">
            <button mat-menu-item (click)="report()">Report Comment</button>
        </div>
    </mat-menu>
    <div class="replies">
        <div class="reply" *ngFor="let comment of Comments" [tabindex]="comment.TabIndex" [id]="comment.ID" [appAutoFocus]="CommentToHighlight === comment.ID">   
            <div class="body">
                <div class="avatar">
                    <img [src]="db.authors[(comment.Owner || '')]?.Avatar || defaultAvatar" alt="">
                </div>
                <div class="outerTxtWrap">
                    <div class="txt">
                        <strong class="name">{{ db.authors[(comment.Owner || '')]?.First + ' ' + db.authors[(comment.Owner || '')]?.Last }}</strong>
                        <div class="wrote">{{ comment.TXT }}</div>
                        <div class="likes btn mat-elevation-z4" *ngIf="comment.Likes">
                            <div class="svg">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12px" height="12px" viewBox="0 0 18.4 16.3" style="enable-background:new 0 0 18.4 16.3;" xml:space="preserve">
                        <defs>
                        </defs>
                        <g>
                        <path d="M15.2,11.3L15.2,11.3c0.6,0,1.2,0.3,1.2,1.2c0,0.6-0.6,0.9-1.2,0.9h-0.9c0.6,0,1.2,0.3,1.2,0.9
                        c0,0.6-0.3,0.9-0.9,0.9c0,0-3.2,0-5.1,0c-2.2,0-2.8-0.5-5.4-0.9V8C6,8,9.2,4.5,9.2,1.5c0-1.2,1.7-1.6,2.3,0.5
                        c0.4,1.3-0.8,4.2-0.8,4.2h6c0.6,0,1.2,0.6,1.2,1.2c0,0.6-0.4,1.5-1.2,1.5h-0.9c0.8,0,1.5,0.6,1.5,1.2c0,0.6-0.6,1.2-1.2,1.2H15.2"
                        />
                        <polygon points="4.1,15.8 0.5,15.8 0.5,6.8 4.1,6.8 	"/>
                        <g>
                        <path d="M2.8,14c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4S3,14,2.8,14L2.8,14z"/>
                        </g>
                        </g>
                        </svg>
                            </div>
                            <div class="num">{{ comment.Likes }}</div>
                        </div>

                        <button mat-icon-button color="white" *ngIf="comment.Deleted !== 'true'">
                        <mat-icon [matMenuTriggerFor]="commentMenu" (click)="checkCommentMenu(comment)">more_horiz</mat-icon>
                    </button>
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="a"></div>
                <div class="b">
                    <button matRipple (click)="likeComment(comment)" class="like" [ngClass]="{liked: db.likedComments[(comment.ID || '')]}">Like</button>
                    <button matRipple (click)="replyToComment(comment)">Reply</button>
                    <span class="time">{{ (comment.Time | timeAgo) }}</span>
                </div>
            </div>
            <div class="commentReplies">
                <div class="make-reply" *ngFor="let reply of comment.Replies" [id]="reply.ID" [tabindex]="reply.TabIndex" [appAutoFocus]="CommentToHighlight === reply.ID">
                    <div class="avatar">
                        <img [src]="db.authors[(reply.Owner || '')]?.Avatar || defaultAvatar" alt="">
                    </div>
                    <div>
                        <div class="mr-inner" style="display: inline-block;">
                            <div class="input">
                                <strong class="name">{{ db.authors[(reply.Owner || '')]?.First + ' ' + db.authors[(reply.Owner || '')]?.Last }}</strong>
                                <div class="wrote">{{ reply.TXT }}</div>
                            </div>
                            <button mat-icon-button color="white" [matMenuTriggerFor]="commentMenu" (click)="checkCommentMenu(reply)" *ngIf="reply.Deleted !== 'true'">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                            <div class="likes btn mat-elevation-z4" *ngIf="reply.Likes">
                                <div class="svg">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12px" height="12px" viewBox="0 0 18.4 16.3" style="enable-background:new 0 0 18.4 16.3;" xml:space="preserve">
                            <defs>
                            </defs>
                            <g>
                            <path d="M15.2,11.3L15.2,11.3c0.6,0,1.2,0.3,1.2,1.2c0,0.6-0.6,0.9-1.2,0.9h-0.9c0.6,0,1.2,0.3,1.2,0.9
                            c0,0.6-0.3,0.9-0.9,0.9c0,0-3.2,0-5.1,0c-2.2,0-2.8-0.5-5.4-0.9V8C6,8,9.2,4.5,9.2,1.5c0-1.2,1.7-1.6,2.3,0.5
                            c0.4,1.3-0.8,4.2-0.8,4.2h6c0.6,0,1.2,0.6,1.2,1.2c0,0.6-0.4,1.5-1.2,1.5h-0.9c0.8,0,1.5,0.6,1.5,1.2c0,0.6-0.6,1.2-1.2,1.2H15.2"
                            />
                            <polygon points="4.1,15.8 0.5,15.8 0.5,6.8 4.1,6.8 	"/>
                            <g>
                            <path d="M2.8,14c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4S3,14,2.8,14L2.8,14z"/>
                            </g>
                            </g>
                            </svg>
                                </div>
                                <div class="num">{{ reply.Likes }}</div>
                            </div>
                        </div>
                        <div class="actions" style="display: block">
                            <button matRipple (click)="likeComment(reply)" class="like" [ngClass]="{liked: db.likedComments[(reply.ID || '')]}">Like</button>
                            <button matRipple (click)="replyToComment(comment, reply)">Reply</button>
                            <span class="time">{{ (reply.Time | timeAgo) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="make-reply last" *ngIf="showReplyBox[(comment.ID || '')]">
                <div class="avatar">
                    <img [title]="auth.person?.First + ' ' + auth.person?.Last" [src]="auth.person?.Avatar || defaultAvatar" alt="">
                </div>
                <div class="mr-inner">
                    <div class="input">
                        <textarea cdkTextareaAutosize cols="30" rows="1" placeholder="Write a reply..." (keydown)="checkReplyKey($event, comment)" [id]="'reply_input_' + comment.ID" [mention]="authorNames" (opened)="mentionOpened()" (closed)="mentionClosed()"></textarea>
                    </div>
                </div>
            </div>
            <div class="indicator make-reply" *ngFor="let i of typingIndicatorsNow[(comment.ID || '')]">
                <div class="avatar">
                    <img [src]="i.Avatar" alt="">
                </div>
                <div class="b">
                    <div class="typing-indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>