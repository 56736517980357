import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    this.utils.updateMeta(
      {
        BrowserTitle: 'About Us - Eastward Missions',
        Description: 'We are passionate followers of Jesus Christ and members of the Seventh-day Adventist Church. Our approach gives us opportunities to supply programs to individuals and to churches in a totally complementary manner.',
        Title: 'About Us - Eastward Missions',
        Image: `https://cdn2.tda.website/beehive/20201126/2020-11-26_181845.jpg${this.utils.defaultImageMetaSettings}`,
      }
    );
  }

}
