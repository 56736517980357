import { Component, OnInit, Inject, Input, PLATFORM_ID, InjectionToken } from '@angular/core';
import { MadisonBlogPostType, StaffAppUserType, CategoryMeta, VideoType, setTimeout$ } from '../../services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NavigationEnd, Router } from '@angular/router';
import { StringToDatePipe } from 'src/app/pipes/toDate';
import { WINDOW } from 'src/app/services/window.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginProvidersDialogComponent } from 'src/app/dialogs/login-providers-dialog/login-providers-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { AppIntersection } from 'src/app/directives/in-view.directive';
import { MyNotificationsComponent } from 'src/app/screens/my-notifications/my-notifications.component';
import { isPlatformBrowser } from '@angular/common';

let NewsScrollPos = 0;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  @Input() autoLoadPosts = true;
  isBrowser: boolean;
  posts: MadisonBlogPostType[] = [];
  users: StaffAppUserType[] = [];
  usersById: { [id: string]: StaffAppUserType } = {};
  loading = true;
  isMore = true;
  isLoadingMore = false;
  page = 1;
  perPage = 13;
  url: string;
  base = 'https://cdn2.tda.website/beehive/';

  constructor(
    private db: DbService,
    private router: Router,
    private toDate: StringToDatePipe,
    private utils: UtilsService,
    private dialog: MatDialog,
    private auth: AuthService,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.url = this.router.url;
  }

  categoryMeta = CategoryMeta;

  ngOnInit(): void {
    this.load();
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url === this.url) {
          this.restoreScrollPos();
        }
      }
    });
  }

  videoUrl(vid: VideoType): string {
    if (vid && vid.MuxStatus === 'ready' && vid.PlaybackIDs) {
      return 'url(\'https://image.mux.com/' + vid.PlaybackIDs[0].id + '/animated.gif?width=180&fps=3&start=' +
        (vid.GifStartTime || '0') + '\')';
    } else {
      return this.base + '20201104/Video-Processing-4x-80.jpg?w=130&h=130&fit=crop';
    }
  }

  async loadMore(): Promise<void> {
    this.isLoadingMore = true;
    this.page++;
    const x = this.window.scrollX;
    const y = this.window.scrollY;
    await this.load();
    this.isLoadingMore = false;
    setTimeout$(() => {
      if (this.window.scrollTo) {
        this.window.scrollTo(x, y);
      }
    }, 0);
  }

  async load(): Promise<void> {

    const posts = Array.from(await this.db.listPage('MadisonBlogPost', this.page, this.perPage, 'CreatedAt'));

    if (!posts.length) {
      this.isMore = false;
      return;
    }

    posts.sort((a, b) => {
      a.isAdvert = false;
      b.isAdvert = false;
      return this.toDate.transform(a.createdAt).getTime() - this.toDate.transform(b.createdAt).getTime();
    }).reverse();

    let TotalPossibleAds = Math.round(posts.length / 3);

    let Ads: MadisonBlogPostType[] = [
      {
        ID: 'AD_PARTNER',
        Category: 'Madison',
        Content: '',
        CreatedAt: '',
        Images: [this.base + '20200903/Madison-updates-2x.png'],
        Owner: '',
        Title: 'Get in the know with exclusive news & updates',
        CallToActionTitle: 'BECOME AN INSIDER',
        CallToActionLink: 'https://madisonmissions.typeform.com/to/lf2tm9',
        UpdatedAt: '',
        isAdvert: true,
        advertIcon: this.base + '20200820/Madison-dk-sq.svg',
      },
      {
        ID: 'AD_SUBSCRIBE',
        Category: 'Madison',
        Content: '',
        CreatedAt: '',
        Images: [this.base + '20200903/Madison-News-2x.png'],
        Owner: '',
        Title: 'All the latest news, right to your inbox',
        CallToActionTitle: 'SUBSCRIBE',
        UpdatedAt: '',
        isAdvert: true,
        advertIcon: this.base + '20200820/Madison-dk-sq.svg',
      },
      {
        ID: 'AD_VISION',
        Category: 'Madison',
        Content: '',
        CreatedAt: '',
        Images: [this.base + '20200903/Madison-vision-2x.png'],
        Owner: '',
        Title: 'Find out more about our vision',
        CallToActionTitle: 'DISCOVER THE VISION',
        UpdatedAt: '',
        isAdvert: true,
        advertIcon: this.base + '20200820/Madison-dk-sq.svg',
      },
      {
        ID: 'AD_DONATE',
        Category: 'Madison',
        Content: '',
        CreatedAt: '',
        Images: [this.base + '20200903/Madison-donate-2x.png'],
        Owner: '',
        Title: 'Help turn our vision into reality',
        CallToActionTitle: 'MAKE A GIFT',
        UpdatedAt: '',
        isAdvert: true,
        advertIcon: this.base + '20200820/Madison-dk-sq.svg',
      },
    ];

    for (const ad of [...Ads]) {
      const existing = this.posts.find(el => {
        return el.ID === ad.ID;
      }) ? true : false;
      if (existing) {
        this.utils.delete(Ads, ad);
      }
    }

    if (TotalPossibleAds > Ads.length) {
      TotalPossibleAds = Ads.length;
    }

    Ads = this.utils.shuffle(Ads);

    let UsedIndexes: number[];

    for (let i = 0; i < TotalPossibleAds; i++) {
      let random = 0;
      UsedIndexes = this.getUsedAdvertndexes(posts);
      while (!random || UsedIndexes.includes(random)) {
        random = this.utils.getRandomInt(2, posts.length);
      }
      UsedIndexes.push(random);
      UsedIndexes.push(random - 1);
      UsedIndexes.push(random + 1);
      posts.splice(random, 0, Ads[i]);
    }

    if (!this.users.length) {
      this.users = Array.from(await this.db.listAll('AppUser'));

      for (const user of this.users) {
        this.usersById['APP_' + user.ID] = user;
      }
    }

    for (const post of posts) {
      post.authorObj = this.usersById[post.Owner];
    }

    this.posts.push(...posts);


    this.loading = false;

  }

  getUsedAdvertndexes(posts: MadisonBlogPostType[]): number[] {
    const output: number[] = [];
    for (const post of posts) {
      const idx = posts.indexOf(post);
      if (posts[idx].isAdvert) {
        output.push(idx);
        output.push(idx - 1);
        output.push(idx + 1);
      }
    }
    return output;
  }

  advertAction(advert: MadisonBlogPostType): void {
    switch (advert.ID) {
      case 'AD_PARTNER':
        this.window.open(advert.CallToActionLink);
        break;
      case 'AD_SUBSCRIBE':
        this.notificationPrefs();
        break;
      case 'AD_VISION':
        if (this.window.scrollTo) {
          this.window.scrollTo(0, 0);
        }
        this.router.navigateByUrl('/vision');
        break;
      case 'AD_DONATE':
        if (this.window.scrollTo) {
          this.window.scrollTo(0, 0);
        }
        this.router.navigateByUrl('/support');
        break;
    }
  }

  notificationPrefs(): void {

    if (this.auth.getSessionValue()) {
      this.openNotificationPrefs();
    } else {
      const ref = this.dialog.open(LoginProvidersDialogComponent, {
        width: '500px',
      });
      ref.afterClosed().subscribe(async val => {
        if (this.auth.authChecked) {
          this.openNotificationPrefs();
        }
      });
    }
  }

  openNotificationPrefs(): void {

    this.dialog.open(MyNotificationsComponent, {
      data: true,
    });

  }

  intersected($event: any): void {
    const event = $event as AppIntersection;
    if (event.scrollingDirection === 'down' && event.isVisible && this.isBrowser) {
      this.loadMore();
    }
  }

  stemJoin(post1: MadisonBlogPostType, post2: MadisonBlogPostType): { [key: string]: string } {
    return {
      'background-image': `linear-gradient(to bottom, ${CategoryMeta[post1.Category].color}, ${CategoryMeta[post2.Category].color})`
    };
  }

  open(post: MadisonBlogPostType): void {
    this.db.curBlogPost = post;
    this.saveScrollPos();
    this.router.navigateByUrl(this.url + '/news-post/' + post.ID);
  }

  saveScrollPos(): void {
    NewsScrollPos = this.window.scrollY;
  }

  restoreScrollPos(): void {
    this.window.scrollTo(0, NewsScrollPos);
  }

  cta(post: MadisonBlogPostType): void {
    this.window.open(post.CallToActionLink);
  }

}
