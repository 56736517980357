<div [hidden]="currentRoute !== 'Checkout-2'">
    <app-eastward-header-nav [simple]="true"></app-eastward-header-nav>
    <div class="checkout-wrapper" style="align-items: flex-start;">
        <div class="payment-details" style="padding-bottom: 50px;">
            <h2>Payment Details</h2>
            <div class="address-list" style="padding: 30px 0;">
                <div class="address-box" *ngFor="let source of MyPaymentSources" (click)="selectedPaymentSource = source">
                    <mat-icon [hidden]="selectedPaymentSource != source">check</mat-icon>
                    <div matRipple class="inner">
                        <h2>{{ source.brand }}</h2>
                        <p>
                            <span style="font-size: 16px; font-weight: bold">xxxx {{ source.last4 }}</span
                  >, &nbsp; exp {{ source.exp_month + "/" + source.exp_year }}
                </p>
              </div>
            </div>
          </div>
      
            <div class="payment-box" [hidden]="!AddPaymentSource && MyPaymentSources.length" *ngIf="isBrowser">
                <img
                src="https://cdn2.tda.website/beehive/20201125/Visa-Mastercard-Logo.svg"
                width="120"
                height="60"
                alt=""
                />
                <stripe-card
                #stripeCard
                [options]="stripeOptions"
                [(token)]="cardToken"
                ></stripe-card>
            </div>
            <div>
                <button mat-stroked-button (click)="AddPaymentSource = !AddPaymentSource">
                    <mat-icon *ngIf="!AddPaymentSource">add</mat-icon>
                    {{ AddPaymentSource ? 'Cancel' : 'New Payment Source' }}
                </button> &nbsp; 
                <button mat-stroked-button color="primay" *ngIf="AddPaymentSource" (click)="savePaymentSource()">
                    Save Card
                </button>
            </div>
            
            <div style="padding: 30px 0">
                <a mat-button color="primary" href="/pp" target="_blank">PRIVACY POLICY</a>
                <a mat-button color="primary" href="/tos" target="_blank">TERMS OF SERVICE</a>
                <p>
                <mat-checkbox [(ngModel)]="tos"
                    >I agree to the privacy policy and terms of service.</mat-checkbox
                >
                </p>
            </div>
        </div>
    </div>
    <div class="bottom-bar" *ngIf="!isProcessing">
        <button mat-button color="primary" (click)="save()" routerLink="/Magabooks/Checkout-1">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="space"></div>
        <div class="amount">Total: {{ total | currency }}</div>
        <div class="space"></div>
        <button mat-button color="primary" (click)="pay()">
            Pay
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
    <div class="bottom-bar" *ngIf="isProcessing">
        <div class="space"></div>
        <mat-spinner color="primary" diameter="30"></mat-spinner>
        <div class="space"></div>
    </div>
</div>
<div *ngIf="currentRoute === 'Login'">
    <app-eastward-header-nav [simple]="true"></app-eastward-header-nav>
    <div class="checkout-wrapper">
        <app-login-with-provider label="Login to place your order"></app-login-with-provider>
    </div>
    <div class="bottom-bar">
        <button mat-button color="primary" (click)="save()" routerLink="/Magabooks">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="space"></div>
        <div class="amount">Total: {{ total | currency }}</div>
        <div class="space"></div>
        <button mat-button [disabled]="true" color="primary">
            CONTINUE
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="currentRoute === 'Checkout-1'">
    <app-eastward-header-nav [simple]="true"></app-eastward-header-nav>
    <div class="my-order">
        <h2>Delivery Address</h2>

        <mat-form-field class="field" appearance="outline">
            <mat-label>Address</mat-label>
            <mat-select [(ngModel)]="selectedAddress" (selectionChange)="save()">
                <mat-option *ngFor="let adr of MyAddresses" [value]="adr">
                    {{ adr.name + ', ' + adr.address1 + (adr.address2 ? ', ' +adr.address2  : '') + ', ' + adr.suburb + ' ' + adr.state + ' ' + adr.postcode }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <div *ngIf="!AddAddress">
            <button mat-stroked-button (click)="AddAddress = true">
                <mat-icon>add</mat-icon>
                New Address
            </button>
        </div>

        <form [formGroup]="addressForm">

            <div class="address-form" [hidden]="!AddAddress && MyAddresses.length">
                <mat-form-field class="field" appearance="outline">
                    <mat-label>Your Name</mat-label>
                    <input matInput formControlName="name" placeholder="Name" type="text">
                    <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
                </mat-form-field>

                <mat-form-field class="field" appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="email" placeholder="Email" type="text">
                    <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
                    <mat-error *ngIf="hasError('email', 'email')">Invalid email</mat-error>
                </mat-form-field>

                <mat-form-field class="field" appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="phone" placeholder="Phone" type="text">
                    <mat-error *ngIf="hasError('phone', 'required')">Phone is required</mat-error>
                </mat-form-field>

                <mat-form-field class="field" appearance="outline">
                    <mat-label>Address line 1</mat-label>
                    <input matInput formControlName="address1" placeholder="Address line 1" type="text" />
                    <mat-error *ngIf="hasError('address1', 'required')">Address is required</mat-error>
                </mat-form-field>

                <mat-form-field class="field" appearance="outline">
                    <mat-label>Address line 2</mat-label>
                    <input matInput formControlName="address2" placeholder="Address line 2" type="text" />
                </mat-form-field>

                <mat-form-field class="field" appearance="outline">
                    <mat-label>Suburb</mat-label>
                    <input matInput formControlName="suburb" placeholder="Address line 2" type="text" />
                    <mat-error *ngIf="hasError('suburb', 'required')">Suburb is required</mat-error>
                </mat-form-field>

                <mat-form-field class="field" appearance="outline">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state">
                        <mat-option value="NSW">NSW</mat-option>
                        <mat-option value="QLD">QLD</mat-option>
                        <mat-option value="SA">SA</mat-option>
                        <mat-option value="TAS">TAS</mat-option>
                        <mat-option value="VIC">VIC</mat-option>
                        <mat-option value="WA">WA</mat-option>
                        <mat-option value="ACT">ACT</mat-option>
                        <mat-option value="NT">NT</mat-option>
                        <mat-option value="JBT">JBT</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="field" appearance="outline">
                    <mat-label>Postcode</mat-label>
                    <input matInput formControlName="postcode" placeholder="Postcode" type="number" />
                    <mat-error *ngIf="hasError('postcode', 'required')">Postcode is required</mat-error>
                </mat-form-field>

                <div>
                    <button mat-stroked-button (click)="AddAddress = false">
                        Cancel
                    </button> &nbsp; 
                    <button mat-stroked-button (click)="saveAddress()">
                        Save
                    </button>
                </div>

            </div>

            <h2>Shipping Option</h2>
            <mat-form-field class="shipping" appearance="outline">
                <mat-label>Shipping Option</mat-label>
                <mat-select formControlName="shipping" (selectionChange)="updateTotal()">
                    <mat-option value="AU">Australia-wide $30/box</mat-option>
                    <mat-option value="PICKUP">Pickup</mat-option>
                </mat-select>
            </mat-form-field>
        </form>

            <h2>My Magabooks</h2>
            <div class="item" *ngIf="this.cart.GC > 0">
                <img src="https://cdn2.tda.website/beehive/20201125/GreatControversy.jpg?w=100">
                <span>
            x{{ this.cart.GC }} box{{ this.cart.GC !== 1 ? 'es' : ''}} of <strong>The Great Controversy</strong> <br>
            {{ GcBoxPrice | currency }} ea.</span>
            </div>
            <div class="item" *ngIf="this.cart.WH > 0">
                <img src="https://cdn2.tda.website/beehive/20201125/WholesomeHomemadeDisplayRender.jpg?w=100">
                <span>
                        x{{ this.cart.WH }} box{{ this.cart.WH !== 1 ? 'es' : ''}} of <strong>Wholesome Homemade</strong> <br>
                        {{ WhBoxPrice | currency }}
                        </span>
            </div>
            <div class="item">
                <img src="https://cdn2.tda.website/beehive/20201125/GreatControversy.jpg?w=100&bri=100" alt=""> Shipping for {{ numBoxes }} box{{ numBoxes !== 1 ? 'es' : ''}}: {{ numBoxes * ShippingPerBox | currency }}
            </div>
        </div>
        <div class="bottom-bar">
            <button mat-button color="primary" (click)="save()" routerLink="/Magabooks">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <div class="space"></div>
            <div class="amount">Total: {{ total | currency }}</div>
            <div class="space"></div>
            <button mat-button [disabled]="!selectedAddress && !addressForm.valid" color="primary" (click)="save()" routerLink="/Magabooks/Checkout-2">
            CONTINUE
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        </div>
    </div>
    <div *ngIf="!currentRoute">
        <app-eastward-header-nav></app-eastward-header-nav>
        <div id="magabooks-top" class="mat-elevation-z5" style="text-align: center;">
            <div class="inner" style="max-width: 600px; margin: auto;">
                <h1>Magabooks</h1>
                <p>Designed by Australian LEs</p>
            </div>
        </div>

        <div class="total sticky">
            <div class="amount">Total: {{ total | currency }}</div>
            <div class="space"></div>
            <div class="checkout">
                <button mat-button [disabled]="!cart.WH && !cart.GC" color="primary" routerLink="/Magabooks/Checkout-1" [queryParams]="{ WH: cart.WH, GC: cart.GC }">
            CHECKOUT
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
            </div>
        </div>
        <div id="book-wrapper">

            <p style="text-align: center; margin: 30px 0 0 0;">Shipping: {{ AuWideShippingCost | currency : 'AUD' : '$' : '1.0-0' }} per box, Australia-wide</p>

            <div class="book mat-elevation-z2">
                <img src="https://cdn2.tda.website/beehive/20201125/GreatControversy.jpg?w=960">
                <mat-divider></mat-divider>
                <div class="product-details">
                    <div>
                        <div class="txt">Box of {{ GcPerBox }}</div>
                    </div>
                    <div *ngIf="!cart.GC">
                        <button mat-stroked-button (click)="add('GC')">
                    <mat-icon>add</mat-icon>
                    Add to Cart
                </button>
                    </div>
                    <div class="c ticker" *ngIf="cart.GC">
                        <mat-button-toggle-group #toggleGroupGC="matButtonToggleGroup">
                            <mat-button-toggle value="-" class="tk" (click)="remove('GC')">
                                <mat-icon>remove</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="num" value="num">{{ cart.GC }}</mat-button-toggle>
                            <mat-button-toggle value="+" class="tk" (click)="add('GC')">
                                <mat-icon>add</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="price-bx">
                        <div class="price">{{ (GcPerBox * GcCost) | currency : 'AUD' : '$' : '1.0-0' }}</div>
                        <small>{{ GcCost | currency }} ea</small>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
            <div class="book mat-elevation-z2">
                <img src="https://cdn2.tda.website/beehive/20201125/WholesomeHomemadeDisplayRender.jpg?w=960">
                <mat-divider></mat-divider>
                <div class="product-details wh">
                    <div class="a">
                        <div class="txt">Box of {{ WhPerBox }}</div>
                    </div>
                    <div class="b">
                        <mat-form-field appearance="outline">
                            <mat-label>Price Category</mat-label>
                            <mat-select [(ngModel)]="whOption" (selectionChange)="updateTotal()">
                                <mat-option *ngFor="let tier of tiers" [value]="tier">
                                    {{tier.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="c" *ngIf="!cart.WH">
                        <button mat-stroked-button (click)="add('WH')">
                    <mat-icon>add</mat-icon>
                    Add to Cart
                </button>
                    </div>
                    <div class="c ticker" *ngIf="cart.WH">
                        <mat-button-toggle-group #toggleGroupWH="matButtonToggleGroup">
                            <mat-button-toggle value="-" class="tk" (click)="remove('WH')">
                                <mat-icon>remove</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle class="num" value="num">{{ cart.WH }}</mat-button-toggle>
                            <mat-button-toggle value="+" class="tk" (click)="add('WH')">
                                <mat-icon>add</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="price-bx d">
                        <div class="price">{{ (whOption.price * 30) | currency : 'AUD' : '$' : '1.0-0' }}</div>
                        <small>{{ whOption.price | currency }} ea</small>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>

        <div class="total">
            <div class="amount">Total: {{ total | currency }}</div>
            <div class="space"></div>
            <div class="checkout">
                <button mat-button [disabled]="!cart.WH && !cart.GC" color="primary" (click)="save()" routerLink="/Magabooks/Login">
            CHECKOUT
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
            </div>
        </div>
    </div>