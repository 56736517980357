
export enum schemaTypes {
    connection,
    queryIndex,
    sortIndex,
}

export enum connectionReturnType {
    list,
    singleObject
}

export interface SchemaTableField {
    name: string;
    types: schemaTypes[];
    connectedTo: string;
    connectionReturns?: connectionReturnType;
    defaultValue?: string;
    connectedListFieldValueEquals?: string;
}

export const OwnerConnection: SchemaTableField = {
    name: 'OwnerObj',
    types: [
        schemaTypes.queryIndex,
        schemaTypes.connection,
    ],
    connectedTo: 'Person.ID',
    connectionReturns: connectionReturnType.singleObject,
    connectedListFieldValueEquals: 'Owner',
};

export const schema: { [key: string]: SchemaTableField[] } = {
    Person: [
        {
            name: 'Addresses',
            types: [
                schemaTypes.connection,
            ],
            connectedTo: 'Address.Owner',
            connectedListFieldValueEquals: 'ID',
            connectionReturns: connectionReturnType.list,
        },
        {
            name: 'Donations',
            types: [
                schemaTypes.connection,
            ],
            connectedTo: 'Donation.Owner',
            connectedListFieldValueEquals: 'ID',
            connectionReturns: connectionReturnType.list,
        }
    ],
};
