import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DbService } from 'src/app/services/db.service';
import { DonationType } from 'src/app/services/DataStore';
import { AuthService } from 'src/app/services/auth.service';
import { WINDOW } from 'src/app/services/window.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-donation-receipt',
  templateUrl: './donation-receipt.component.html',
  styleUrls: ['./donation-receipt.component.scss']
})
export class DonationReceiptComponent implements OnInit, OnDestroy {

  paramsSubscription: Subscription | null = null;

  Donation: DonationType | null = null;

  constructor(
    private activeRoute: ActivatedRoute,
    private auth: AuthService,
    private db: DbService,
    private router: Router,
    @Inject(WINDOW) private window: Window,
    private utils: UtilsService,
  ) { }

  IsTopAdmin = false;

  ngOnDestroy(): void {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.paramsSubscription = this.activeRoute.params.subscribe(routeParams => {

      if (routeParams.id) {

        this.load(routeParams.id);

      }

    });

    this.IsTopAdmin = this.auth.isTopAdmin();

  }

  async load(id: string): Promise<void> {

    this.Donation = await this.db.get('Donation', id);

    this.utils.updateMeta({
      BrowserTitle: `Donation receipt #${this.Donation?.DonationID}`,
      Title: `Donation receipt #${this.Donation?.DonationID}`,
      Description: `This is your donation receipt. Thank you so much for your support.`,
    });

  }

  async delete(): Promise<void> {
    if (this.Donation && confirm('Delete this donation?')) {
      await this.db.delete(this.Donation);
      this.db.snackBar.open('Donation has been deleted.', 'Dismiss');
      this.router.navigateByUrl('my-account');
    }
  }

  print(): void {
    this.window.print();
  }

}
