import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-canvassing-photos',
  templateUrl: './canvassing-photos.component.html',
  styleUrls: ['./canvassing-photos.component.scss']
})
export class CanvassingPhotosComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private db: DbService,
  ) { }

  imageData: Array<string | number>[] = [
    ['20201106/00010XTR-00010-BURST20180513114454-01.jpeg', 2064, 2067, '#e69f36'],
    ['20201106/1.jpg', 2048, 1365, '#d3b367'],
    ['20201106/viber-image-2019-12-08-14-05-53--2-.jpg', 1280, 960, '#d94c30'],
    ['20201106/viber-image-2019-12-18-13-15-58.jpg', 1350, 1800, '#ce3336'],
    ['20201106/viber-image-2019-12-18-19-13-11.jpg', 1280, 960, '#c7376c'],
    ['20201106/viber-image-2019-12-18-19-14-51.jpg', 1280, 960, '#c99c38'],
    ['20201106/viber-image-2019-12-18-19-15-57.jpg', 1280, 960, '#c2c22f'],
    ['20201106/viber-image-2019-12-18-19-16-09.jpg', 960, 1280, '#dc344c'],
    ['20201106/viber-image-2019-12-18-19-16-29.jpg', 1280, 960, '#ceb92c'],
    ['20201106/viber-image-2019-12-20-17-54-37.jpg', 1280, 960, '#c55442'],
    ['20201106/viber-image-2019-12-24-19-20-23.jpg', 1280, 960, '#3395c9'],
    ['20201106/viber-image-2019-12-24-19-20-57.jpg', 1280, 960, '#0aacac'],
    ['20201106/viber-image-2019-12-24-21-31-45.jpg', 1280, 960, '#a86247'],
    ['20201106/111.jpg', 3648, 2736, '#a44e3f'],
    ['20201106/20161205-094147.jpg', 2048, 1152, '#d7492a'],
    ['20201106/20161205-174027.jpg', 864, 1536, '#be6a38'],
    ['20201106/20161212-124402.jpg', 864, 1536, '#d42339'],
    ['20201106/20161226-101056.jpg', 2048, 1152, '#8e5039'],
    ['20201106/20161228-142012.jpg', 2048, 1152, '#16d1f1'],
    ['20201106/2017-Program-Group-Photo.jpg', 4032, 2172, '#cb9b24'],
    ['20201106/20171027-172126.jpg', 1920, 1440, '#026d97'],
    ['20201106/20171113-095325-hero-80.jpg', 2000, 1287, '#a2b138'],
    ['20201106/20171115-151006-hero.jpg', 2000, 1125, '#2fc8dd'],
    ['20201106/20171226-084558.jpg', 1440, 1080, '#cf7133'],
    ['20201106/20171227-094455.jpg', 1440, 1080, '#af5411'],
    ['20201106/20171229-225839.jpg', 1440, 1080, '#0baae4'],
    ['20201106/20171230-084736.jpg', 1440, 1080, '#c06e2d'],
    ['20201106/20180102-172611.jpg', 4032, 3024, '#aab552'],
    ['20201106/20180106-115614.jpg', 1440, 1080, '#31cada'],
    ['20201106/20191202-151908.jpg', 4032, 3024, '#bb4747'],
    ['20201106/20191230-153015-90-5e23b5b8c94e4.jpg', 3024, 4032, '#df6c6b'],
    ['20201106/20191231-113546.jpg', 4032, 3024, '#b24e3a'],
    ['20201106/20191231-164205-5e124ed847f70.jpg', 1875, 2500, '#e13828'],
    ['20201106/20191231-164517-5e124f215cc91.jpg', 1875, 2500, '#df5a57'],
    ['20201106/20200114-132648.jpg', 4032, 3024, '#c7c027'],
    ['20201106/20200128-180129.jpg', 3264, 1472, '#b73948'],
    ['20201106/Abby-and-Tara.jpg', 3648, 2736, '#bf384e'],
    ['20201106/AdobePhotoshopExpress-d510a46c7b8849999af7eab6a56e5ea6.jpg', 1920, 1080, '#c646cb'],
    ['20201106/Andy-Potrait.jpg', 500, 500, '#4b89ba'],
    ['20201106/Bruce-and-Chantelle.jpg', 3648, 2736, '#2dad6c'],
    ['20201106/Burnie-Church.jpg', 2048, 1536, '#c85120'],
    ['20201106/Canvassing-at-Jumbunna-Lodge-edit-5bc84d88420cb.jpg', 4000, 2038, '#c6cf2c'],
    ['20201106/canvassing-bags.jpeg', 1920, 1081, '#d0374a'],
    ['20201106/canvassing-books-hero.jpeg', 3054, 1718, '#ed8e2e'],
    ['20201106/Corey-Canvassing.jpg', 2736, 3648, '#3191cd'],
    ['20201106/cranbourne-canvassing.jpg', 1751, 986, '#3aa6cb'],
    ['20201106/Delphia-and-Katalyn-Canvassing.jpg', 3648, 2736, '#5e9bd0'],
    ['20201106/DSC-0208.jpg', 2048, 1362, '#4d70ac'],
    ['20201106/DSC03496.jpg', 2048, 1536, '#f5de24'],
    ['20201106/DSC03553--2-.jpg', 2048, 1536, '#ac368e'],
    ['20201106/DSC03558.jpg-cropped--2-.jpg', 1051, 1536, '#d55609'],
    ['20201106/DSC03722.jpg', 2048, 1536, '#2d558a'],
    ['20201106/DSCN5762-78.jpg', 3160, 4213, '#2553af'],
    ['20201106/DSCN5766-89.jpg', 3888, 2916, '#c06240'],
    ['20201106/DSCN5772-69.jpg', 4842, 3632, '#2861d4'],
    ['20201106/DSCN5810.jpg', 2888, 2166, '#0897f1'],
    ['20201106/DSCN5811.jpg', 2107, 1580, '#464aae'],
    ['20201106/Dsozo-Camp-2016-013.jpg', 1440, 1080, '#47acc1'],
    ['20201106/Dsozo-Camp-2016-069.jpg', 1440, 1080, '#4397cc'],
    ['20201106/DSozo-NSW-2017-22.jpg', 2272, 1278, '#1f46b8'],
    ['20201106/DSozo-WA-2017-092.jpg', 1667, 2500, '#cd9f45'],
    ['20201106/DSozo-WA-2017-104.jpg', 2500, 1667, '#3fd5aa'],
    ['20201106/geelong-canvassing.jpg', 1784, 1004, '#485e96'],
    ['20201106/Geoff.jpg', 2096, 2864, '#cb3739'],
    ['20201106/group-training-hero.jpg', 1280, 657, '#cf9015'],
    ['20201106/Group.jpg', 7296, 5472, '#186099'],
    ['20201106/Happy-Canvassers.jpg', 2427, 2736, '#ec3648'],
    ['20201106/heritage-college-group-shot.jpg', 3788, 2131, '#24c8e1'],
    ['20201106/IMG-1295-01.jpeg', 3022, 3024, '#c57136'],
    ['20201106/IMG-1dfcd0659f45bb060fc834ca469fe2cb-V.jpg', 2000, 1500, '#4888d8'],
    ['20201106/IMG-20171107-115155669-HDR-hero-58.jpg', 2000, 1125, '#de8834'],
    ['20201106/IMG-20171112-124837209-79.jpg', 2000, 1125, '#0f629c'],
    ['20201106/IMG-20180510-163519-HDR.jpg', 4000, 3000, '#d3566e'],
    ['20201106/IMG-20180516-162606-01.jpeg', 2000, 2000, '#35a9ae'],
    ['20201106/IMG-20180521-165805-01.jpeg', 2000, 1498, '#ebcc22'],
    ['20201106/IMG-20180617-072651-01-hero.jpeg', 1920, 1079, '#0664cf'],
    ['20201106/IMG-20180619-121322-01.jpeg', 2560, 2556, '#b0aa34'],
    ['20201106/IMG-20180625-082846-01-01.jpeg', 2173, 2171, '#a51f20'],
    ['20201106/IMG-20180625-145652-01.jpeg', 3211, 2409, '#e65a6f'],
    ['20201106/IMG-20180806-164400-01.jpeg', 1780, 1776, '#daa326'],
    ['20201106/IMG-20180822-120446-01.jpeg', 2272, 2274, '#cc5777'],
    ['20201106/IMG-20180823-160144-01.jpeg', 3072, 3072, '#cb384b'],
    ['20201106/IMG-20180828-110350-01.jpeg', 3070, 3068, '#e2545f'],
    ['20201106/IMG-20180829-125946-01.jpeg', 2258, 2255, '#b78559'],
    ['20201106/IMG-20180917-172317-01.jpeg', 2611, 2610, '#3c8ad2'],
    ['20201106/IMG-20180920-172158-01.jpeg', 2475, 2475, '#b7454e'],
    ['20201106/IMG-20190318-161923-01.jpeg', 2434, 2434, '#e01125'],
    ['20201106/IMG-20200123-184016.jpg', 7296, 5472, '#ce4f66'],
    ['20201106/IMG-20200124-091918.jpg', 7296, 5472, '#fd493b'],
    ['20201106/IMG-20200129-155704-87.jpg', 3648, 2736, '#b7414d'],
    ['20201106/IMG-20200201-205248.jpg', 7296, 5472, '#a8770a'],
    ['20201106/IMG-3265-14.png', 1334, 750, '#77baeb'],
    ['20201106/IMG-3273.jpg', 1280, 743, '#bd5d48'],
    ['20201106/IMG-3931.jpg', 2048, 1536, '#5896e5'],
    ['20201106/IMG-3dce4aeac708dc4623e49709e28eb45f-V.jpg', 1280, 960, '#6699de'],
    ['20201106/IMG-4581.jpg', 1440, 1080, '#cc966d'],
    ['20201106/IMG-4617.jpg', 1280, 960, '#ca7026'],
    ['20201106/IMG-6535.jpg', 1136, 1704, '#2898b6'],
    ['20201106/IMG-7068602e46e268d40e535a40b2af9b8b-V.jpg', 1280, 960, '#57a7ff'],
    ['20201106/IMG-7391.jpg', 4032, 3024, '#8fa531'],
    ['20201106/IMG-8295.jpg', 2272, 1515, '#3e6ec3'],
    ['20201106/IMG-8374.jpg', 2272, 1515, '#d54910'],
    ['20201106/IMG-f79aebaa436b6c91528998415fa12830-V.jpg', 1280, 960, '#bb3146'],
    ['20201106/IMG20171014083117.jpg', 1280, 948, '#9f8a35'],
    ['20201106/IMG20171219115756.jpg', 1440, 1080, '#b3925c'],
    ['20201106/IMG20171222101522.jpg', 810, 1080, '#bbae29'],
    ['20201106/lake-mac-map-fs8.png', 1664, 936, '#bd4c3a'],
    ['20201106/le-advance-group-shot.jpg', 4011, 2256, '#2058cb'],
    ['20201106/lIMG-9f53e41142e857eeedec80141084b86e-V.jpg', 2000, 1500, '#64a5f4'],
    ['20201106/Main-Web-Banner.jpg', 1000, 400, '#c6483a'],
    ['20201106/Mary-anne-and-Pearl-Praying.jpg', 3648, 2736, '#963636'],
    ['20201106/Maryann-and-Gladys.jpg', 3648, 2736, '#bb3c4e'],
    ['20201106/MC-2018-229-Tim-Canvassing.jpg', 1664, 2500, '#d64939'],
    ['20201106/MVIMG-20180619-115913-1-01.jpeg', 2250, 2250, '#d1ca53'],
    ['20201106/On-the-Road-Update-3-032.jpg', 1440, 1080, '#7aa9e4'],
    ['20201106/On-the-Road-Update-3-103.jpg', 1626, 1080, '#c3a446'],
    ['20201106/Pano25-60.jpg', 2400, 500, '#18a1dc'],
    ['20201106/Rachel-Canvassing.jpg', 1080, 1080, '#cc5778'],
    ['20201106/rockhampton-team-photo.jpeg', 2000, 1125, '#68b4f2'],
    ['20201106/salt-lake-team-shot-hero.jpg', 2000, 1125, '#05ace6'],
    ['20201106/SAM-4375.jpg', 1440, 1080, '#889437'],
    ['20201106/SAM-4838.jpg', 2048, 1536, '#355b91'],
    ['20201106/SAM-5485.jpg', 2048, 1536, '#c87129'],
    ['20201106/team-shot-ayers-rock-hero.jpg', 2000, 1125, '#149edf'],
    ['20201106/the-great-controversy-cropped.jpg', 1500, 844, '#b09056'],
    ['20201106/viber-image-2019-12-08-14-05-27.jpg', 1280, 960, '#d93730'],
    ['20201106/viber-image-2020-01-05-22-21-41.jpg', 1280, 960, '#d44e48'],
    ['20201106/viber-image-2020-01-05-22-23-06.jpg', 1280, 857, '#fa8b11'],
    ['20201106/viber-image-2020-01-17-18-56-31.jpg', 1280, 960, '#165d98'],
    ['20201106/viber-image-2020-01-18-15-09-58.jpg', 1280, 960, '#bea254'],
    ['20201106/viber-image-2020-01-19-10-12-12.jpg', 1280, 960, '#c97038'],
    ['20201106/viber-image-2020-01-19-11-17-38.jpg', 1600, 1200, '#5db0d2'],
    ['20201106/viber-image-2020-01-19-12-35-34.jpg', 1280, 960, '#d05529'],
    ['20201106/WA-Program-Banner.jpg', 3000, 1080, '#ef6d54'],
    ['20201106/WP-20151226-16-05-32-Pro.jpg', 1920, 1081, '#aaae51'],
    ['20201106/WP-20160102-19-28-58-Pro.jpg', 1920, 1080, '#986239'],
    ['20201106/WP-20160106-08-21-15-Pro-1.jpg', 1920, 1080, '#a842ba'],
  ];

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: 'Canvassing, Magabooks & Youth Programs in Australia',
      Title: 'Canvassing, Magabooks & Youth Programs in Australia',
      Description: 'Find out about magabook student canvassing programs in Australia.',
    });
  }

}
