<h1 mat-dialog-title>Pick your Avatar</h1>
<div mat-dialog-content>
    <div class="avatars">
        <div class="avatar" *ngFor="let img of Avatars" [mat-dialog-close]="AvatarEndpoint + img">
            <img [src]="'https://cdn2.tda.website/beehive/20200826/' + img">
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button mat-button (click)="random()">Pick Random</button>
</div>