import { Injectable } from '@angular/core';
import { StripeToken } from 'stripe-angular';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';

export interface StripeCustomer {
  id: string;
  object: string;
  address: string;
  balance: number;
  created: number;
  currency: string;
  default_source: string;
  delinquent: boolean;
  description: string;
  discount: any;
  email: string;
  invoice_prefix: string;
  invoice_settings: {
    custom_fields: any;
    default_payment_method: any;
    footer: any
  };
  livemode: boolean;
  metadata: any;
  name: string;
  next_invoice_sequence: 1;
  phone: string;
  preferred_locales: any[];
  shipping: any;
  sources: {
    object: string;
    data: StripePmtSource[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  subscriptions: {
    object: string;
    data: any[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  tax_exempt: string;
  tax_ids: {
    object: string;
    data: any[];
    has_more: boolean;
    total_count: number;
    url: string;
  };
}

export interface StripePmtSource {
  id: string;
  object: string;
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line1_check: string;
  address_line2: string;
  address_state: string;
  address_zip: string;
  address_zip_check: string;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string;
  dynamic_last4: any;
  exp_month: 2;
  exp_year: 2022;
  fingerprint: string;
  funding: string;
  last4: string;
  metadata: {};
  name: string;
  tokenization_method: any;
}

export interface StripeAddress {
  line1: string;
  city?: string;
  country?: string;
  line2?: string;
  postal_code?: string;
  state?: string;
}


@Injectable({
  providedIn: 'root'
})
export class StripeService {

  static PmtApiEndpoint = 'https://pcpwanrxfj.execute-api.ap-southeast-2.amazonaws.com/default/Beehive-Payment-Fns';

  constructor(
    private utils: UtilsService,
    private auth: AuthService,
    private http: HttpClient,
  ) { }

  async sendContactForm(name: string, email: string, phone: string, message: string): Promise<object> {

    const TxnData = {
      cmd: 'ContactForm',
      name, email, phone, message,
    };

    return await this.http.post(StripeService.PmtApiEndpoint, TxnData).toPromise();

  }

  async charge(Card: StripePmtSource, InvoiceID: string): Promise<object> {

    const TxnData = {
      session: this.auth.getSessionValue(),
      cmd: 'NewInvoice',
      InvoiceID,
      Card,
    };

    return await this.http.post(StripeService.PmtApiEndpoint, TxnData).toPromise();

  }

  async saveCard(token: StripeToken, address: StripeAddress): Promise<object> {

    address = this.utils.replaceValue(address, null, '');

    const response = await this.http.post(StripeService.PmtApiEndpoint, {
      session: this.auth.getSessionValue(),
      address,
      cmd: 'SaveCard',
      token,
    }).toPromise();

    return response;

  }

  async removeCard(cardID: string): Promise<object> {

    const response = await this.http.post(StripeService.PmtApiEndpoint, {
      session: this.auth.getSessionValue(),
      cmd: 'RemoveCard',
      cardID,
    }).toPromise();

    return response;

  }

}
