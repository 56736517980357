import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { detect } from 'detect-browser';
import { LoginData } from 'src/app/screens/auth/login/login.component';
import { SignupData } from 'src/app/screens/auth/signup/signup.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  loginForm: FormGroup | null = null;
  signupForm: FormGroup | null = null;
  recoverForm: FormGroup | null = null;
  activity = false;
  hidePwd = true;
  screen: 'Login' | 'Signup' | 'Recover Password' = 'Login';

  constructor(
    private auth: AuthService,
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    public snackBar: MatSnackBar,
    private db: DbService,
  ) { }

  ngOnInit(): void {

    this.loginForm = new FormGroup({

      email: new FormControl('', [Validators.required, Validators.email]),

      password: new FormControl('', [Validators.required, Validators.pattern(
        /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
      )]),

      remember: new FormControl('*')

    });

    this.signupForm = new FormGroup({
      first: new FormControl('', [Validators.required]),
      last: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.pattern(
        /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
      )]),
    });

    this.recoverForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.loginForm?.controls[controlName].hasError(errorName) || false;
  }

  public hasSignupError = (controlName: string, errorName: string) => {
    return this.signupForm?.controls[controlName].hasError(errorName) || false;
  }

  public hasRecoverError = (controlName: string, errorName: string) => {
    return this.recoverForm?.controls[controlName].hasError(errorName) || false;
  }

  async recover(value: any): Promise<void> {
    this.activity = true;
    const Email = value.email;
    const result = detect() as any;
    await this.db.q('auth:recoverPwd', {
      Email,
      Browser: result.name,
      OS: result.os,
    });

    this.snackBar.open('If your email address is in the system, you will receive a password reset email.', 'Dismiss', {
      duration: 6000,
    });

    this.activity = false;
  }

  signup(data: SignupData): void {

    this.activity = true;

    this.auth.signup(data).then((response) => {

      this.activity = false;

      if (response.Success) {

        this.screen = 'Login';

      }

    });

  }

  async login(data: LoginData): Promise<void> {

    this.activity = true;

    const LoginSuccess = await this.auth.login(data);

    this.activity = false;

    if (LoginSuccess) {

      this.dialogRef.close();

    }

  }

}
