import { Component, OnInit, Output, EventEmitter, Inject, Optional } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { NotificationFrequency, NotificationPrefType } from 'src/app/services/DataStore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationThanksComponent } from 'src/app/dialogs/notification-thanks/notification-thanks.component';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-my-notifications',
  templateUrl: './my-notifications.component.html',
  styleUrls: ['./my-notifications.component.scss']
})
export class MyNotificationsComponent implements OnInit {

  prefs: NotificationPrefType | null = null;

  @Output() saved: EventEmitter<any> = new EventEmitter();

  saving = false;

  constructor(
    public auth: AuthService,
    private db: DbService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public isDialog: boolean,
    @Optional() private ref: MatDialogRef<MyNotificationsComponent>,
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    if (!this.prefs) {
      this.prefs = {
        Frequency: 'Weekly',
        MuteComments: false,
        Unsubscribed: false,
      };
    }
    this.load();
    if (!this.ref) {
      this.utils.updateMeta({
        BrowserTitle: `Notifications - Activity Stream`,
        Title: `Notifications`,
        Description: `Notifications`,
      });
    }
  }

  async load(): Promise<void> {
    const prefs = (Array.from(await this.db.list('NotificationPref')) || [])[0];
    if (prefs) {
      this.prefs = prefs;
      if (this.prefs?.Unsubscribed) {
        this.prefs.Frequency = null;
      }
    }
  }

  async save(): Promise<void> {
    if (!this.prefs) {
      return;
    }

    this.saving = true;
    if (this.prefs.Frequency === null) {
      this.prefs.Frequency = 'Weekly';
    }
    await this.db.put('NotificationPref', this.prefs);
    if (!this.prefs.Unsubscribed && !this.prefs.MuteComments && !this.isDialog) {
      const ref = this.dialog.open(NotificationThanksComponent, {
        width: '700px',
      });
      ref.afterClosed().subscribe(() => {
        this.router.navigateByUrl('/');
      });
    } else {
      this.snack.open('Your preferences have been saved', 'DISMISS', {
        duration: 6000,
      });
    }
    this.saving = false;
  }

  async saveDialog(): Promise<void> {
    await this.save();
    this.ref.close();
  }
}
