import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MadisonBlogPostType } from 'src/app/services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-view-post',
  templateUrl: './view-post.component.html',
  styleUrls: ['./view-post.component.scss']
})
export class ViewPostComponent implements OnInit {

  loaded = false;
  post: MadisonBlogPostType | null = null;

  constructor(
    private route: ActivatedRoute,
    private db: DbService,
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    this.load();
  }

  getPostImage(post: MadisonBlogPostType): string {

    let image = '';
    if (post.Images && post.Images.length && !post.Videos?.length) {
      image = post.Images[0] + '?w=1200&h=700&fit=crop&crop=faces';
    } else if (post.Videos && post.Videos.length) {

      const vid = post.Videos[0];

      if (vid && vid.MuxStatus === 'ready' && vid.PlaybackIDs) {
        image = 'https://image.mux.com/' + vid.PlaybackIDs[0].id + '/animated.gif?width=500';
      } else {
        image = 'https://cdn2.tda.website/beehive/20201104/Video-Processing-4x-80.jpg?w=1200&h=700&fit=crop&crop=faces';
      }

    }

    return image;
  }

  async load(): Promise<void> {
    this.route.params.subscribe(async params => {
      if (params.id) {
        const post: MadisonBlogPostType = await this.db.get('MadisonBlogPost', params.id);
        this.loaded = true;
        this.post = post;

        this.utils.updateMeta({
          BrowserTitle: post.Title + ' - news post',
          Title: post.Title || 'Untitled',
          Description: post.Content || '',
          Image: this.getPostImage(post),
        });

      }
    });
  }

}
