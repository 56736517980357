import { Component, OnInit, Input, OnDestroy, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import { themeColorKeys } from 'src/app/modules/colors';
import { UtilsService } from 'src/app/services/utils.service';
import { AppIntersection } from 'src/app/directives/in-view.directive';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteEventsService } from 'src/app/services/router-events.service';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-back-header',
  templateUrl: './back-header.component.html',
  styleUrls: ['./back-header.component.scss']
})
export class BackHeaderComponent implements OnInit {

  @Input() theme!: themeColorKeys;
  @Input() title!: string;
  @Input() back!: string;
  @Input() fragment?: string;
  @Input() padding = '60px';

  isBrowser: boolean;
  sticky = false;
  scrollBreakPoint = 0;
  originalPadding = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
    private router: Router,
    private routeEventsService: RouteEventsService,
    @Inject(WINDOW) private window: Window,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.originalPadding = this.padding;
  }

  windowScrollEvID: string | null = null;

  intersected($event: any): void {
    const event = $event as AppIntersection;
    if (event.scrollingDirection === 'down' && !event.isVisible && this.isBrowser) {
      this.scrollBreakPoint = event.scrollY;
      this.sticky = true;
      this.padding = '10px';
    } else if (this.sticky && event.scrollY > this.scrollBreakPoint) {
      this.sticky = false;
      this.padding = this.originalPadding;
    }
  }

  ngOnInit(): void {
    // console.log(this.routeEventsService.previousRoutePath.value);
  }

  GoBack(): void {
    const prevUrl = this.routeEventsService.previousRoutePath.value;
    const currentUrl = this.routeEventsService.currentRoutePath.value;
    if (prevUrl !== this.router.url && !prevUrl.includes(currentUrl)) {
      this.window.history.back();
    } else {
      this.router.navigate([this.back], {
        fragment: this.fragment
      });
    }
  }

}
