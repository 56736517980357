import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountPaymentSourcesComponent } from './components/account/account-payment-sources/account-payment-sources.component';
import { AccountPersonalDetailsComponent } from './components/account/account-personal-details/account-personal-details.component';
import { AccountComponent } from './components/account/account.component';
import { OpenNewsPostComponent } from './components/open-news-post/open-news-post.component';
import { OpenPostImageComponent } from './components/open-post-image/open-post-image.component';
import { AboutComponent } from './screens/about/about.component';
import { ActivityStreamComponent } from './screens/activity-stream/activity-stream.component';
import { AllPostsComponent } from './screens/all-posts/all-posts.component';
import { AmbassadorsComponent } from './screens/ambassadors/ambassadors.component';
import { LoginComponent } from './screens/auth/login/login.component';
import { RecoverPwdComponent } from './screens/auth/recover-pwd/recover-pwd.component';
import { ResetPwdComponent } from './screens/auth/reset-pwd/reset-pwd.component';
import { SignupComponent } from './screens/auth/signup/signup.component';
import { CanvassingPhotosComponent } from './screens/canvassing-photos/canvassing-photos.component';
import { CanvassingComponent } from './screens/canvassing/canvassing.component';
import { ContactUsComponent } from './screens/contact-us/contact-us.component';
import { DonationReceiptComponent } from './screens/donation-receipt/donation-receipt.component';
import { DsozoCampPhotosComponent } from './screens/dsozo-camp-photos/dsozo-camp-photos.component';
import { LightOnAHillCampMeetingComponent } from './screens/events/light-on-a-hill-camp-meeting/light-on-a-hill-camp-meeting.component';
import { FeasibilitySurveyComponent } from './screens/feasibility-survey/feasibility-survey.component';
import { HomeComponent } from './screens/home/home.component';
import { MagabooksComponent } from './screens/magabooks/magabooks.component';
import { MusicCampPhotosComponent } from './screens/music-camp-photos/music-camp-photos.component';
import { MyNotificationsComponent } from './screens/my-notifications/my-notifications.component';
import { Page404Component } from './screens/page404/page404.component';
import { PrivacyPolicyComponent } from './screens/privacy-policy/privacy-policy.component';
import { SupportComponent } from './screens/support/support.component';
import { TosComponent } from './screens/tos/tos.component';
import { ViewPostComponent } from './screens/view-post/view-post.component';
import { ViewReceiptComponent } from './screens/view-receipt/view-receipt.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'news-post/:id',
        component: OpenNewsPostComponent,
        children: [
          {
            path: 'image',
            component: OpenPostImageComponent,
            outlet: 'imageRouter',
          },
        ],
      },
    ],
  },

  { path: 'view-donation/:id', component: DonationReceiptComponent },

  { path: 'view-receipt', component: ViewReceiptComponent },

  { path: 'tos', component: TosComponent },

  { path: 'About', component: AboutComponent },

  { path: 'Light-on-a-Hill-Camp-Meeting', component: LightOnAHillCampMeetingComponent },
  { path: 'Ambassadors', component: AmbassadorsComponent },
  { path: 'Survey', component: FeasibilitySurveyComponent },

  { path: 'Magabooks', component: MagabooksComponent },
  { path: 'Magabooks/:route', component: MagabooksComponent },

  { path: 'Canvassing', component: CanvassingComponent },
  { path: 'Canvassing-Photos', component: CanvassingPhotosComponent },
  { path: 'Canvassing-Photos/:id', component: CanvassingPhotosComponent },

  { path: 'Music-Camp-Photos', component: MusicCampPhotosComponent },
  { path: 'Music-Camp-Photos/:id', component: MusicCampPhotosComponent },

  { path: 'Dsozo-Camp-Photos', component: DsozoCampPhotosComponent },
  { path: 'Dsozo-Camp-Photos/:id', component: DsozoCampPhotosComponent },

  {
    path: 'stream',
    component: ActivityStreamComponent,
    children: [
      {
        path: 'news-post/:id',
        component: OpenNewsPostComponent,
        children: [
          {
            path: 'image',
            component: OpenPostImageComponent,
            outlet: 'imageRouter',
          },
        ],
      },
    ],
  },
  { path: 'all-posts', component: AllPostsComponent },
  { path: 'view-post/:id', component: ViewPostComponent },

  { path: 'Contact', component: ContactUsComponent },
  { path: 'Support', component: SupportComponent },
  {
    path: 'my-account',
    component: AccountComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-notifications',
    component: MyNotificationsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/all-payment-sources',
    component: AccountPaymentSourcesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'my-account/edit-details',
    component: AccountPersonalDetailsComponent,
    canActivate: [AuthGuardService],
  },

  { path: 'login', component: LoginComponent },
  { path: 'recover-pwd', component: RecoverPwdComponent },
  { path: 'reset-pwd', component: ResetPwdComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'pp', component: PrivacyPolicyComponent },

  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
