import { Component, OnInit, Input, Inject } from '@angular/core';
import { MadisonBlogPostType } from 'src/app/services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginWithProviderComponent } from '../login-with-provider/login-with-provider.component';
import { LoginProvidersDialogComponent } from 'src/app/dialogs/login-providers-dialog/login-providers-dialog.component';
import { Router } from '@angular/router';
import { ViewAuthorComponent } from 'src/app/dialogs/view-author/view-author.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { UtilsService } from 'src/app/services/utils.service';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-news-social-summary',
  templateUrl: './news-social-summary.component.html',
  styleUrls: ['./news-social-summary.component.scss']
})
export class NewsSocialSummaryComponent implements OnInit {

  @Input() post!: MadisonBlogPostType;

  constructor(
    public db: DbService,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private clipboard: Clipboard,
    private utils: UtilsService,
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit(): void {
    this.loadLike();
  }

  async loadLike(): Promise<void> {
    if (this.auth.getSessionValue()) {
      this.db.query('BlogLike', 'Key', this.post.ID || '', false, 0).then(res => {
        for (const item of res) {
          if (typeof item !== 'undefined' && item.Owner === this.auth.personID && this.post.ID) {
            this.db.likedPosts[this.post.ID] = true;
          }
        }
      });
    }
  }

  open(): void {
    this.db.curBlogPost = this.post;
    this.router.navigateByUrl('/news-post/' + this.post.ID);
  }

  viewAuthor(): void {
    this.dialog.open(ViewAuthorComponent, {
      data: this.post.Owner,
      width: '400px',
    });
  }

  async like(): Promise<void> {
    if (!this.post.ID) {
      return;
    }
    if (!this.auth.getSessionValue()) {
      const ref = this.dialog.open(LoginProvidersDialogComponent, {
        width: '500px',
      });
      ref.afterClosed().subscribe(async val => {
        if (this.auth.authChecked) {
          await this.loadLike();
          if (this.post.ID && !this.db.likedPosts[this.post.ID]) {
            this.like();
          }
        }
      });
    } else {
      this.db.likedPosts[this.post.ID] = !this.db.likedPosts[this.post.ID];
      this.post.Likes = this.post.Likes || 0;
      if (this.db.likedPosts[this.post.ID]) {
        this.post.Likes++;
      } else {
        this.post.Likes--;
      }
      await this.db.q('likeBlogPost', { id: this.post.ID });
    }
  }

  get link(): string {
    let output = '';
    if (this.post && this.post.ID) {
      output = `https://${this.utils.orgSite}/view-post/${this.post.ID}`;
    }
    return output;
  }

  copyLink(): boolean {
    let response = false;
    let message = 'Something is wrong with this post. Couldn\'t copy the link';
    if (this.link) {
      this.clipboard.copy(this.link);
      message = 'Link has been copied to your clipboard';
      response = true;
    }
    this.db.snackBar.open(message, 'DISMISS', {
      duration: 5000,
    });
    return response;
  }

  openLink(link: string): void {
    this.window.open(link);
  }

  shareToFacebook(): void {
    this.openLink(`https://www.facebook.com/sharer/sharer.php?u=${this.link}`);
  }

  shareToEmail(): void {
    this.openLink(`mailto:?Subject=${this.post.Title}&Body=${this.link}`);
  }

}
