import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewsOverlayComponent } from '../news-overlay/news-overlay.component';
import { DbService } from 'src/app/services/db.service';
import { MadisonBlogPostType } from 'src/app/services/DataStore';
import { ActivatedRoute, Router } from '@angular/router';
import { WINDOW } from 'src/app/services/window.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({ template: '' })
export class OpenNewsPostComponent implements OnInit {

  PostId = '';

  constructor(
    public dialog: MatDialog,
    private db: DbService,
    private curRoute: ActivatedRoute,
    private router: Router,
    @Inject(WINDOW) private window: Window,
    private utils: UtilsService,
  ) {
    this.curRoute.params.subscribe(params => {
      this.PostId = params.id;
    });
  }

  ngOnInit(): void {
    this.openDialog();
  }

  getPostImage(post: MadisonBlogPostType): string {

    let image = '';
    if (post.Images && post.Images.length && !post.Videos?.length) {
      image = post.Images[0] + '?w=1200&h=700&fit=crop&crop=faces';
    } else if (post.Videos && post.Videos.length) {

      const vid = post.Videos[0];

      if (vid.PlaybackIDs) {

        if (vid && vid.MuxStatus === 'ready') {
          image = 'https://image.mux.com/' + vid.PlaybackIDs[0].id + '/animated.gif?width=1200&start=' +
            (vid.GifStartTime || '0');
        } else {
          image = 'https://cdn2.tda.website/beehive/20201104/Video-Processing-4x-80.jpg?w=1200&h=700&fit=crop&crop=faces';
        }

      }

    }

    return image;
  }

  async openDialog(): Promise<void> {

    let post: MadisonBlogPostType;

    if (this.db.curBlogPost) {
      post = this.db.curBlogPost;
    } else {
      post = await this.db.get('MadisonBlogPost', this.PostId);
    }

    this.utils.updateMeta({
      BrowserTitle: post.Title + ' - news post',
      Title: post.Title || '',
      Description: post.Content || '',
      Image: this.getPostImage(post),
    });

    const dialogRef = this.dialog.open(NewsOverlayComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: post,
      closeOnNavigation: false,
      autoFocus: false,
      panelClass: ['full-screen']
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.window.location.href.includes('stream/')) {
        this.router.navigateByUrl('/stream');
      } else if (this.window.location.href.includes('news-post/')) {
        this.router.navigateByUrl('/');
      }
    });

  }

}
