import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-dsozo-camp-photos',
  templateUrl: './dsozo-camp-photos.component.html',
  styleUrls: ['./dsozo-camp-photos.component.scss']
})
export class DsozoCampPhotosComponent implements OnInit {

  constructor(
    private utils: UtilsService,
    private db: DbService,
  ) { }

  imageData: Array<string | number>[] = [
    ['20201106/2015-Music-Camp-363.jpg', 1920, 1275, '#53a1df'],
    ['20201106/Bethany-Baptism-Square.jpg', 850, 850, '#14aac6'],
    ['20201106/bethany.jpg', 828, 1242, '#0dadca'],
    ['20201106/Camp-Group.jpg', 3000, 993, '#ba343d'],
    ['20201106/DSCN5607-79.jpg', 3561, 4748, '#d34b34'],
    ['20201106/DSCN5655.jpg', 3890, 2918, '#e48c3c'],
    ['20201106/DSCN5884-94.jpg', 4419, 3314, '#63b0f5'],
    ['20201106/DSCN5902-31.jpg', 5184, 2916, '#ca483c'],
    ['20201106/Dsozo-Camp-2016-007.jpg', 1440, 1080, '#2d51b6'],
    ['20201106/Dsozo-Camp-2016-013.jpg', 1440, 1080, '#47acc1'],
    ['20201106/Dsozo-Camp-2016-064.jpg', 1920, 1080, '#7394cf'],
    ['20201106/Dsozo-Camp-2016-069.jpg', 1440, 1080, '#4397cc'],
    ['20201106/DSozo-NSW-2017-38-74.jpg', 7712, 3356, '#c74d46'],
    ['20201106/DSozo-NSW-2017-45.jpg', 2272, 1278, '#c1c744'],
    ['20201106/DSozo-WA-2017-006.jpg', 1667, 2500, '#4d7fc0'],
    ['20201106/DSozo-WA-2017-038.jpg', 1667, 2500, '#77af50'],
    ['20201106/DSozo-WA-2017-041.jpg', 2500, 1667, '#f7556b'],
    ['20201106/DSozo-WA-2017-047.jpg', 2500, 1667, '#af6bcd'],
    ['20201106/DSozo-WA-2017-097.jpg', 2500, 1667, '#d76621'],
    ['20201106/DSozo-WA-2017-104.jpg', 2500, 1667, '#3fd5aa'],
    ['20201106/DSozo-WA-2017-120.jpg', 2500, 1667, '#a2328c'],
    ['20201106/DSozo-WA-2017-169.jpg', 2500, 1667, '#c69274'],
    ['20201106/DSozo-WA-2017-194.jpg', 2500, 1667, '#514c35'],
    ['20201106/DSozo-WA-2017-208-77.jpg', 2500, 1667, '#f99c2f'],
    ['20201106/DSozo-WA-2017-244.jpg', 2500, 1667, '#da6328'],
    ['20201106/DSozo-WA-2018044.jpg', 2048, 1536, '#d5cf34'],
    ['20201106/DSozo-WA-2018067.jpg', 2048, 1536, '#09d3d1'],
    ['20201106/DSozo-WA-2018140-panorama.jpg', 6000, 2452, '#d4162d'],
    ['20201106/DSozo-WA-2018177.jpg', 2048, 1365, '#d4ac4c'],
    ['20201106/DSozo-WA-2018223.jpg', 1280, 850, '#f94e5c'],
    ['20201106/Eastward-Activities-Parallax.jpg', 2048, 981, '#ba23d5'],
    ['20201106/Group-Shot-Edited.jpg', 3382, 1423, '#2fd9c2'],
    ['20201106/GYB-Panorama.jpg', 2500, 699, '#336690'],
    ['20201106/GYB-poster.jpg', 1920, 1080, '#689ad4'],
    ['20201106/IMG-7025-597d454c60772.jpg', 1214, 1079, '#6b99c5'],
    ['20201106/IMG-7079.jpg', 2500, 1667, '#396896'],
    ['20201106/IMG-7183--2-.jpg', 2500, 1667, '#4571a3'],
    ['20201106/IMG-7240.jpg', 1667, 2500, '#989e39'],
    ['20201106/IMG-7325.jpg', 2500, 1667, '#bebc5a'],
    ['20201106/IMG-7427.jpg', 2500, 1667, '#4d70b1'],
    ['20201106/IMG-7509.jpg', 2272, 1515, '#5edaa9'],
    ['20201106/IMG-8062.jpg', 2272, 1515, '#a5b957'],
    ['20201106/IMG-8086.jpg', 2272, 1515, '#a9280c'],
    ['20201106/IMG-8295.jpg', 2272, 1515, '#3e6ec3'],
    ['20201106/IMG-8299.jpg', 2272, 1515, '#865037'],
    ['20201106/IMG-8374.jpg', 2272, 1515, '#d54910'],
    ['20201106/Jesus-Love-of-My-Soul-Poster.jpg', 1920, 1080, '#d02a62'],
    ['20201106/Junes-garden-crew--2-.jpeg', 1632, 1224, '#3254be'],
    ['20201106/live-action.jpg', 2400, 500, '#4677c5'],
    ['20201106/Oh-How-I-Love-Jesus-Music-Video-Poster.jpg', 1920, 1080, '#33b6c6'],
    ['20201106/Oh-How-I-Love-Jesus-Music-Video.00-00-02-17.Still001.png', 1920, 1080, '#31a9aa'],
    ['20201106/Oh-How-I-Love-Jesus-Music-Video.00-00-07-19.Still002.png', 1920, 1080, '#6daccb'],
    ['20201106/Pano13.jpg', 1500, 500, '#cac354'],
    ['20201106/Poster--5-.jpg', 900, 600, '#3c9ed1']
  ];

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: 'Photos from D\'Sozo Camp',
      Title: 'Photos from D\'Sozo Camp',
      Description: `D'Sozo camp is a very unique opportunity for youth to be involved in mission service. The primary focus of camp is christian help-work, music production, canvassing (literature evangelism) and door-to-door community surveys.`,
    });
  }

}
