import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { GenericRow } from './DataStore';
import { WINDOW } from './window.service';

export interface TintType {
  tint: string;
  color: string;
}

export interface PageMeta {
  BrowserTitle: string;
  Title: string;
  Image?: string;
  Description: string;
}

type GlobalEvents = 'scroll' | 'resize' | 'mousedown' | 'mouseup' | 'mousemove' | 'keypress' | 'keydown' | 'keyup' | 'orientationchange';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private title: Title,
    private meta: Meta,
  ) { }

  lodash = _;

  scriptCache = {};
  scriptProcessing = {};
  lo = _;
  eventCallbacks: any = {};
  eventCallbackFunctions: any = {};

  GlobalEventCallbacks: { [key: string]: { [key: string]: (event: any) => void } } = {};
  GlobalEventIsWatching: { [key: string]: boolean } = {};

  orgTitle = 'Eastward Missions';
  orgSite = 'www.eastward.edu.au';
  defaultImageMetaSettings = '?w=1200&h=700&fit=crop&crop=faces';

  getUrl(): string {
    return `https://${this.orgSite + this.router.url}`;
  }

  updateMeta(input: PageMeta): void {
    this.title.setTitle(input.BrowserTitle);
    this.meta.updateTag({
      property: 'og:image',
      content: input.Image || 'https://cdn2.tda.website/beehive/20201126/2020-11-26_193524.jpg',
    });
    this.meta.updateTag({ property: 'og:title', content: input.Title, });
    this.meta.updateTag({ property: 'og:description', content: input.Description, });
    this.meta.updateTag({ name: 'description', content: input.Description, });
    this.meta.updateTag({ property: 'og:type', content: 'website', });
    this.meta.updateTag({ property: 'og:url', content: this.getUrl(), });
  }

  replaceValue(obj: any, value: any, replace: any): any {
    Object.keys(obj).forEach(key => {
      if (obj[key] === value) {
        obj[key] = replace;
      }
    });
    return obj;
  }

  chunk(array: any[], size: number): any {
    return _.chunk(array, size);
  }

  dateDesc(ar: GenericRow[]): void {
    ar.sort((a, b) => {
      if (typeof b.createdAt !== 'undefined') {
        return ('' + (a.createdAt)).localeCompare((b.createdAt));
      } else {
        return ('' + 1).localeCompare('0');
      }
    }).reverse();
  }

  shuffle(array: any[]): any[] {
    let currentIndex = array.length;
    let temporaryValue: any;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  QueryParamsFromUrl(url: string): { [key: string]: string } {

    if (url.includes('?')) {

      const output: any = {};

      const QueryStr = url.split('?')[1];
      const Params: any = {};
      QueryStr.split('&').forEach(param => {
        const bit = param.split('=');
        output[bit[0]] = bit[1];
      });

      return output;

    } else {
      return {};
    }

  }

  RemoveEventCallback(eventName: GlobalEvents, uid: string): void {
    if (this.GlobalEventCallbacks[eventName]) {
      delete this.GlobalEventCallbacks[eventName][uid];
    }
  }

  RunEventCallbacks(eventName: GlobalEvents, event: any): void {

    if (this.GlobalEventCallbacks[eventName]) {
      Object.keys(this.GlobalEventCallbacks[eventName]).forEach(uid => {
        const Fn = this.GlobalEventCallbacks[eventName][uid];
        Fn(event);
      });
    }

  }

  WhenEventHappens(eventName: GlobalEvents, fn: (event: any) => void): string {

    const IsWatching = this.GlobalEventIsWatching[eventName];

    const uid = this.uid();
    this.GlobalEventCallbacks[eventName] = this.GlobalEventCallbacks[eventName] || {};
    this.GlobalEventCallbacks[eventName][uid] = fn;

    if (!IsWatching) {

      if (eventName === 'scroll' || eventName === 'resize') {
        if (this.window.addEventListener) {
          this.window.addEventListener(eventName, (event) => {
            this.RunEventCallbacks(eventName, event);
          });
        }
      } else {
        this.document.addEventListener(eventName, (event) => {
          this.RunEventCallbacks(eventName, event);
        });
      }

      this.GlobalEventIsWatching[eventName] = true;

    }
    return uid;
  }

  whenWindowScrolls(fn: (event: any) => void): string {
    return this.WhenEventHappens('scroll', fn);
  }

  removeScroll(uid: string): void {
    return this.RemoveEventCallback('scroll', uid);
  }

  whenWindowResizes(fn: (event: any) => void): string {
    return this.WhenEventHappens('resize', fn);
  }

  removeResize(uid: string): void {
    return this.RemoveEventCallback('resize', uid);
  }

  StopDocEvent(EventName: string): void {
    this.document.removeEventListener(EventName, this.eventCallbackFunctions[EventName]);
    delete this.eventCallbackFunctions[EventName];
    this.eventCallbacks[EventName].length = 0;
  }

  StartDocEvent(EventName: string, callback: any): void {

    this.eventCallbacks[EventName] = this.eventCallbacks[EventName] || [];

    if (!this.eventCallbackFunctions[EventName]) {
      this.eventCallbackFunctions[EventName] = (event: any) => {
        this.eventCallbacks[EventName].forEach((callbackFn: (event: any) => void) => {
          callbackFn(event);
        });
      };
    }

    if (!this.eventCallbacks[EventName].length) {

      this.document.addEventListener(EventName, this.eventCallbackFunctions[EventName]);

    }

    this.eventCallbacks[EventName].push(callback);

  }

  uppercaseFirst(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  lowercaseFirst(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  randomChars(num: number): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let text = '';
    for (let i = 0; i < num; i++) {
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return text;
  }

  uid(): string {
    // return uuid();
    return (this.randomChars(4)) + Date.now().toString(36) + (this.randomChars(4));
  }

  shortUid(): string {
    return (this.randomChars(4)) + Date.now().toString(36);
  }

  isObject(item: any): boolean {
    return _.isObject(item);
  }

  isArray(item: any): boolean {
    return _.isArray(item);
  }


  // JsonClone effectively deep clones an object by stringifying to JSON and re-parsing
  JsonClone(item: any): any {

    return JSON.parse(JSON.stringify(item));

  }

  clone(item: any, deep?: boolean): any {
    if (deep) {
      return _.cloneDeep(item);
    } else {
      return _.clone(item);
    }
  }

  sort(destination: any, key: any): any {
    return destination.sort((a: any, b: any) => {

      let GetValue: any;

      GetValue = () => {
        return 0;
      };
      if (typeof key === 'string') {
        GetValue = (obj: any) => {
          return obj[key] && obj[key].toString().toLowerCase() || '';
        };
      } else if (typeof key === 'function') {
        GetValue = (obj: any) => {
          return key(obj).toLowerCase();
        };
      }

      if (parseInt(GetValue(a), 10) === GetValue(a)) {
        return GetValue(a) - GetValue(b);
      } else if (GetValue(a) < GetValue(b)) {
        return -1;
      } else if (GetValue(a) > GetValue(b)) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  isJson(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  normalizeMobile(mobile: string): string {
    if (typeof mobile === 'number') {
      const mobileNum = mobile as number;
      mobile = mobileNum.toString();
    }
    if (mobile.length === 9 && mobile.charAt(0) === '4') {
      mobile = '0' + mobile;
    }
    mobile = mobile.replace(/\s+/g, '');
    mobile = mobile.replace(/^\+614/, '04');
    mobile = mobile.replace(/^614/, '04');
    return mobile;
  }

  delete(source: any[], row: any, idx?: any): void {
    if (_.isUndefined(idx)) {
      idx = _.indexOf(source, row);
    }
    if (idx > -1) {
      source.splice(idx, 1);
    }
  }

  deleteByVal(source: any[], key: any, val: any): any[] | undefined {
    if (!source) {
      return;
    }
    for (let i = source.length - 1; i >= 0; i--) {
      if (source[i][key] === val) {
        source.splice(i, 1);
      }
    }
    return source;
  }

  addSum(nums: any): number {

    let output = 0;
    _.each(nums, (n) => {
      output += (parseFloat(n || 0) || 0);
    });

    return output;

  }

  getMonthShortName(monthNumber: number): string {

    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    return monthNames[monthNumber] || 'Unkown';

  }

  find(haystack: any[], key: any, needle?: any): any {

    if (!_.isUndefined(needle)) {
      return _.find(haystack, (row) => {
        return row[key] === needle;
      });
    } else {
      return _.find(haystack, (row) => {
        return row[key];
      });
    }

  }

  filter(haystack: any[], key: any, needle?: any): any {

    if (!_.isUndefined(needle)) {
      return _.filter(haystack, (row) => {
        return row[key] === needle;
      });
    } else {
      return _.filter(haystack, (row) => {
        return row[key];
      });
    }

  }

  colorIsDark(hex: string): boolean {

    return this.luminanceFromHex(hex) < 60;

  }

  hexToRGB(hex: string): [number, number, number] {

    const rgb: [number, number, number] = [0, 0, 0];

    hex = hex.substr(1);

    for (let i = 0; i < 3; i++) {

      rgb[i] = parseInt(hex.substr(i * 2, 2), 16);

    }

    return rgb;

  }

  rgbToHex(r: number, g: number, b: number): string {

    let output = '#';

    [r, g, b].forEach(val => {

      val = Math.round(val);

      let hexDec = val.toString(16);

      if (hexDec.length < 2) {
        hexDec = '0' + hexDec;
      }

      output += hexDec;

    });

    return output;

  }

  // Takes a hex color, with a label
  // Returns an array of all lighter and darker shades, incremented by 10%;

  getColorTintsAndShades(hex: string): TintType[] {

    const output: TintType[] = [];

    const rgb = this.hexToRGB(hex);

    const TenPercentOfSpectrum = 0.1 * 255;

    // First get shades

    let Shades = [];

    const NotLessThanZero = (num: number) => {
      return Math.max(num, 0);
    };

    const NotGreaterThan255 = (num: number) => {
      return Math.min(num, 255);
    };

    const RoundToNearest10 = (num: number) => {
      return Math.ceil(num / 10) * 10;
    };

    const RgbSum = rgb.reduce((a, b) => a + b, 0);
    const RgbAverage = RgbSum / 3;

    // Starting with the brightest rgb value (Math.max)
    // Continue decreasing darkness until we reach 0
    // const MaxRgbVal = Math.sum(...rgb);

    for (let i = TenPercentOfSpectrum; i < RgbAverage; i += TenPercentOfSpectrum) {
      const LuminancePercent = RoundToNearest10(((RgbAverage - i) / 255) * 100);
      // if (LuminancePercent > 0) {
      Shades.push(
        {
          tint: `${LuminancePercent}`,
          color: this.rgbToHex(
            NotLessThanZero(rgb[0] - i),
            NotLessThanZero(rgb[1] - i),
            NotLessThanZero(rgb[2] - i)
          )
        });
      // }
    }

    // Reverse to start with the darkest shades, getting lighter
    Shades = Shades.reverse();

    // Push shades to output
    output.push(...Shades);

    // Push Actual Color
    output.push({
      tint: `${RoundToNearest10((RgbAverage / 255) * 100)}`,
      color: hex
    });

    const Tints = [];

    // Starting with the darkest rgb value (Math.min)
    // Continue increasing brightness until we reach 255
    for (let i = TenPercentOfSpectrum; i < 255 - RgbAverage; i += TenPercentOfSpectrum) {
      const LuminancePercent = RoundToNearest10(((i + RgbAverage) / 255) * 100);
      Tints.push({
        tint: `${LuminancePercent}`,
        color: this.rgbToHex(
          NotGreaterThan255(rgb[0] + i),
          NotGreaterThan255(rgb[1] + i),
          NotGreaterThan255(rgb[2] + i)
        )
      });
    }

    // Push tints to output
    output.push(...Tints);

    return output;

  }

  // Take hex string, convert to RGB
  // Add luminance % (represents a percentage of 255)
  // convert back to hex & return
  addLuminance(hex: string, LumPercent: number): string {

    const rgb = this.hexToRGB(hex);

    const Negative = LumPercent < 0;

    LumPercent = (Math.abs(LumPercent) / 100);

    const LumAmount = Math.round(LumPercent * 255);

    const ModifiedRGB: [number, number, number] = [0, 0, 0];

    rgb.forEach((val, index) => {

      if (Negative) {
        val -= LumAmount;
      } else {
        val += LumAmount;
      }

      if (val < 0) {
        val = 0;
      } else if (val > 255) {
        val = 255;
      }

      ModifiedRGB[index] = val;

    });

    return this.rgbToHex(...ModifiedRGB);

  }

  luminanceFromHex(hex: string): number {

    const rgb = this.hexToRGB(hex);

    const sum = rgb.reduce((previous, current) => current += previous);

    const luminance = Math.round((sum / (255 * 3)) * 100);

    return luminance;

  }

}