import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  constructor(
    private utils: UtilsService,
  ) { }

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: `Support ${this.utils.orgTitle}`,
      Title: 'Support ${this.utils.orgTitle}',
      Description: `Together we can establish a 21st-Century Madison College.`,
    });
  }

}
