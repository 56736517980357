import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { AddressType, PersonType, DonationType } from 'src/app/services/DataStore';
import { UtilsService } from 'src/app/services/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: [
    './account.component.scss',
  ]
})
export class AccountComponent implements OnInit, OnDestroy {

  admin = false;
  customer: PersonType | null = null;
  fetched = false;

  Donations: DonationType[] = [];
  ActiveRecurringDonations: DonationType[] = [];

  authSub: Subscription | null = null;

  myAddresses: AddressType[] = [];

  onAuthChange = (loggedIn: boolean) => {
    this.admin = this.authService.isTopAdmin();
    this.customer = this.authService.person;
    if (loggedIn && !this.fetched) {
      this.fetched = true;
      this.load();
    }
  }

  constructor(
    private authService: AuthService,
    private db: DbService,
    private utils: UtilsService,
    public snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.authService.onStateChange(this.onAuthChange);
    this.utils.updateMeta({
      BrowserTitle: `My Account`,
      Title: `My Account`,
      Description: `My Account`,
    });
  }

  ngOnDestroy(): void {
    this.authService.removeStateChangeCallback(this.onAuthChange);
  }

  nth(d: number): string {
    if (d > 3 && d < 21) {
      return 'th';
    }
    switch (d % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  donationDayOfMonth(donation: DonationType): string {

    if (!donation.createdAt) {
      return '';
    }

    const date = new Date(donation.createdAt);
    let DayOfMonth = date.getDate();
    if (DayOfMonth > 28) {
      DayOfMonth = 28;
    }

    return DayOfMonth + this.nth(DayOfMonth);

  }

  async cancel(donation: DonationType): Promise<void> {
    if (confirm('Cancel this donation?')) {
      donation.Active = 'false';
      this.db.put(donation);
      this.utils.delete(this.ActiveRecurringDonations, donation);
    }
  }

  async load(): Promise<void> {

    if (!this.authService.personID) {
      return;
    }

    const person = await this.db.get('Person', this.authService.personID) as PersonType;

    this.myAddresses = person.Addresses || [];
    this.Donations = person.Donations || [];
    this.utils.dateDesc(this.Donations);

    this.Donations.forEach(itm => {
      if (itm.Recurring === 'true' && itm.Active === 'true') {
        this.ActiveRecurringDonations.push(itm);
      }
    });

    this.ActiveRecurringDonations = this.ActiveRecurringDonations.reverse();

  }

}
