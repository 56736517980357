import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-eastward-header-nav',
  templateUrl: './eastward-header-nav.component.html',
  styleUrls: ['./eastward-header-nav.component.scss']
})
export class EastwardHeaderNavComponent implements OnInit {

  constructor() { }

  @Input() simple = false;

  ngOnInit(): void {
  }

}
