<div mat-dialog-content>
    <div class="header mat-elevation-z2 sec">
        <img src="https://cdn2.tda.website/beehive/20200901/My-Notifications.svg">
        <div class="txt">
            <h1>Be the first to know</h1>
            <p>Get updates from Madison, Eastward, The Maker Heals & Beehive</p>
        </div>
    </div>
    <div class="main sec" *ngIf="prefs !== null">
        <div class="space"></div>
        <div class="inner">
            <p>{{ auth.person?.First || 'Hi' }}, how often would you like to know what's going on?</p>
            <p>&nbsp;</p>
            <mat-radio-group aria-label="Select notification frequency" [(ngModel)]="prefs.Frequency">
                <div matRipple class="radio-outer" (click)="prefs.Frequency='Daily';" [ngClass]="{selected: prefs.Frequency === 'Daily' }">
                    <div class="mat-inner">
                        <mat-radio-button value="Daily"></mat-radio-button>
                        <span class="custom-mat-label">Daily</span>
                    </div>
                </div>
                <div matRipple class="radio-outer" (click)="prefs.Frequency='Weekly';" [ngClass]="{selected: prefs.Frequency === 'Weekly' }">
                    <div class="mat-inner">
                        <mat-radio-button value="Weekly"></mat-radio-button>
                        <span class="custom-mat-label">Weekly</span>
                    </div>
                </div>
                <div matRipple class="radio-outer" (click)="prefs.Frequency='Fortnightly';" [ngClass]="{selected: prefs.Frequency === 'Fortnightly' }">
                    <div class="mat-inner">
                        <mat-radio-button value="Fortnightly"></mat-radio-button>
                        <span class="custom-mat-label">Fortnightly</span>
                    </div>
                </div>
                <div matRipple class="radio-outer" (click)="prefs.Frequency='Monthly';" [ngClass]="{selected: prefs.Frequency === 'Monthly' }">
                    <div class="mat-inner">
                        <mat-radio-button value="Monthly"></mat-radio-button>
                        <span class="custom-mat-label">Monthly</span>
                    </div>
                </div>
            </mat-radio-group>
            <p style="margin-bottom: 10px;"><small>We'll send you an email digest with highlights from the activity stream.</small></p>

            <div [hidden]="isDialog">
                <button mat-stroked-button color="primary" (click)="save()">Save</button>
            </div>
            <p>&nbsp;</p>
            <p>Other preferences</p>
            <p>&nbsp;</p>
            <mat-slide-toggle [(ngModel)]="prefs.MuteComments">Mute comment notifications</mat-slide-toggle>
            <p>&nbsp;</p>
            <mat-slide-toggle [(ngModel)]="prefs.Unsubscribed">Unsubscribe from everything</mat-slide-toggle>
            <p>&nbsp;</p>

            <div [hidden]="isDialog">
                <button mat-stroked-button color="primary" (click)="save()">Save</button>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions *ngIf="isDialog" align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" [disabled]="saving" (click)="saveDialog()">Confirm</button>
</div>