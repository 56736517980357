<app-eastward-header-nav class="no-print" [simple]="true"></app-eastward-header-nav>

<div *ngIf="!authenticated" style="padding: 30px;">
    <app-login-with-provider label="Login to view your receipt"></app-login-with-provider>
</div>

<div class="outer" *ngIf="authenticated && Inv">
    <div class="wrapper">
        <div class="head">
            <div class="left">
                <div class="txt">
                    Receipt #{{ Inv.InvoiceID }} <br> {{ Inv.createdAt | date:'mediumDate' }}
                </div>
            </div>
            <div class="right mobile-hide">
                <img src="https://cdn2.tda.website/beehive/20200607/Eastward-logo.svg" class="logo">
            </div>
        </div>
        <div class="head" style="align-items: flex-start;">
            <div class="a">
                <h2 class="grey">Customer Details</h2>
                <div *ngIf="Inv.Address">
                    {{ Inv.Address.name }} <br> {{ Inv.Address.mobile }} <br> {{ Inv.Address.address1 }} <br>
                    <span *ngIf="Inv.Address.address2">{{ Inv.Address.address2 }} <br></span> {{ Inv.Address.suburb }} {{ Inv.Address.postcode }} {{ Inv.Address.state }}
                </div>
                <div *ngIf="!Inv.Address">
                    {{ Inv.OwnerObj?.First }} {{ Inv.OwnerObj?.Last }} <br> &lt;{{ Inv.OwnerObj?.Email }}&gt;
                </div>
            </div>
            <div class="b">
                <h2 class="grey">Payment to</h2>
                <div>
                    Madison Missions <br> ABN: 93 637 957 085 <br> 19 Westleys Rd <br> Faulkland NSW 2422 <br> mail@madison.org.au <br>
                </div>
            </div>
        </div>

        <div class="body">
            <table cellspacing="0">
                <thead>
                    <th>
                        <h2 class="grey align-left">Description</h2>
                    </th>
                    <th>
                        <h2 class="grey align-right">Price</h2>
                    </th>
                </thead>
                <tr *ngFor="let row of Rows">
                    <td class="align-left">{{ row.Description }}</td>
                    <td class="align-right">{{ row.Amount ? (row.Amount | currency) : '' }}</td>
                </tr>
                <tr>
                    <td class="align-right">TOTAL:</td>
                    <td class="align-right">{{ Inv.Total | currency }}</td>
                </tr>
                <tr>
                    <td class="align-right">GST:</td>
                    <td class="align-right">{{ Inv.Tax | currency }}</td>
                </tr>
            </table>
            <hr>
            <div class="foot">
                <div>Status: {{ Inv.Status }}</div>
                <div [hidden]="!Inv.PaymentSource">Paid by {{ Inv.PaymentSource?.brand }} ending in {{ Inv.PaymentSource?.last4 }}</div>
            </div>
        </div>
    </div>
</div>