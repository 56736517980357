<h1 mat-dialog-title>{{ screen }}</h1>

<form [formGroup]="loginForm" *ngIf="loginForm && screen == 'Login'" autocomplete="off" novalidate (ngSubmit)="login(loginForm.value)">

    <mat-form-field class="field">
        <input matInput tabindex="1" formControlName="email" placeholder="Email" type="text">
        <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error>
        <mat-error *ngIf="hasError('email', 'email')">Invalid email</mat-error>
    </mat-form-field>

    <mat-form-field class="field">
        <input matInput tabindex="2" formControlName="password" placeholder="Password" [type]="hidePwd ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hidePwd = !hidePwd" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePwd">
            <mat-icon>{{hidePwd ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="hasError('password', 'required')">Password is required</mat-error>
        <mat-error *ngIf="hasError('password', 'pattern')">Must be at least six characters with a letter and a number.</mat-error>
    </mat-form-field>

    <mat-form-field class="field">
        <mat-label>Remember me on this device:</mat-label>
        <mat-select tabindex="3" formControlName="remember">
            <mat-option value="10">for 10 minutes</mat-option>
            <mat-option value="60">for 1 hour</mat-option>
            <mat-option value="1440">for 1 day</mat-option>
            <mat-option value="10080">for 1 week</mat-option>
            <mat-option value="43200">for 1 month</mat-option>
            <mat-option value="*">Forever (until I logout)</mat-option>
        </mat-select>
    </mat-form-field>

    <p>
        <button type="submit" tabindex="3" mat-raised-button color="primary" [disabled]="activity || !loginForm.valid">Login</button>
    </p>

    <div>
        Don't have an account? <a class="link" (click)="screen = 'Signup'">Sign up</a>
    </div>
    <div>
        Forgot your password? <a class="link" (click)="screen = 'Recover Password'">Recover</a>
    </div>

</form>

<form [formGroup]="signupForm" *ngIf="signupForm && screen == 'Signup'" autocomplete="off" novalidate (ngSubmit)="signup(signupForm.value)">

    <mat-form-field class="field">
        <input matInput tabindex="1" formControlName="first" placeholder="Firstname" type="text">
        <mat-error *ngIf="hasSignupError('first', 'required')">Firstname is required</mat-error>
    </mat-form-field>

    <mat-form-field class="field">
        <input matInput tabindex="2" formControlName="last" placeholder="Lastname" type="text">
        <mat-error *ngIf="hasSignupError('last', 'required')">Lastname is required</mat-error>
    </mat-form-field>

    <mat-form-field class="field">
        <input matInput tabindex="3" formControlName="email" placeholder="Email" type="text">
        <mat-error *ngIf="hasSignupError('email', 'required')">Email is required</mat-error>
        <mat-error *ngIf="hasSignupError('email', 'email')">Invalid email</mat-error>
    </mat-form-field>

    <mat-form-field class="field" style="margin-bottom: 20px;">
        <input matInput tabindex="4" formControlName="password" placeholder="Password" [type]="hidePwd ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hidePwd = !hidePwd" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePwd">
    <mat-icon>{{hidePwd ? 'visibility_off' : 'visibility'}}</mat-icon>
</button>
        <mat-hint>Must be at least six characters with a letter and a number.</mat-hint>
        <mat-error *ngIf="hasSignupError('password', 'required')">Password is required</mat-error>
        <mat-error *ngIf="hasSignupError('password', 'pattern')">Must be at least six characters with a letter and a number.</mat-error>
    </mat-form-field>

    <p>
        <button type="submit" tabindex="5" mat-raised-button color="primary" [disabled]="activity || !signupForm.valid">Signup</button>
    </p>

    <div>
        Already have an account? <a class="link" (click)="screen = 'Login'">Login</a>
    </div>

</form>

<form [formGroup]="recoverForm" *ngIf="recoverForm && screen === 'Recover Password'" autocomplete="off" novalidate (ngSubmit)="recover(recoverForm.value)">

    <mat-form-field class="field">
        <input matInput tabindex="1" formControlName="email" placeholder="Email" type="text">
        <mat-error *ngIf="hasRecoverError('email', 'required')">Email is required</mat-error>
        <mat-error *ngIf="hasRecoverError('email', 'email')">Invalid email</mat-error>
    </mat-form-field>

    <p>
        <button type="submit" tabindex="2" mat-raised-button color="primary" [disabled]="activity || !recoverForm.valid">Recover</button>
    </p>

    <div>
        <a class="link" (click)="screen = 'Login'">Login</a>
    </div>

</form>