import { Component, OnInit, Inject, Input } from '@angular/core';
import { WINDOW } from 'src/app/services/window.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginProvidersDialogComponent } from 'src/app/dialogs/login-providers-dialog/login-providers-dialog.component';
import { MyNotificationsComponent } from '../my-notifications/my-notifications.component';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-activity-stream',
  templateUrl: './activity-stream.component.html',
  styleUrls: ['./activity-stream.component.scss']
})
export class ActivityStreamComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private auth: AuthService,
    private dialog: MatDialog,
    private utils: UtilsService,
  ) { }

  @Input() embedded!: boolean;

  ngOnInit(): void {

    if (!this.embedded) {
      this.utils.updateMeta({
        BrowserTitle: 'Activity Stream',
        Title: 'Activity Stream',
        Description: `What's happening as it happens. View the latest news, and subscribe to updates.`,
        Image: `https://cdn2.tda.website/beehive/20200902/wyCgDmkx-Madison-Infographic-4x.png?h=550&w=550&fit=crop&auto=format`,
      });
    }
  }

  open(link: string): void {
    this.window.open(link);
  }

  scrollToEl(id: string, smooth: boolean = false): void {
    const el = this.document.getElementById(id) as HTMLElement;
    if (el) {
      if (this.window.scrollTo) {
        this.window.scrollTo({
          top: el.offsetTop,
          behavior: smooth ? 'smooth' : 'auto',
        });
      }
    }
  }

  notifications(): void {
    if (this.auth.getSessionValue()) {
      this.openNotificationPrefs();
    } else {
      const ref = this.dialog.open(LoginProvidersDialogComponent, {
        width: '500px',
        autoFocus: false,
      });
      ref.afterClosed().subscribe(async () => {
        if (this.auth.authChecked) {
          this.openNotificationPrefs();
        }
      });
    }
  }

  openNotificationPrefs(): void {

    this.dialog.open(MyNotificationsComponent, {
      data: true,
    });

  }

}
