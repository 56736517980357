import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbService } from 'src/app/services/db.service';
import { StaffAppUserType } from 'src/app/services/DataStore';

@Component({
  selector: 'app-view-author',
  templateUrl: './view-author.component.html',
  styleUrls: ['./view-author.component.scss']
})
export class ViewAuthorComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public authorID: string,
    private db: DbService,
  ) { }

  author: StaffAppUserType | null = null;
  imageSettings = '?h=300&w=400&fit=facearea&facepad=10&auto=format';

  ngOnInit(): void {
    this.load();
  }

  async load(): Promise<void> {
    this.author = await this.db.get('AppUser', this.authorID.replace('APP_', ''), false, 0);
  }

}
