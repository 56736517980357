import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, InjectionToken, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StringToDatePipe } from 'src/app/pipes/toDate';
import { MadisonBlogPostType, setTimeout$, VideoType } from 'src/app/services/DataStore';
import { DbService } from 'src/app/services/db.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-all-posts',
  templateUrl: './all-posts.component.html',
  styleUrls: ['./all-posts.component.scss']
})
export class AllPostsComponent implements OnInit, AfterViewInit {

  isLoading = true;
  isBrowser: boolean;

  @ViewChild('viewport') CdkScroll!: CdkVirtualScrollViewport;

  constructor(
    private breakpoint$: BreakpointObserver,
    private db: DbService,
    private toDate: StringToDatePipe,
    @Inject(WINDOW) private window: Window,
    private utils: UtilsService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    breakpoint$.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {

        this.chunkSize.next(2);

      } else if (result.breakpoints[Breakpoints.Small]) {

        this.chunkSize.next(3);

      }
      else if (result.breakpoints[Breakpoints.Medium]) {

        this.chunkSize.next(4);

      }
      else if (result.breakpoints[Breakpoints.Large]) {

        this.chunkSize.next(5);

      }
      else {

        this.chunkSize.next(6);

      }
    });
  }

  items: MadisonBlogPostType[] = [];
  renderer = [];
  chunkSize = new BehaviorSubject(2);

  ngOnInit(): void {
    this.load();
    this.utils.updateMeta({
      BrowserTitle: 'All news posts',
      Title: 'All news posts',
      Description: 'A news wall with everything from Eastward & Madison Missions.',
    });
  }

  ngAfterViewInit(): void {
    // if (this.window.scrollTo) {
      // this.window.scrollTo(0, 0);
    // }
  }

  async load(): Promise<void> {
    if (!this.db.allPostsCache.length) {
      this.items = Array.from(
        await this.db.listAll('MadisonBlogPost', {
          columns: ['Title', 'Images', 'Videos', 'CreatedAt', 'Likes', 'Views', 'Comments'],
        })
      );
      this.items.sort((a, b) => {
        return this.toDate.transform(b.CreatedAt || '').getTime() - this.toDate.transform(a.CreatedAt || '').getTime();
      });
      this.db.allPostsCache = this.items;
    } else {
      this.items = this.db.allPostsCache;
    }
    setTimeout(() => {
      if (this.CdkScroll) {

        this.CdkScroll.getElementRef().nativeElement.scrollTop = this.db.allPostsScrollPos || 0;
        this.CdkScroll.elementScrolled().subscribe(res => {
          this.db.allPostsScrollPos = (res.target as HTMLElement).scrollTop;
        });
      }
    }, 0);
    this.render();
    this.isLoading = false;
  }

  render(): void {
    this.chunkSize.subscribe(chunkSize => {
      this.renderer = this.toChunks(this.items, chunkSize);
    });
  }

  tail = (a: any[]) => a[a.length - 1];

  chunkReducerForSize = (chunkSize = 2) => (result: any[], item: MadisonBlogPostType) => {
    const lastElm = this.tail(result);
    if (lastElm.length === chunkSize) {
      result.push([item]);
    } else {
      lastElm.push(item);
    }
    return result;
  }

  toChunks = (arr: any[], chunkSize = 5) => {
    return arr.reduce(this.chunkReducerForSize(chunkSize), [[]]);
  }

  cardStyle(post: MadisonBlogPostType): { [key: string]: string } {
    let image = '';
    if (post.Images && post.Images.length && !post.Videos?.length) {
      image = post.Images[0] + '?w=300&h=200&fit=crop&crop=faces';
    } else if (post.Videos && post.Videos.length) {
      image = this.videoUrl(post.Videos[0]);
    }
    if (image !== '') {
      return {
        'background-image': `url('${image}')`
      };
    } else {
      return {
        'background-color': `#333`
      };
    }
  }

  videoUrl(vid: VideoType): string {
    if (vid.PlaybackIDs) {
      if (vid && vid.MuxStatus === 'ready') {
        return 'https://image.mux.com/' + vid.PlaybackIDs[0].id + '/animated.gif?width=180&fps=3&start=' +
          (vid.GifStartTime || '0');
      } else {
        return 'https://cdn2.tda.website/beehive/20201104/Video-Processing-4x-80.jpg?w=130&h=130&fit=crop&crop=faces';
      }
    }
    return '';
  }

}
