import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-music-camp-photos',
  templateUrl: './music-camp-photos.component.html',
  styleUrls: ['./music-camp-photos.component.scss']
})
export class MusicCampPhotosComponent implements OnInit {

  constructor(
    private utils: UtilsService,
  ) { }

  imageData: Array<string | number>[] = [
    ['20201106/2015-Music-Camp-043.jpg', 1920, 1440, '#2bb0c7'],
    ['20201106/2015-Music-Camp-079.jpg', 1920, 1275, '#cc3552'],
    ['20201106/2015-Music-Camp-088.jpg', 1920, 1275, '#a32b5d'],
    ['20201106/2015-Music-Camp-131.jpg', 1920, 1275, '#bb6943'],
    ['20201106/2015-Music-Camp-165.jpg', 1920, 1275, '#e5124c'],
    ['20201106/2015-Music-Camp-234.jpg', 1920, 1275, '#c13050'],
    ['20201106/2015-Music-Camp-240.jpg', 1920, 1440, '#e52261'],
    ['20201106/2015-Music-Camp-312.jpg', 1920, 1277, '#d40b1a'],
    ['20201106/2015-Music-Camp-313.jpg', 1920, 1277, '#da0b26'],
    ['20201106/2015-Music-Camp-340.jpg', 1920, 1441, '#f20830'],
    ['20201106/2015-Music-Camp-347.jpg', 1920, 993, '#cd942c'],
    ['20200903/67797586-689184321493882-6593249878577512448-o.jpg', 1875, 1248, '#01b7f6'],
    ['20201106/A-Tender-Heart-11--2-.jpg', 1920, 1080, '#d72a33'],
    ['20201106/A-Tender-Heart-12.jpg', 1920, 1080, '#589ac5'],
    ['20201106/A-Tender-Heart-13.jpg', 1920, 1080, '#4e92ce'],
    ['20201106/A-Tender-Heart-18.jpg', 1920, 1080, '#f15c5d'],
    ['20201106/A-Tender-Heart-19.jpg', 1920, 1080, '#bd3236'],
    ['20201106/A-Tender-Heart-20--2-.jpg', 1920, 1080, '#f25b5d'],
    ['20201106/Above-Rubies-Beach.jpg', 3576, 1336, '#cb9771'],
    ['20201106/ACIB-Poster-2.jpg', 1920, 1080, '#a29b38'],
    ['20201106/All-For-Christ.00-00-28-23.Still005.jpg', 1920, 1080, '#2d718e'],
    ['20201106/All-For-Christ.00-00-52-00.Still009.jpg', 1920, 1080, '#0a70aa'],
    ['20201106/All-For-Christ.00-02-11-00.Still019.jpg', 1920, 1080, '#ef3432'],
    ['20201106/All-For-Christ.00-04-00-03.Still031.jpg', 1920, 1080, '#ab7450'],
    ['20201106/All-For-Christ.00-04-35-23.Still035.jpg', 1920, 1080, '#d32a2f'],
    ['20201106/All-the-Boys.jpg', 1920, 2087, '#c4798a'],
    ['20201106/Always-Cheerful-Large-Pano-Stitch.jpg', 4958, 964, '#94ab33'],
    ['20201106/Always-Cheerful-Panorama.jpg', 2244, 642, '#cf6691'],
    ['20201106/Always-Cheerful.00-03-28-06.Still050.jpg', 1920, 1080, '#a99450'],
    ['20201106/Anywhere-Group.jpg', 1920, 1080, '#1975d9'],
    ['20201106/Behind-The-Scenes.jpg', 1920, 1080, '#af6f51'],
    ['20201106/Behold-I-Come-Pano.jpg', 1440, 276, '#8e1966'],
    ['20201106/Behold-I-Come-Poster.jpg', 1920, 1080, '#ac9450'],
    ['20201106/Bound-For-The-Land-Of-Canaan-Multicam.00-03-05-12.Still063.jpg', 1920, 1080, '#8f9037'],
    ['20201106/boys-unit-shot.jpg', 955, 293, '#9a4d30'],
    ['20201106/Boys-Unit.jpg', 2500, 2051, '#1e6bc8'],
    ['20201106/Camp-Group-Shot.jpg', 2304, 748, '#3db3cb'],
    ['20201106/Dare-to-Be-a-Daniel-drone-pano.jpg', 1449, 686, '#a20a94'],
    ['20201106/Dare-to-Be-a-Daniel-drone-pano2.jpg', 1449, 686, '#a20a93'],
    ['20201106/Dare-to-Be-a-Daniel-drone-pano3.jpg', 1449, 686, '#c8310b'],
    ['20201106/Dare-to-Be-a-Daniel-drone-pano3-26.jpg', 1449, 686, '#d12915'],
    ['20201106/Dare-to-Be-a-Daniel.jpg', 3264, 1471, '#ab13ac'],
    ['20201106/download--3-.png', 956, 271, '#c4b065'],
    ['20201106/DSC-0289.jpg', 2500, 1667, '#e49728'],
    ['20201106/DSC-0290.jpg', 2500, 1667, '#e4902a'],
    ['20201106/DSC-0360.jpg', 2500, 1667, '#c08b3d'],
    ['20201106/DSC-0374.jpg', 2500, 1667, '#c23e37'],
    ['20201106/DSC-0504.jpg', 2500, 1667, '#e4b84a'],
    ['20201106/DSC-0539.jpg', 2500, 1667, '#36acef'],
    ['20201106/DSC-0550.jpg', 2500, 1667, '#1b51af'],
    ['20201106/DSC-0555.jpg', 2500, 1667, '#0d9c9d'],
    ['20201106/DSC-0559.jpg', 2500, 1667, '#11a3b3'],
    ['20201106/DSC-0589.jpg', 2500, 1667, '#185cd7'],
    ['20201106/DSC-0844.jpg', 2500, 1667, '#b83217'],
    ['20201106/DSC-0846.jpg', 2500, 1667, '#1d5ad1'],
    ['20201106/DSC-0849.jpg', 2500, 1667, '#016acf'],
    ['20201106/DSC-0854.jpg', 2500, 1667, '#b8970d'],
    ['20201106/DSC-0877.jpg', 2500, 1667, '#506fbb'],
    ['20201106/DSC-1143.jpg', 2500, 1667, '#dda163'],
    ['20201106/DSC-1148.jpg', 2500, 1667, '#d69f62'],
    ['20201106/DSC-1364.jpg', 2500, 1667, '#0c5caa'],
    ['20201106/DSC-1369.jpg', 2500, 1667, '#1792ab'],
    ['20201106/DSC-1381.jpg', 2500, 1667, '#a1d457'],
    ['20201106/DSC-1401.jpg', 2500, 1663, '#375bac'],
    ['20201106/DSC-1425.jpg', 2500, 1667, '#2ca3f0'],
    ['20201106/DSC-1434.jpg', 2500, 1667, '#22c24f'],
    ['20201106/DSC-1449.jpg', 1667, 2500, '#91290d'],
    ['20201106/DSC-1539.jpg', 2500, 1667, '#d24993'],
    ['20201106/DSC-1618.jpg', 2500, 1667, '#8c4f0f'],
    ['20201106/DSC-1628.jpg', 2500, 1667, '#e69122'],
    ['20201106/DSC-1667.jpg', 2500, 1667, '#de1d21'],
    ['20201106/DSC-1715.jpg', 2500, 803, '#11619d'],
    ['20201106/DSC-1787.jpg', 2500, 1667, '#0b419c'],
    ['20201106/DSC-1790.jpg', 2500, 1667, '#68a0e4'],
    ['20201106/DSC-2103.jpg', 2500, 1667, '#d3182f'],
    ['20201106/DSC-2155.jpg', 2500, 1667, '#da9b3b'],
    ['20201106/DSC-2158.jpg', 1667, 2500, '#e32121'],
    ['20201106/DSC-2186.jpg', 2500, 1667, '#069bfa'],
    ['20201106/DSC-2195.jpg', 2500, 1667, '#0d59af'],
    ['20201106/DSC-2237.jpg', 2500, 1667, '#aa6b34'],
    ['20201106/DSC-2950.jpg', 2500, 1665, '#15b8e3'],
    ['20201106/DSC-2993.jpg', 2500, 1665, '#d0af0f'],
    ['20201106/DSC-3014-22.jpg', 4528, 3016, '#e35e20'],
    ['20201106/DSC-3833.jpg', 2500, 1663, '#ac3322'],
    ['20201106/DSC-4024.jpg', 2500, 1324, '#d52118'],
    ['20201106/DSC-4045.jpg', 2500, 1663, '#5773b2'],
    ['20201106/DSC-4178.jpg', 2500, 1663, '#067bf3'],
    ['20201106/DSC-4275.jpg', 2500, 1663, '#02aaea'],
    ['20201106/DSC-4353.jpg', 2500, 1663, '#00b6f7'],
    ['20201106/DSC-4374.jpg', 2500, 1663, '#ce6c18'],
    ['20201106/DSC-4465.jpg', 2500, 1663, '#00c8ff'],
    ['20201106/DSC-4601.jpg', 2500, 1663, '#deec09'],
    ['20201106/DSC-4662.jpg', 2500, 1663, '#f2534b'],
    ['20201106/DSC-4702.jpg', 2500, 1663, '#219fe3'],
    ['20201106/DSC-4719.jpg', 2500, 1663, '#c3ca4e'],
    ['20201106/DSC-4724.jpg', 2500, 1663, '#d2dd25'],
    ['20201106/DSC-4743.jpg', 2500, 1663, '#6dcff0'],
    ['20201106/DSC-4785-edit.jpg', 2500, 1663, '#0b97e5'],
    ['20201106/DSC-4830.jpg', 2500, 1663, '#0d8ffa'],
    ['20201106/DSC-4835.jpg', 2500, 1663, '#d5b632'],
    ['20201106/DSC-4907.jpg', 2500, 1663, '#d5c32d'],
    ['20201106/DSC-5353.jpg', 2500, 1663, '#d72649'],
    ['20201106/DSC-5361.jpg', 2500, 1663, '#ca2538'],
    ['20201106/DSC-5460.jpg', 2500, 1663, '#b6352b'],
    ['20201106/DSC-5483.jpg', 2500, 1663, '#dd9968'],
    ['20201106/DSC-5564.jpg', 2500, 1665, '#dca262'],
    ['20201106/DSC-5939.jpg', 2500, 1665, '#cacb2a'],
    ['20201106/DSC-6268.jpg', 2500, 1665, '#d8a711'],
    ['20201106/DSC-6275.jpg', 2500, 1665, '#d1582c'],
    ['20201106/DSC-6524.jpg', 2500, 1663, '#d5252f'],
    ['20201106/DSC-6634.jpg', 2500, 1663, '#d1a90c'],
    ['20201106/DSC-6659.jpg', 2500, 1663, '#e7b20a'],
    ['20201106/DSC-6681.jpg', 2500, 1663, '#b36717'],
    ['20201106/DSC-6814.jpg', 2442, 2500, '#b36e29'],
    ['20201106/DSC-6904.jpg', 2398, 2500, '#cf923a'],
    ['20201106/DSC-6910.jpg', 2456, 2500, '#c5762e'],
    ['20201106/DSC-7093.jpg', 2500, 1663, '#de1d2e'],
    ['20201106/DSC-7173.jpg', 2500, 1663, '#cd4b34'],
    ['20201106/DSC-7268.jpg', 2500, 1663, '#cf2a12'],
    ['20201106/DSC-7274.jpg', 2500, 1663, '#d84022'],
    ['20201106/DSC-7278.jpg', 2500, 1663, '#d03630'],
    ['20201106/DSC-7406.jpg', 2500, 1663, '#dd6521'],
    ['20201106/DSC-7773.jpg', 2500, 1663, '#b04e32'],
    ['20201106/Dsozo-Camp-2016-064.jpg', 1920, 1080, '#7394cf'],
    ['20201106/Dsozo-Camp-2016-082.jpg', 1920, 1080, '#c33a0e'],
    ['20201106/DSozo-NSW-2017-07.jpg', 2272, 1704, '#e29d29'],
    ['20201106/DSozo-NSW-2017-13-67.jpg', 5312, 1172, '#c94333'],
    ['20201106/DSozo-NSW-2017-39.jpg', 2272, 1278, '#a11617'],
    ['20201106/DSozo-WA-2018177.jpg', 2048, 1365, '#d4ac4c'],
    ['20201106/Group-Panorama-2-edit.jpg', 6000, 1841, '#31aad3'],
    ['20201106/Group-Photo--2-.jpg', 4236, 1380, '#ee3250'],
    ['20201106/Group-Photo-2.jpg', 1280, 854, '#cd2933'],
    ['20201106/Group-Photo-5a4dbceb54056.jpg', 1280, 660, '#d62738'],
    ['20201106/Group-Shot--2-.jpg', 4288, 2848, '#ce3d4e'],
    ['20201106/Id-Rather-Have-Jesus-Music-Video.00-00-20-23.Still007.png', 1920, 1080, '#c22a32'],
    ['20201106/image1.jpg', 2557, 1239, '#c4b064'],
    ['20201106/IMG-20190715-151551.jpg', 3648, 2736, '#456aa6'],
    ['20201106/IMG-6334.jpg', 2272, 1515, '#e7a22e'],
    ['20201106/IMG-E6363.jpg', 2557, 654, '#88362a'],
    ['20201106/Into-The-Woods-2.00-02-15-08.Still018.jpg', 1920, 1080, '#ea394d'],
    ['20201106/Into-The-Woods-2.00-02-36-08.Still020.jpg', 1920, 1080, '#d56358'],
    ['20201106/Into-The-Woods-2.00-03-34-22.Still029.jpg', 1920, 1080, '#ef2a38'],
    ['20201106/Into-The-Woods-2.00-06-10-06.Still010-14.jpg', 1920, 1080, '#d03439'],
    ['20201106/Ku-ring-gai-chase-group-shot.jpg', 3905, 2196, '#01cbd3'],
    ['20201106/Line-Call-1.jpg', 2500, 1664, '#b23d2f'],
    ['20201106/Love-of-God-Panorama-2-edit.jpg', 6928, 1798, '#2559a0'],
    ['20201106/MC-2018-002-Kayla-Beside-Still-Waters-24.jpg', 2500, 1663, '#93a127'],
    ['20201106/MC-2018-004-Tom-Sabbath-Sharing-23.jpg', 1664, 2500, '#c8c644'],
    ['20201106/MC-2018-005-Jylan-Sabbath-Sharing-33.jpg', 1664, 2500, '#378eca'],
    ['20201106/MC-2018-007-Tim-Tree-Lying-Down-63.jpg', 2500, 1664, '#c1c755'],
    ['20201106/MC-2018-008-Tim-Canopy-Lookout-77.jpg', 2500, 1664, '#bec637'],
    ['20201106/MC-2018-013-Group-Shot-Counsellors.jpg', 1539, 1025, '#288fcc'],
    ['20201106/MC-2018-014-Registration-1.jpg', 2500, 1663, '#609ac8'],
    ['20201106/MC-2018-029-Sam-Chef-4.jpg', 1664, 2500, '#f8cd02'],
    ['20201106/MC-2018-038-Ice-Breakers-9.jpg', 2500, 1664, '#c67606'],
    ['20201106/MC-2018-039-Ice-Breakers-10.jpg', 2500, 1663, '#c77506'],
    ['20201106/MC-2018-042-Food-3.jpg', 2500, 1664, '#cb8d38'],
    ['20201106/MC-2018-044-Singing-2.jpg', 2500, 1665, '#cf542d'],
    ['20201106/MC-2018-050-Campfire-2.jpg', 1865, 2500, '#c47033'],
    ['20201106/MC-2018-053-Boys-Unit.jpg', 2500, 2051, '#d5c817'],
    ['20201106/MC-2018-060-Bible-Study-3.jpg', 2500, 1664, '#bba14a'],
    ['20201106/MC-2018-074-Orchestra-16.jpg', 2500, 1664, '#c6b5aa'],
    ['20201106/MC-2018-078-Orchestra-4.jpg', 2500, 1664, '#d8041d'],
    ['20201106/MC-2018-120-Audio-Team.jpg', 2500, 1664, '#2b4ac1'],
    ['20201106/MC-2018-121-Singing-16.jpg', 1664, 2500, '#eb0b02'],
    ['20201106/MC-2018-123-Singing-20.jpg', 1664, 2500, '#c95b2e'],
    ['20201106/MC-2018-125-Quiet-Time.jpg', 2500, 1664, '#c51227'],
    ['20201106/MC-2018-126-Quiet-Time-1.jpg', 2500, 1664, '#8f8c13'],
    ['20201106/MC-2018-140-Workout-2.jpg', 2500, 1664, '#cabb2e'],
    ['20201106/MC-2018-141-Workout-3.jpg', 2500, 1664, '#b9c62e'],
    ['20201106/MC-2018-143-Workout-5.jpg', 2500, 1664, '#d44459'],
    ['20201106/MC-2018-148-Workshop-3.jpg', 2500, 1664, '#d22a4c'],
    ['20201106/MC-2018-168-Near-To-The-Heart-of-God-BTS-2.jpg', 2500, 1664, '#bb9849'],
    ['20201106/MC-2018-170-Near-To-The-Heart-of-God-BTS-3.jpg', 2500, 1664, '#2fcad2'],
    ['20201106/MC-2018-174-Group-Shot-1.jpg', 2500, 1664, '#269ecd'],
    ['20201106/MC-2018-176-Cabin-5.jpg', 2500, 1664, '#1597c6'],
    ['20201106/MC-2018-177-Cabin-1.jpg', 2500, 1663, '#1aa8d1'],
    ['20201106/MC-2018-178-Cabin-2.jpg', 2500, 1664, '#5476c8'],
    ['20201106/MC-2018-179-Cabin-3.jpg', 2500, 1664, '#2caed2'],
    ['20201106/MC-2018-180-Boys-Pyramid.jpg', 2050, 2500, '#4a6dbe'],
    ['20201106/MC-2018-181-Cabin-4.jpg', 2500, 1664, '#2aaacf'],
    ['20201106/MC-2018-202-Prayer-8.jpg', 2500, 1664, '#a95316'],
    ['20201106/MC-2018-203-BTS-13.jpg', 2500, 1664, '#4aacc6'],
    ['20201106/MC-2018-204-Group-Shot-4.jpg', 2500, 1664, '#31a8cf'],
    ['20201106/MC-2018-205-Group-Shot-7.jpg', 2500, 576, '#46b4ca'],
    ['20201106/MC-2018-206-Group-Shot-5.jpg', 2500, 1663, '#b7471b'],
    ['20201106/MC-2018-207-Group-Shot-Orchestra.jpg', 2246, 2500, '#cac33b'],
    ['20201106/MC-2018-209-BTS-15.jpg', 2500, 1663, '#4dbfd0'],
    ['20201106/MC-2018-210-Henry-1.jpg', 1663, 2500, '#c5160a'],
    ['20201106/MC-2018-216-BTS-1.jpg', 2500, 1664, '#2290af'],
    ['20201106/MC-2018-218-Joel-Ridgeway.jpg', 2500, 1664, '#9f2367'],
    ['20201106/MC-2018-224-Boss-Man-2.jpg', 1663, 2500, '#378cd2'],
    ['20201106/MC-2018-225-BTS-27.jpg', 2500, 1664, '#1ca0cb'],
    ['20201106/MC-2018-227-Group-Shot-6.jpg', 2500, 1545, '#c0313d'],
    ['20201106/MC-2018-231-Memorial-Concert-2.jpg', 2500, 1664, '#dd9b22'],
    ['20201106/mc2015-Panorama-Filming-Location-5-23.jpg', 1920, 564, '#cd932c'],
    ['20201106/Music-Camp-2016-052.jpg', 2304, 748, '#3db3cb'],
    ['20201106/Music-Camp-2016-091.jpg', 2272, 635, '#c98a73'],
    ['20201106/Music-Camp-2016-122.jpg', 3576, 1336, '#cb9771'],
    ['20201106/Music-Camp-2016-134.jpg', 2272, 782, '#4e5bb4'],
    ['20201106/Music-Camp-2016-145.jpg', 2272, 967, '#193886'],
    ['20201106/Music-Camp-2016-149.jpg', 2272, 1515, '#a13541'],
    ['20201106/Music-Camp-2016-166.jpg', 2272, 1515, '#314f8e'],
    ['20201106/Music-Camp-2016-177.jpg', 720, 960, '#ac523e'],
    ['20201106/Music-Camp-2016-193.jpg', 2272, 1704, '#c39776'],
    ['20201106/Music-Camp-2016-200-44.jpg', 2272, 1515, '#91ae4c'],
    ['20201106/Music-Camp-2016-206.jpg', 2272, 1515, '#87aa48'],
    ['20201106/Music-Camp-2016-229.jpg', 2272, 1282, '#2760b0'],
    ['20201106/Music-Camp-2017-050.jpg', 2500, 1667, '#923828'],
    ['20201106/Music-Camp-2017-160.jpg', 2500, 1109, '#987948'],
    ['20201106/Music-Camp-2017-169.jpg', 2500, 1318, '#9111a3'],
    ['20201106/Music-Camp-2017-170.jpg', 2500, 1318, '#3284e0'],
    ['20201106/Music-Camp-2017-173.jpg', 2500, 1873, '#cda24c'],
    ['20201106/Music-Camp-2019-386.jpg', 2500, 1324, '#d52118'],
    ['20201106/music-camp-3.jpg', 1920, 1080, '#ce8435'],
    ['20201106/music-camp-4.jpg', 1920, 1080, '#cb683e'],
    ['20201106/music-camp-5.jpg', 1920, 1080, '#3d5098'],
    ['20201106/music-camp-7.jpg', 1920, 852, '#b4985b'],
    ['20201106/Music-Camp-Morph.jpg', 2048, 540, '#bf3d3c'],
    ['20201106/Music-Camp-Production-Poster.jpg', 1866, 1400, '#8eac26'],
    ['20201106/Oh-How-I-Love-Jesus-Music-Video.00-00-02-17.Still001.png', 1920, 1080, '#31a9aa'],
    ['20201106/Oh-Love-That-Will-Not-Let-Me-Go.00-02-15-03.Still002-22.jpg', 1920, 1080, '#8e6034'],
    ['20201106/Oh-Love-That-Will-Not-Let-Me-Go.00-02-30-30.Still001.jpg', 1920, 1080, '#6a95c8'],
    ['20201106/Orchestra-1.jpg', 2500, 1663, '#3b86d0'],
    ['20201106/Orchestra-3.jpg', 1663, 2500, '#db344c'],
    ['20201106/Orchestra-7.jpg', 2500, 1664, '#305aa8'],
    ['20201106/orchestra-finale-16-9.jpg', 2140, 1204, '#31afcc'],
    ['20201106/Pano10.jpg', 2400, 500, '#bb3348'],
    ['20201106/Pano12.jpg', 2400, 500, '#d22136'],
    ['20201106/Pano22--2-.jpg', 2400, 500, '#db5960'],
    ['20201106/Pano25.jpg', 2400, 500, '#cb455e'],
    ['20201106/Pano29.jpg', 2400, 500, '#d8235b'],
    ['20201106/Pano30-81.jpg', 2400, 500, '#ce9252'],
    ['20201106/Pano32.jpg', 2400, 500, '#cd1b36'],
    ['20201106/Pano4--2-.jpg', 2400, 500, '#ad7253'],
    ['20201106/Seeking-the-Lost-Bush-Chapel.jpg', 3648, 2736, '#c33547'],
    ['20201106/The-Lord-is-Coming-Panorama-edit-69.jpg', 5424, 1164, '#2099c6'],
  ];

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: 'Music Camp Photos',
      Title: 'Photos from Music Camp',
      Description: 'Music camp is a two-week event for young people passionate about music and growing in Christ.',
    });
  }

}
