import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ThemeColor, themeColorKeys, ThemeColors } from 'src/app/modules/colors';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() label!: string;
  @Input() loading = false;
  @Input() theme!: themeColorKeys;
  @Input() padding!: string;
  @Input() disabled?= false;
  @Input() border?= true;
  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();

  color: ThemeColor | null = null;

  constructor() { }

  ngOnInit(): void {
    if (this.theme) {
      this.color = ThemeColors[this.theme];
    }
  }

  onButtonClick(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.click.emit(event);
    }
  }

}
