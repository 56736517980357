import { DbService } from './db.service';
import { AuthService } from './auth.service';
import { StripeCustomer, StripePmtSource } from './donationPmt.service';
import { of } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

export function setTimeout$(cb: () => void, timer: number): void {
    of(true).pipe(debounceTime(timer), first()).subscribe(cb);
}

interface DataStoreInterface {
    singletons: {
        [propName: string]: any
    };
    sets: {
        [propName: string]: Set<any>;
    };
    db?: DbService;
    auth?: AuthService;
    lastPageUrl: string[];
    activeDomain?: string;
}

export type BlogCategoryNames = 'Madison' | 'Beehive' | 'The Maker Heals' | 'Eastward';

export interface BlogCategory {
    color: string;
    txt?: string;
    rgb: string;
    image: string;
}

type BlogCategories = {
    [key in BlogCategoryNames]: BlogCategory;
};

export const CategoryMeta: BlogCategories = {
    Madison: {
        color: '#f36e21',
        rgb: 'rgba(243, 110, 33, 0.1)',
        image: 'https://cdn2.tda.website/beehive/20200820/Madison-dk-sq.svg',
    },
    Beehive: {
        color: '#f3bb19',
        txt: '#e8ad00',
        rgb: 'rgb(243, 187, 25, 0.07)',
        image: 'https://cdn2.tda.website/beehive/20200608/beehive-logo-dark-sq.svg',
    },
    'The Maker Heals': {
        color: '#53875b',
        rgb: 'rgb(83, 135, 91, 0.07)',
        image: 'https://cdn2.tda.website/beehive/20200608/Hands.svg',
    },
    Eastward: {
        color: '#8dc63f',
        rgb: 'rgb(141, 198, 63, 0.1)',
        image: 'https://cdn2.tda.website/beehive/20200607/Eastward-sq.svg',
    },
};

export const DataStore: DataStoreInterface = {
    singletons: {},
    sets: {},
    lastPageUrl: []
};

export interface GenericRow {
    ID?: string;
    Owner?: string;
    OwnerObj?: PersonType;
    createdAt?: string;
    updatedAt?: string;
    __typename?: string;
    isCommon?: boolean;
    typeSuffix?: string;
}

export type AllRowTypes = GenericRow | ImageType | AddressType | ImageSettingFavType | VideoType | InvoiceType;

export interface UploaderProgress {
    percent: number;
    done: boolean;
}

export interface ImageType extends GenericRow {
    name: string;
    _Folder: string;
    path: string;
    width: number;
    height: number;
    host: string;
    uploader?: UploaderProgress;
    unsplash?: boolean;
    params?: string;
}

export interface ImageSettingFavType extends GenericRow {
    name: string;
    values: { [key: string]: string | number };
}

export interface FolderType extends GenericRow {
    parent: string;
    name: string;
}

export interface PersonType extends GenericRow {
    First: string;
    Last: string;
    Email: string;
    Password: string;
    TopAdmin: boolean;
    Mobile: string;
    Avatar?: string;
    StripeCustomer?: StripeCustomer;
    Addresses?: AddressType[];
    Donations?: DonationType[];
}

export interface AddressType extends GenericRow {
    name: string;
    mobile: string;
    instructions: string;
    address1: string;
    address2: string;
    suburb: string;
    postcode: string;
    state: string;
}


export interface RecipeType extends GenericRow {
    name: string;
    image?: ImageType;
    description: string;
    ingredients?: string;
    allowedTemp: string;
    type: 'main' | 'dessert';
}

export type BooleanString = 'true' | 'false';

export type FrequencyString = 'onetime' | 'monthly';

export interface DonationType extends GenericRow {
    DonationID: string;
    Amount: number;
    Recurring: BooleanString;
    First: string;
    Last: string;
    Email: string;
    Comments: string;
    CardBrand: string;
    CardExpMonth: number;
    CardExpYear: number;
    CardID: string;
    CardLast4: string;
    Person?: string;
    Frequency?: FrequencyString;
    Active?: BooleanString;
    Website: 'makerheals.org.au' | 'madison.org.au';
}

export interface StaffAppUserType extends GenericRow {
    Name: string;
    Email: string;
    Phone: string;
    Role: 'admin' | 'staff';
    Image?: string;
    Bio?: string;
}

export interface MadisonBlogPostType extends GenericRow {
    Title?: string;
    Category: BlogCategoryNames;
    Content?: string;
    ContentHTML?: string;
    Owner?: string;
    Images?: string[];
    Videos?: VideoType[];
    CreatedAt?: string;
    UpdatedAt?: string;
    CallToActionTitle?: string;
    CallToActionLink?: string;
    Likes?: number;
    Views?: number;
    Comments?: number;
    isAdvert?: boolean;
    advertIcon?: string;
    authorObj?: StaffAppUserType;
}

export type NotificationFrequency = 'Daily' | 'Weekly' | 'Fortnightly' | 'Monthly' | null;

export interface NotificationPrefType extends GenericRow {
    Frequency: NotificationFrequency;
    MuteComments: boolean;
    Unsubscribed: boolean;
}


type MuxStati = 'preparing' | 'ready' | 'errored';
type MuxPolicy = 'public' | 'signed';
type MuxAccess = 'temporary' | 'none';
type MP4Support = 'standard' | 'none';
type MuxResolution = 'Audio-only' | 'SD' | 'HD' | 'FHD' | 'UHD';

export interface VideoType extends GenericRow {
    Title: string;
    Identifier: string;
    path: string;
    MuxID?: string;
    MuxStatus?: MuxStati;
    PlaybackIDs?: MuxPlaybackID[];
    MuxTracks?: MuxTrack[];
    Duration?: number;
    MaxResolution?: MuxResolution;
    GifStartTime?: number;
    ThumbStartTime?: number;
}

export interface MuxTrack {
    duration: number;
    id: string;
    type: 'video' | 'audio' | 'text';
    max_frame_rate?: number;
    max_height?: number;
    max_width?: number;
    max_channel_layout?: 'mono' | 'stereo' | '5.1' | '7.1';
    max_channels?: number;
}

export interface MuxResType {
    created_at: string;
    id: string;
    master_access: MuxAccess;
    mp4_support: MP4Support;
    status: MuxStati;
    playback_ids: MuxPlaybackID[];
    max_stored_frame_rate?: number;
    max_stored_resolution?: MuxResolution;
    duration?: number;
    aspect_ratio?: string;
    tracks?: MuxTrack[];
}

export interface MuxPlaybackID {
    id: string;
    policy: MuxPolicy;
}

export interface BlogCommentType extends GenericRow {
    Key: string;
    Time: number;
    TXT: string;
    Likes?: number;
    ParentComment?: string;
    Deleted?: BooleanString;
    TabIndex?: number;
}

export interface BlogLikeType extends GenericRow {
    ID: string;
    Key: string;
    Owner: string;
    CreatedAt: string;
    UpdatedAt: string;
}

export interface BlogCommentsFor {
    Key: string;
    for: 'blog' | 'other';
}

export type InvoiceStatiType = 'Pending' | 'Complete' | 'Paid' | 'Delivered' | 'Cancelled' | 'Refunded';

export interface InvoiceType extends GenericRow {
    InvoiceID: string;
    Person: string;
    Address: AddressType;
    PaymentSource: StripePmtSource;
    Total: number;
    Tax: number;
    Paid: boolean;
    Status: InvoiceStatiType;
    Template?: 'Eastward' | 'Beehive' | 'Madison';
}

export interface InvoiceRowType extends GenericRow {
    Invoice: string;
    ProductID: string;
    Description: string;
    Quantity: number;
    Amount: number;
    Tax: number;
    Sort?: number;
}
