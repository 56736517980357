<div class="wrapper">
    <h2>Do You Like this?</h2>
    <p>🎉 Tell your friends! 👍 <br> Help us raise awareness. </p>
    <div class="share">
        <button matRipple (click)="shareFb()" style="background: #4267B2;">
        <app-icon>facebook</app-icon>
        <span>Facebook</span>
    </button>
        <button matRipple (click)="shareTwitter()" style="background: #1DA1F2;">
        <app-icon>twitter</app-icon>
        <span>Twitter</span>
    </button>
        <button matRipple (click)="sharePinterest()" style="background: #E60023;">
        <app-icon>pinterest</app-icon>
        <span>Pinterest</span>
    </button>
        <button matRipple (click)="shareEmail()" style="background: #444;">
        <app-icon>email</app-icon>
        <span>Email</span>
    </button>
    </div>

</div>