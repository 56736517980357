<div *ngIf="!author">
    <mat-spinner color="primary" diameter="30"></mat-spinner>
</div>
<!-- {{ author | json }} -->

<div class="author" *ngIf="author">
    <h1 mat-dialog-title>{{ author.Name }}</h1>
    <div mat-dialog-content>
        <img [src]="author.Image + imageSettings" alt="">
        <div class="bio">
            {{ author.Bio }}
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
    </div>
</div>