<div class="menu" [ngClass]="closed ? 'closed' : 'open'" (click)="toggleMenu($event)">
    <div class="wrap mat-elevation-z3">
        <img src="/assets/Blue-Stripe.svg">
    </div>
    <div class="content" [ngClass]="showContent">
        <h1>Menu</h1>
        <div class="inner">
            <nav>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/">Home</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/stream">Activity Stream</a>
                <a matRipple (click)="toggleMenu($event, true)" href="https://mus.camp/" target="_blank">Music Camp</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/Canvassing">Canvassing</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/Magabooks">Magabooks</a>
                <a matRipple (click)="toggleMenu($event, true)"  href="https://www.madison.org.au/vision" target="_blank">Madison Vision</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/About">About Us</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/Support">Support</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/Contact">Contact Us</a>
                <a matRipple href="tel:61420746255">
                    <app-icon>phone</app-icon>
                    <span>+61 0420 746 255</span>
                </a>
                <a matRipple href="mailto:pr@eastward.edu.au">
                    <app-icon>email</app-icon>
                    <span>pr@eastward.edu.au</span>
                </a>
                <a matRipple (click)="toggleMenu($event, true)" href="https://dsozo.camp/" target="_blank">D'Sozo</a>
                <a matRipple (click)="toggleMenu($event, true)" href="https://makerheals.org.au/">The Maker Heals Program</a>
                <a matRipple (click)="toggleMenu($event, true)" href="https://beehive.services/" target="_blank">Beehive Community Services</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/my-account">My Account</a>
                <a matRipple (click)="toggleMenu($event, true)" routerLink="/pp">Privacy Policy</a>
                <a matRipple (click)=" logout(); toggleMenu($event, true);" *ngIf="IsAuthenticated" style="cursor: pointer;">Logout</a>
            </nav>
            <div class="footer">
                &copy; Eastward Missions | 19 Westleys Rd, Faulkland NSW 2422 | pr@eastward.edu.au | +61 420 746 255
            </div>
        </div>
    </div>
    <div class="burger" matRipple (click)="toggleMenu($event, true)">
        <div class="inner">
            <div class="l line1"></div>
            <div class="l line2"></div>
            <div class="l line3"></div>
        </div>
    </div>
</div>