<div class="header">
    <a class="logo" (click)="closeD()">
        <img src="https://cdn2.tda.website/beehive/20200820/Madison-dk-sq.svg" alt="">
    </a>
    <div class="subscribe">

        <button mat-button (click)="notificationPrefs()">
            <mat-icon>notifications</mat-icon>
            Get Notifications
        </button>

        <div class="hand">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33.4px" height="25.3px" viewBox="0 0 33.4 25.3" style="enable-background:new 0 0 33.4 25.3;" xml:space="preserve">
            <style type="text/css">
            .st0{fill:#424243;}
            .st1{fill:#FFFFFF;}
            </style>
            <defs>
            </defs>
            <g>
            <g id="_x32_0_icons_1_">
            <path class="st0" d="M26.6,6.8l-2.7-2.3c-1-0.9-2.6-0.7-3.4,0.3c-0.3,0.3-0.4,0.7-0.5,1.1c-1-0.6-2.3-0.4-3.1,0.5
                c-0.3,0.4-0.5,0.9-0.6,1.3c-0.2-0.1-0.5-0.1-0.8-0.1c-0.6,0.1-1.2,0.4-1.6,0.9c-0.3,0.3-0.4,0.7-0.5,1l-2.4-2
                C10.4,7.1,9.8,6.9,9.2,7C8.5,7,7.9,7.3,7.5,7.8c-0.9,1-0.7,2.6,0.3,3.4l8.2,6.9l-1.8-0.4c-1.3-0.3-2.6,0.6-2.9,1.9
                c-0.3,1.3,0.6,2.6,1.9,2.9l13.3,2.8c0.1,0,0.2,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2l6.1-7.2c0.2-0.2,0.2-0.4,0.1-0.7
                C33.2,16.9,30.1,9.8,26.6,6.8z M26.4,23.9l-12.9-2.7c-0.6-0.1-1-0.7-0.9-1.3c0.1-0.3,0.2-0.5,0.5-0.7C13.4,19,13.7,19,14,19
                l4.3,0.9c0.3,0.1,0.6-0.1,0.7-0.4c0.1-0.3,0.1-0.6-0.2-0.8L8.7,10.2C8.2,9.8,8.1,9.1,8.5,8.7C8.7,8.4,9,8.3,9.3,8.3
                c0.3,0,0.6,0.1,0.8,0.3l4.1,3.4l0,0l3.4,2.9c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
                c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.2-0.7-0.1-0.9
                L15,11c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.3,0.1-0.6,0.3-0.8C15.1,9.1,15.3,9,15.6,9c0.3,0,0.6,0.1,0.8,0.3l3.2,2.7l0.9,0.7
                c0.2,0.2,0.4,0.2,0.6,0.1c0.1,0,0.2-0.1,0.3-0.2c0.2-0.3,0.2-0.7-0.1-0.9l-0.7-0.6l0,0l-0.2-0.2L18,8.8c-0.5-0.4-0.5-1.1-0.1-1.6
                c0.4-0.5,1.1-0.5,1.6-0.1l1.4,1.1c0,0,0,0,0,0l2.7,2.3c0.3,0.2,0.7,0.2,0.9-0.1c0.2-0.3,0.2-0.7-0.1-0.9l-0.7-0.6c0,0,0,0,0,0
                l-2-1.7c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.3,0.1-0.6,0.3-0.8c0.4-0.5,1.1-0.5,1.6-0.1l2.7,2.3c2.9,2.4,5.5,8.1,6.2,9.5L26.4,23.9z"/>
            <path class="st0" d="M5.2,13.3l-2.1,2.4C3,16,3,16.4,3.3,16.7c0.2,0.2,0.4,0.2,0.6,0.1c0.1,0,0.2-0.1,0.3-0.2l2.1-2.4
                c0.2-0.3,0.2-0.7-0.1-0.9C5.9,13,5.5,13,5.2,13.3z"/>
            <path class="st0" d="M12.6,5.5c0.2,0.2,0.4,0.2,0.6,0.1c0.1,0,0.2-0.1,0.3-0.2L15.6,3c0.2-0.3,0.2-0.7-0.1-0.9
                c-0.3-0.2-0.7-0.2-0.9,0.1l-2.1,2.4C12.3,4.9,12.3,5.3,12.6,5.5z"/>
            <path class="st0" d="M5.5,5.2L3,3.2C2.7,3,2.3,3,2.1,3.3S1.9,3.9,2.2,4.2l2.4,2.1C4.8,6.4,5,6.4,5.3,6.3c0.1,0,0.2-0.1,0.3-0.2
                C5.8,5.9,5.7,5.5,5.5,5.2z"/>
            <path class="st0" d="M10.3,4.4c0.1,0,0.2,0,0.3,0C10.8,4.3,11,4.1,11,3.9l0.6-3.1C11.6,0.4,11.4,0.1,11,0
                c-0.4-0.1-0.7,0.2-0.8,0.5L9.7,3.7C9.7,4,9.9,4.4,10.3,4.4z"/>
            <path class="st0" d="M0.5,8.5L3.7,9c0.1,0,0.2,0,0.3,0c0.2-0.1,0.4-0.3,0.4-0.5c0.1-0.4-0.2-0.7-0.5-0.8L0.8,7.2
                C0.4,7.1,0.1,7.4,0,7.7C-0.1,8.1,0.2,8.4,0.5,8.5z"/>
            <path class="st0" d="M7.7,4.7C8,4.5,8.2,4.2,8.1,3.8L7,0.8C6.8,0.5,6.5,0.3,6.1,0.4C5.8,0.6,5.6,0.9,5.7,1.3l1.1,3
                C7,4.6,7.3,4.8,7.7,4.7z"/>
            <path class="st0" d="M4.7,11.1c-0.1-0.3-0.5-0.5-0.8-0.4l-3,1.1c-0.3,0.1-0.5,0.5-0.4,0.8C0.6,13,0.9,13.1,1.3,13l3-1.1
        C4.6,11.8,4.8,11.4,4.7,11.1z"/>
        </g>
        <path class="st1" d="M26.4,23.9l-12.9-2.7c-0.6-0.1-1-0.7-0.9-1.3c0.1-0.3,0.2-0.5,0.5-0.7C13.4,19,13.7,19,14,19l4.3,0.9
        c0.3,0.1,0.6-0.1,0.7-0.4c0.1-0.3,0.1-0.6-0.2-0.8L8.7,10.2C8.2,9.8,8.1,9.1,8.5,8.7C8.7,8.4,9,8.3,9.3,8.3c0.3,0,0.6,0.1,0.8,0.3
        l4.1,3.4l0,0l3.4,2.9c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.2-0.7-0.1-0.9L15,11
        c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.3,0.1-0.6,0.3-0.8C15.1,9.1,15.3,9,15.6,9c0.3,0,0.6,0.1,0.8,0.3l3.2,2.7l0.9,0.7
        c0.2,0.2,0.4,0.2,0.6,0.1c0.1,0,0.2-0.1,0.3-0.2c0.2-0.3,0.2-0.7-0.1-0.9l-0.7-0.6l0,0l-0.2-0.2L18,8.8c-0.5-0.4-0.5-1.1-0.1-1.6
        c0.4-0.5,1.1-0.5,1.6-0.1l1.4,1.1c0,0,0,0,0,0l2.7,2.3c0.3,0.2,0.7,0.2,0.9-0.1c0.2-0.3,0.2-0.7-0.1-0.9l-0.7-0.6c0,0,0,0,0,0
        l-2-1.7c-0.2-0.2-0.4-0.5-0.4-0.8c0-0.3,0.1-0.6,0.3-0.8c0.4-0.5,1.1-0.5,1.6-0.1l2.7,2.3c2.9,2.4,5.5,8.1,6.2,9.5L26.4,23.9z"/>
        </g>
        </svg>
        </div>

    </div>
    <div class="filler"></div>
    <div class="close">
        <button matRipple aria-label="Close news post" (click)="closeD()">
        <mat-icon>close</mat-icon>
    </button>
    </div>
</div>

<div class="post">
    <div class="images" [ngClass]="'num-' + post.Images?.length" *ngIf="post.Images && !post.Videos?.length">
        <div class="img img1" matRipple [routerLink]="basePath+post.ID" [queryParams]="{img: post.Images[0]}" *ngIf="post.Images[0]" [ngStyle]="{'background-image':'url(\''+post.Images[0]+imgSettings+'\')'}"></div>
        <div class="img img2" matRipple [routerLink]="basePath+post.ID" [queryParams]="{img: post.Images[1]}" *ngIf="post.Images[1]" [ngStyle]="{'background-image':'url(\''+post.Images[1]+imgSettings+'\')'}"></div>
        <div class="img img3" matRipple [routerLink]="basePath+post.ID" [queryParams]="{img: post.Images[2]}" *ngIf="post.Images[2]" [ngStyle]="{'background-image':'url(\''+post.Images[2]+imgSettings+'\')'}"></div>
        <div class="img img4" matRipple [routerLink]="basePath+post.ID" [queryParams]="{img: post.Images[3]}" *ngIf="post.Images[3]" [ngStyle]="{'background-image':'url(\''+post.Images[3]+imgSettings+'\')'}"></div>
    </div>
    <div class="images" style="display: flex;" *ngIf="post.Videos?.length">
        <video #video controls autoplay muted playsinline loop style="background: #000; width: 100%;"></video>
    </div>
    <div class="body">
        <h1>{{ post.Title }}</h1>
        <app-news-social-summary [post]=post></app-news-social-summary>
        <div class="txt" *ngIf="!post.ContentHTML">
            {{ post.Content?.trim() }}
        </div>
        <div class="txt" #html *ngIf="post.ContentHTML"></div>
        <div class="actions" *ngIf="post.CallToActionLink">
            <button mat-stroked-button (click)="cta(post)">{{ post.CallToActionTitle || post.CallToActionLink }}</button>
        </div>
        <app-comments id="comments" [for]="{Key: (post.ID || ''), for: 'blog'}"></app-comments>
    </div>
</div>

<router-outlet name="imageRouter"></router-outlet>