import { DataStore, AllRowTypes, GenericRow, MadisonBlogPostType, PersonType, setTimeout$ } from './DataStore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { schema, schemaTypes, connectionReturnType, OwnerConnection } from '../data/schema';

export interface ListOptions {
  fromCache?: boolean;
  depth?: number;
  insight?: boolean;
  page?: number;
  maxItems?: number;
  sortColumn?: string;
  direction?: 'ASC' | 'DESC';
  columns?: string[];
}

interface StackItem {
  Type: string;
  Value: any;
}

export interface CallResponse {
  Success: boolean;
  internetConnection?: 0 | 1;
  Message?: string;
  Notify?: boolean;
  Data?: any;
  Connections?: any;
}

export interface MultiQueryInput {
  Column: string;
  Equals: string;
}

type ConnectionOutput = { [key: string]: { [key: string]: string }[] };
type BatchInput = {
  type: string, data: AllRowTypes[]
}[];

interface GetResponseType {
  row: any;
}

export const TdaWeakMap = new WeakMap();

interface SessionObj {
  Expires: number;
  Expired: boolean;
  Person: string;
  Email: string;
  SessionID: string;
  TopAdmin: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DbService {

  static endpoint = 'https://db.beehive.services/';
  static websocketEndpoint = 'wss://db.beehive.services/ws';
  // static endpoint = 'http://localhost:8094/';

  listOptionDefaults: ListOptions = {
    fromCache: false,
    depth: 1,
    insight: false,
    page: 0,
    maxItems: 10,
    sortColumn: 'createdAt',
    direction: 'DESC',
  };

  constructor(
    public dialog: MatDialog,
    public utils: UtilsService,
    public http: HttpClient,
    public snackBar: MatSnackBar,
  ) {
    DataStore.db = this;
  }

  AuthSession: SessionObj | null = null;

  curBlogPost: MadisonBlogPostType | null = null;
  likedPosts: { [id: string]: boolean } = {};
  likedComments: { [id: string]: boolean } = {};
  authors: { [id: string]: PersonType } = {};

  allPostsCache: MadisonBlogPostType[] = [];
  allPostsScrollPos = 0;

  onGoingRequests: Set<any> = new Set();
  RequestCallbacks: Set<any> = new Set();

  CallQueue: { data: any, quiet: boolean, resolve: (value?: CallResponse | PromiseLike<CallResponse>) => void }[] = [];
  IsCallingQueue = false;

  ListLastTime: {
    [type: string]: number;
  } = {};

  originalSchemaKeys: { [typeName: string]: string[] } = {};

  addSchemaKey(type: string, key: string): void {
    if (this.originalSchemaKeys[type]) {
      this.originalSchemaKeys[type].push(key);
      this.originalSchemaKeys[type] = Array.from(new Set(this.originalSchemaKeys[type]));
    }
  }

  getIndexes(type: string): {
    [key: string]: string;
  }[] {

    const indexes: { [key: string]: string }[] = [];

    const fields = [...(schema[type] || []), OwnerConnection];

    fields.forEach(field => {
      if (field.types.indexOf(schemaTypes.queryIndex) > -1) {
        indexes.push({
          Column: field.connectedListFieldValueEquals || field.name,
          IndexType: 'query',
        });
      }

      if (field.types.indexOf(schemaTypes.sortIndex) > -1) {
        indexes.push({
          Column: field.name,
          IndexType: 'sort',
        });
      }
    });


    return indexes;
  }
  // Map<String, List<Map<String, String>>>
  getConnections(type: string, level?: number, existingOutput?: ConnectionOutput): ConnectionOutput {

    if (!level) {
      level = 1;
    }

    const output: ConnectionOutput = existingOutput != null ? existingOutput : {};

    output[type] = [];

    const connectionTypes: string[] = [];

    const fields = [...(schema[type] || [])];

    if (type !== 'Person') {
      fields.push(OwnerConnection);
    }

    fields.forEach(field => {
      if (field.types.indexOf(schemaTypes.connection) > -1) {
        const fieldSplit: string[] = field.connectedTo.split('.');

        if (fieldSplit[0] !== type) {
          connectionTypes.push(fieldSplit[0]);
        }

        output[type].push({
          Table: fieldSplit[0],
          Field: fieldSplit[1],
          Equals: field.connectedListFieldValueEquals == null
            ? field.name
            : field.connectedListFieldValueEquals,
          Action:
            field.connectionReturns === connectionReturnType.singleObject
              ? 'get'
              : 'list',
          Saveto: field.name
        });
      }
    });

    if (level > 1) {
      level--;
      connectionTypes.forEach(theType => {
        this.getConnections(theType, level, output);
      });
    }

    return output;
  }

  saveConnections(
    typeName: string,
    responseConnections: { [key: string]: any },
    row: AllRowTypes,
    depth: number = 0
  ): AllRowTypes {

    if (typeof row.ID === 'undefined') {
      return row;
    }

    this.originalSchemaKeys[typeName] = Object.keys(row);

    if (responseConnections != null && responseConnections[row.ID] != null) {
      const connections: { [key: string]: any } = responseConnections[row.ID];

      Object.keys(connections).forEach(key => {
        let value = connections[key];
        value = JSON.parse(value);
        if (this.utils.isArray(value)) {
          value = value as [];
          Object.keys(value).forEach(index => {
            let item = value[index] as AllRowTypes;
            if (depth > 1) {
              item = this.saveConnections(typeName, responseConnections, item, depth - 1);
            }
            value[index] = this.PutDataStore(typeName, item);
          });
          row[key as keyof AllRowTypes] = value;
        } else {
          const field = this.utils.find([...(schema[typeName] || []), OwnerConnection], 'name', key);
          if (field != null) {
            if (value.__typename == null) {
              value.__typename = field.connectedTo.split('.')[0];
            }
          }
          if (depth > 1) {
            value = this.saveConnections(value.__typename, responseConnections, value, depth - 1);
          }
          row[key as keyof AllRowTypes] = this.PutDataStore(value.__typename, value) as any;
        }
      });
    }

    return row;
  }

  addOngoingRequest(request: Promise<any>): void {

    this.onGoingRequests.add(request);

    this.sendRequestChange();

    request.then(() => {
      this.onGoingRequests.delete(request);
      this.sendRequestChange();
    });

  }

  sendRequestChange(): void {

    for (const callback of this.RequestCallbacks) {
      callback(this.onGoingRequests.size);
    }

  }

  onRequestChange(fn: (num: number) => void): void {
    this.RequestCallbacks.add(fn);
  }

  getReq(url: string): Promise<any> {

    const promise = new Promise<any>((resolve) => {

      this.http.get(url).subscribe(res => {
        resolve(res);
      }, (error: any) => {
        this.snackBar.open('HTTP Error', 'Dismiss', {
          duration: 6000
        });
        resolve({
          Success: false,
          internetConnection: 0,
          Message: 'Http Error'
        });
      });

    });

    this.addOngoingRequest(promise);

    return promise;

  }

  async processCallQueue(): Promise<void> {

    if (!this.CallQueue.length) {
      return;
    }

    this.IsCallingQueue = false;

    const queue = [...this.CallQueue];
    this.CallQueue.length = 0;

    const calls = [];

    const data = {
      CMD: 'multiPlex',
      Params: '',
    };

    for (const call of queue) {
      calls.push(call.data);
    }

    data.Params = JSON.stringify(calls);

    const res = await this.http.post(DbService.endpoint, data).toPromise() as CallResponse;

    if (res.Success) {
      const responses: CallResponse[] = JSON.parse(res.Data);
      for (const response of responses) {

        const index = responses.indexOf(response);

        const call = queue[index];

        if (response.Data) {
          try {
            response.Data = `${response.Data}`.replace(/tuilder\.imgix\.net/g, 'cdn2.tda.website');
            response.Data = JSON.parse(response.Data) || response.Data;
          } catch (e) {
            console.warn(`Unable to parse response JSON.`);
          }
        }
        if (response.Connections) {
          response.Connections = JSON.parse(response.Connections);
        }

        if (!response.Success) {
          if (!call.quiet) {
            this.snackBar.open(response.Message || 'Unspecified Error.', 'Dismiss', {
              duration: 6000
            });
          }
          console.error('Error in db call', response.Message, data);
        }
        if (response.Notify && typeof response.Message !== 'undefined') {
          this.snackBar.open(response.Message, 'Dismiss', {
            duration: 6000
          });
        }
        response.internetConnection = 1;
        call.resolve(response);
      }
    }

  }

  call(data: any, quiet: boolean): Promise<CallResponse> {

    const promise = new Promise<CallResponse>((resolve) => {

      this.CallQueue.push({ data, quiet, resolve });

      if (!this.IsCallingQueue) {
        setTimeout(() => {
          this.processCallQueue();
        });
      }

    });

    this.addOngoingRequest(promise);

    return promise;

  }

  getSessionValue(): string {

    const value = localStorage.getItem('session');

    if (value !== null) {
      return value;
    }

    return '';

  }

  q(cmd: string, params: any, quiet: boolean = false): Promise<CallResponse> {

    const SessionValue = this.getSessionValue();

    if (SessionValue) {
      params.session = SessionValue;
    }

    return this.call({ cmd, params }, quiet);

  }

  Singleton(row: AllRowTypes): any {

    // Keep a single record of all objects, by id
    // ID should be universally unique

    if (typeof row.ID === 'undefined') {
      return row;
    }

    // If key with matching ID found in existing singletons
    if (DataStore.singletons[row.ID]) {

      // Extend row properties onto existing singleton, and return singleton

      // loop through row keys
      Object.keys(row).forEach(key => {

        if (typeof row.ID !== 'undefined') {

          // Value
          const val = row[key as keyof AllRowTypes];

          if (!this.utils.isObject(DataStore.singletons[row.ID][key]) || this.utils.isObject(val)) {
            // Extend value to singleton
            DataStore.singletons[row.ID][key] = val;
          }

        }


      });

      // Return singleton obj
      return DataStore.singletons[row.ID];

    } else {

      // Add to row to singleton
      DataStore.singletons[row.ID] = row;
      return row;

    }
  }

  InitDataStore(type: string): void {
    // Initialise type in datastore, if it doesn't exist create new set
    // Sets are faster than arrays and garuntee uniqueness
    DataStore.sets[type] = DataStore.sets[type] || new Set();
  }

  ClearDataStore(): void {
    DataStore.sets = {};
    DataStore.singletons = {};
  }

  RemoveFromDataStore(item: any): void {

    if (DataStore.singletons[item.ID]) {
      delete DataStore.singletons[item.ID];
    }

    const Set = DataStore.sets[item.__typename];

    if (Set && Set.has(item)) {
      Set.delete(item);
    }

  }

  PutDataStore(type: string, row: AllRowTypes): AllRowTypes {

    // Init store for type
    this.InitDataStore(type);

    // Ensure row is singleton
    row = this.Singleton(row);

    // Add to set
    DataStore.sets[type].add(row);

    return row;

  }

  async batchPut(input: BatchInput): Promise<CallResponse> {

    const data: {
      type: string;
      rows: string;
      _IDX: string;
    }[] = [];

    input.forEach(row => {

      const rows: string[] = [];
      const type = row.type;

      row.data.forEach(itm => {
        this.addRowDefaults(itm, type);
        itm = this.removeSchemaKeys(itm);
        rows.push(JSON.stringify(itm));
      });

      data.push({
        type,
        rows: JSON.stringify(rows),
        _IDX: JSON.stringify(this.getIndexes(type))
      });

    });

    // Send to database
    return await this.q('crud:batchPut', { batchData: JSON.stringify(data), type: 'batch' });

  }

  async batchGet(type: string, IDs: string[], depth: number = 1): Promise<AllRowTypes[]> {

    // Send to database
    const res = await this.q('crud:batchGet', {
      IDs: JSON.stringify(IDs),
      type,
      depth: depth.toString(),
      _CNX: depth ? JSON.stringify(this.getConnections(type, depth)) : null,
    });

    const rows: AllRowTypes[] = res.Data;

    rows.forEach(row => {

      this.saveConnections(type, res.Connections, row, depth);
      row = this.PutDataStore(type, row);

    });

    return rows;

  }

  addRowDefaults(row: GenericRow, type: string): GenericRow {
    row.__typename = type;

    // Add id to row if it doesn't exist
    if (!row.ID) {
      row.ID = this.utils.uid();
    }

    if (this.AuthSession !== null) {
      // Add owner of the field
      if (!row.Owner || !this.AuthSession.TopAdmin) {
        row.Owner = this.AuthSession.Person;
      }
    }

    // Add typename
    row.__typename = type;

    // ISO string of date
    const ISOString = (new Date()).toISOString();

    // Update time
    row.updatedAt = ISOString;

    // If no createdAt property exists yet, safe to assume it is created now
    if (!row.createdAt) {
      row.createdAt = ISOString;
    }

    return row;
  }

  convertOwnerFields(row: any): any {

    if (row.Owner && this.utils.isObject(row.Owner)) {
      const Owner = row.Owner as any;
      row.Owner = Owner.ID;
    }

    Object.keys(row).forEach(key => {
      if (this.utils.isObject(row[key])) {
        row[key] = this.removeSchemaKeys(Object.assign({}, row[key]));
      }
    });

    return row;
  }

  stringify(input: any): string {
    if (this.utils.isArray(input)) {
      input.forEach((itm: any) => {
        if (this.utils.isObject(itm)) {
          this.removeCircular(itm);
        }
      });
    }
    return JSON.stringify(input);
  }

  removeCircular(input: any): void {
    if (this.utils.isObject(input) || this.utils.isArray(input)) {
      if (this.utils.isObject(input) && input.__typename) {
        this.removeSchemaKeys(input);
      } else {
        Object.keys(input).forEach(k => {
          const v = input[k];
          if (this.utils.isObject(v) || this.utils.isArray(v)) {
            this.removeCircular(v);
          }
        });
      }
    }
  }

  removeSchemaKeys(row: any): any {

    row = Object.assign({}, row);

    if (row.__typename && this.originalSchemaKeys[row.__typename]) {
      const allowedKeys = this.originalSchemaKeys[row.__typename];
      Object.keys(row).forEach(key => {
        if (allowedKeys.indexOf(key) < 0 || key === 'OwnerObj') {
          delete row[key];
        }
      });
    }

    Object.keys(row).forEach(key => {
      if (this.utils.isObject(row[key]) && row[key].__typename) {
        const rowObj = row[key] as GenericRow;
        row[key] = this.removeSchemaKeys(rowObj);
      }
    });

    return row;

  }

  async put(type: any, row?: AllRowTypes): Promise<GenericRow | undefined> {

    if (typeof row === 'undefined' && this.utils.isObject(type)) {
      row = type as AllRowTypes;
      type = row.__typename;
    } else if (typeof row === 'undefined') {
      console.warn('Empty put request');
      return;
    }

    const rowObj = row as AllRowTypes;

    this.addRowDefaults(row, type);

    // Put to local datastore
    row = this.PutDataStore(type, row);

    row = this.removeSchemaKeys(row);

    // Send to database
    await this.q('crud:put', {
      type,
      row: JSON.stringify(row),
      ID: rowObj.ID || '',
      _IDX: JSON.stringify(this.getIndexes(type)),
    });

    return row;

  }

  async listAll(type: string, ops?: ListOptions): Promise<Set<any>> {

    ops = ops || {};

    ops.insight = true;

    return await this.list(type, ops);

  }

  async listPage(
    type: string,
    page: number = 1,
    maxItems: number = 10,
    sortColumn: string = 'createdAt',
    direction: 'ASC' | 'DESC' = 'DESC',
    fromCache: boolean = false,
    depth: number = 0,
  ): Promise<Set<any>> {

    return await this.list(type, {
      fromCache,
      depth,
      insight: true,
      page,
      maxItems,
      sortColumn,
      direction
    });

  }

  addListDefaultOptions(ops: any): ListOptions {

    if (ops) {

      const keys = Object.keys(this.listOptionDefaults);
      for (const k of keys) {
        if (typeof ops[k as keyof ListOptions] === 'undefined') {
          ops[k as keyof ListOptions] = this.listOptionDefaults[k as keyof ListOptions];
        }
      }

      return ops;

    }

    return this.listOptionDefaults;

  }

  async list(
    type: string,
    optionsFrmUsr?: ListOptions,
  ): Promise<Set<any>> {

    const options = this.addListDefaultOptions(optionsFrmUsr);

    this.InitDataStore(type);

    return new Promise<Set<any>>(resolve => {

      if (options.fromCache && DataStore.sets[type].size) {

        resolve(DataStore.sets[type]);

      } else {

        const Now = (new Date()).getTime() / 1000; // seconds

        const page = typeof options.page === 'undefined' ? 0 : options.page;

        const listPage = page > 0 ? true : false;

        // Ten minute TTL
        // Auto list from cache if last list was in the last 2 minutes
        // if (!listPage && this.ListLastTime[type] && Now - this.ListLastTime[type] < 120 && DataStore.sets[type].size) {
        //   resolve(DataStore.sets[type]);
        // } else {

        this.ListLastTime[type] = Now;

        const cmd = listPage ? 'listPage' : 'list';

        if (options.columns) {
          options.columns.push('ID');
        }

        const maxItems = typeof options.maxItems === 'undefined' ? 10 : options.maxItems;
        const depth = typeof options.depth === 'undefined' ? 0 : options.depth;

        this.q(`crud:${cmd}`, {
          type,
          page: page.toString(),
          maxItems: maxItems.toString(),
          sortColumn: options.sortColumn,
          direction: options.direction,
          depth: depth.toString(),
          insight: options.insight ? 'true' : 'false',
          columns: options.columns ? JSON.stringify(options.columns) : '',
          _CNX: depth > 0 ? JSON.stringify(this.getConnections(type, depth)) : '',
        }).then(response => {

          if (typeof response.Data === 'object' && response.Data.length && typeof response.Data[0] === 'object') {
            const rows: any[] = response.Data.reverse();

            for (let row of rows) {
              row = this.saveConnections(type, response.Connections, row, options.depth);
              this.PutDataStore(type, row);
            }

            if (!listPage) {
              resolve(DataStore.sets[type]);
            } else {
              const set = new Set();
              for (const row of rows) {
                set.add(row);
              }
              resolve(set);
            }
          } else {
            console.log(response);
            resolve(new Set());
          }

        });

        // }

      }

    });


  }

  get(type: string, ID: string, fromCache?: boolean, depth: number = 1): Promise<any> {

    return new Promise<any>(resolve => {

      let returned = false;

      // Return from cache if exists there
      if (fromCache) {

        // Init datastore in case it isn't already
        this.InitDataStore(type);

        // Loop over items
        for (const item of DataStore.sets[type]) {

          // If matching ID
          if (item.ID === ID) {

            // Resolve promise with item
            resolve(item);
            returned = true;
            break;
          }
        }
      }

      if (returned) {
        return;
      }

      this.q('crud:get', {
        type,
        ID,
        depth: depth.toString(),
        _CNX: depth ? JSON.stringify(this.getConnections(type, depth)) : null,
      }, true).then(response => {

        let row: AllRowTypes = response.Data;

        this.saveConnections(type, response.Connections, row, depth);

        row = this.PutDataStore(type, row);

        resolve(row);

      });

    });

  }

  async delete(item: AllRowTypes): Promise<boolean> {

    return new Promise<boolean>(resolve => {

      if (item.ID && item.__typename) {
        this.q('crud:delete', {
          type: item.__typename,
          ID: item.ID,
          _IDX: JSON.stringify(this.getIndexes(item.__typename)),
        }).then(response => {

          if (response.Success) {
            this.RemoveFromDataStore(item);
            resolve(true);
          } else {
            resolve(false);
            console.error(response);
          }

        });
      } else {
        console.error('Unable to delete item.', item);
        resolve(false);
      }

    });

  }

  async multiQuery<T>(
    type: string,
    query: MultiQueryInput[],
    depth: number = 1,
    saveObj: boolean = true
  ): Promise<T[]> {

    const response = await this.q('crud:multiQuery', {
      type,
      query: JSON.stringify(query),
      depth: depth.toString(),
      _CNX: JSON.stringify(this.getConnections(type, depth)),
    });

    const rows: any[] = response.Data;

    const Output: T[] = [];

    for (const row of rows) {
      if (saveObj) {
        if (response.Connections != null &&
          response.Connections[row.ID] != null) {
          const connections = response.Connections[row.ID] as { [key: string]: any };

          Object.keys(connections).forEach(key => {
            const CnxValue = JSON.parse(connections[key]);
            if (this.utils.isArray(CnxValue)) {
              const InnerOutput: any[] = [];
              CnxValue.forEach((item: AllRowTypes) => {
                if (typeof item.__typename !== 'undefined') {
                  InnerOutput.push(this.PutDataStore(item.__typename, item));
                }
              });
              row[key] = InnerOutput;
            } else {
              row[key] = this.PutDataStore(CnxValue.__typename, CnxValue);
            }
          });
        }
        Output.push(this.PutDataStore(type, row) as T);
      } else {
        Output.push(row);
      }
    }

    return Output;
  }


  query(
    type: string,
    column: string,
    value: string,
    fromCache?: boolean,
    depth: number = 2,
    saveObj: boolean = true,
  ): Promise<Set<any>> {

    return new Promise<Set<any>>(resolve => {

      this.q('crud:query', {
        type,
        column,
        value,
        depth: depth.toString(),
        _CNX: JSON.stringify(this.getConnections(type, depth)),
      }).then(response => {

        const rows: any[] = response.Data;

        const Output = new Set();

        for (const row of rows) {
          if (saveObj) {
            if (response.Connections != null &&
              response.Connections[row.ID] != null) {
              const connections = response.Connections[row.ID] as { [key: string]: any };

              Object.keys(connections).forEach(key => {
                const CnxValue = JSON.parse(connections[key]);
                if (this.utils.isArray(CnxValue)) {
                  const InnerOutput: any[] = [];
                  CnxValue.forEach((item: AllRowTypes) => {
                    if (typeof item.__typename !== 'undefined') {
                      InnerOutput.push(this.PutDataStore(item.__typename, item));
                    }
                  });
                  row[key] = InnerOutput;
                } else {
                  row[key] = this.PutDataStore(CnxValue.__typename, CnxValue);
                }
              });
            }
            Output.add(this.PutDataStore(type, row));
          } else {
            Output.add(row);
          }
        }

        resolve(Output);

      });

    });

  }

  isSchemaTypeLike(key: string): boolean {
    // SchemaType example: _Page
    return key && typeof key === 'string' && key.match('^\_[a-zA-Z]+$') ? true : false;
  }

  Subscribe<T>(
    types: string[] | string,
    fn: (message: T) => void,
    Owner: string = this.AuthSession?.Person || '',
  ): WebSocket {
    if (typeof types === 'string') {
      types = [types];
    }
    const socket = new WebSocket(
      `${DbService.websocketEndpoint}?session=${this.AuthSession?.SessionID || ''}&` +
      `owner=${Owner}&` +
      `types=${types.join(',')}`
    );

    socket.onopen = () => {
      // console.log('Successfully Connected');
    };

    socket.onmessage = msg => {
      let row = JSON.parse(JSON.parse(msg.data).row) as AllRowTypes;
      if (row.__typename) {
        row = this.PutDataStore(row.__typename, row);
      }
      fn(row as T);
    };

    socket.onclose = event => {
      // console.log('Socket Closed Connection: ', event);
    };

    socket.onerror = error => {
      // console.log('Socket Error: ', error);
    };

    return socket;
  }

}
