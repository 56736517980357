import { Component, OnInit, Inject } from '@angular/core';
import { WINDOW } from 'src/app/services/window.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  constructor(
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit(): void {
  }

  shareFb(): void {
    this.open('https://www.facebook.com/sharer/sharer.php?u=https://madison.org.au/');
  }

  shareTwitter(): void {
    this.open('https://twitter.com/home?status=https://madison.org.au/');
  }

  sharePinterest(): void {
    this.open('https://pinterest.com/pin/create/button/?url=https://madison.org.au/&media=&description=');
  }

  shareEmail(): void {
    this.open('mailto:%20?&subject=Madison+Missions&body=https://madison.org.au/');
  }

  open(link: string): void {
    this.window.open(link);
  }

}
