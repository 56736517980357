import { Component, ChangeDetectorRef, OnInit, AfterViewInit, Inject } from '@angular/core';
import { DbService } from './services/db.service';
import { AuthService } from './services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { DataStore } from './services/DataStore';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from './services/window.service';
import { RouteEventsService } from './services/router-events.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'Madison Missions Australia';

  onGoingRequests = 0;
  isCheckingAuth = false;
  IsAuthenticated = false;

  constructor(
    private db: DbService,
    private authService: AuthService,
    private router: Router,
    private routerEvents: RouteEventsService,
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
  ) {

    DataStore.auth = authService;

  }

  ngAfterViewInit(): void {

    this.db.onRequestChange((num) => {
      this.onGoingRequests = num;
      this.changeDetector.detectChanges();
    });

    this.document.getElementById('InitLoader')?.remove();

  }

  ngOnInit(): void {

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && typeof window !== 'undefined' && !val.url.includes('#')) {
        // window.scrollTo(0, 0); 
      }
    });

    this.authService.onStateChange(AuthState => {

      this.IsAuthenticated = AuthState;

    });

    if (this.authService.hasSessionValue() && !this.authService.authChecked) {

      this.isCheckingAuth = true;

      this.authService.checkSession().then((response: any) => {

        this.isCheckingAuth = false;

      });

    }
  }

}
