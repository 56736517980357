import { CallResponse } from './../../../services/db.service';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';

export interface LoginData {
  email: string;
  password: string;
  remember: string;
}

export interface LoginResponse extends CallResponse {
  verifyError: 1 | 0;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  activity = false;
  loginForm: FormGroup | null = null;
  hidePwd = true;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilsService,
  ) { }

  onAuthChange = (loggedIn: boolean) => {
    if (loggedIn) {
      const returnRoute: string = this.route.snapshot.queryParamMap.get('return') || '';

      if (returnRoute) {
        this.router.navigateByUrl(returnRoute);
      } else {
        this.router.navigateByUrl('account');
      }
    }
  }

  ngOnDestroy(): void {
    this.auth.removeStateChangeCallback(this.onAuthChange);
  }

  ngOnInit(): void {

    this.utils.updateMeta({
      BrowserTitle: `Login - ${this.utils.orgTitle}`,
      Title: `Login - ${this.utils.orgTitle}`,
      Description: `Login - ${this.utils.orgTitle}`,
    });

    this.auth.onStateChange(this.onAuthChange);

    this.loginForm = new FormGroup({

      email: new FormControl('', [Validators.required, Validators.email]),

      password: new FormControl('', [Validators.required, Validators.pattern(
        /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/
      )]),

      remember: new FormControl('*')

    });

  }

  public hasError = (controlName: string, errorName: string) => {
    if (this.loginForm !== null) {
      return this.loginForm.controls[controlName].hasError(errorName);
    }
    return false;
  }

  success(): void {

  }

  async login(data: LoginData): Promise<void> {

    this.activity = true;

    const LoginSuccess = await this.auth.login(data);

    this.activity = false;

    if (LoginSuccess) {

      this.success();

    }

  }

}
