import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import * as typeformEmbed from '@typeform/embed';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterViewInit {

  backLink = '';
  isBrowser: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
    private activatedRoute: ActivatedRoute,
    private utils: UtilsService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.back) {
      this.backLink = this.activatedRoute.snapshot.queryParams.back;
    }
    this.utils.updateMeta({
      BrowserTitle: `Contact Us - ${this.utils.orgTitle}`,
      Title: 'Contact Us',
      Description: `Contact ${this.utils.orgTitle}.`,
    });
  }

  ngAfterViewInit(): void {

    if (this.isBrowser) {
      setTimeout(() => {
        this.document.getElementById('loaderFrame')?.remove();
      }, 1500);
      const el = this.document.getElementById('form');
      if (el) {
        typeformEmbed.makeWidget(el, 'https://madisonmissions.typeform.com/to/e8RELK', {
          hideFooter: true,
          hideHeaders: true,
          opacity: 0,
        });
      }
    }

  }

}
