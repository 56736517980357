<div class="social-summary">
    <style type="text/css">
        .st0 {
            fill: #EDEDED;
            stroke: #231F20;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }
        
        .st0.selected {
            fill: #ff8d4b;
            stroke: #7d3c25;
        }
        
        .st1 {
            fill: none;
            stroke: #231F20;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
        }
    </style>
    <button mat-button class="comments btn" (click)="open()">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18.4px" height="15.4px" viewBox="0 0 18.4 15.4" style="enable-background:new 0 0 18.4 15.4;" xml:space="preserve">
        <defs>
        </defs>
        <g>
        <polygon class="st0" points="17.9,11.9 8.8,11.9 5.3,14.9 5.3,11.9 0.5,11.9 0.5,0.5 17.9,0.5 	"/>
        <line class="st1" x1="4.3" y1="3.5" x2="11.1" y2="3.5"/>
        <line class="st1" x1="4.3" y1="6" x2="14.1" y2="6"/>
        <line class="st1" x1="4.3" y1="8.3" x2="14.1" y2="8.3"/>
        </g>
        </svg>
        <div class="num">{{ post.Comments || 0 }}</div>
    </button>
    <button mat-button class="likes btn" (click)="like()">
        <div class="svg">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18.4px" height="16.3px" viewBox="0 0 18.4 16.3" style="enable-background:new 0 0 18.4 16.3;" xml:space="preserve">
        <defs>
        </defs>
        <g>
        <path class="st0" [ngClass]="{selected: db.likedPosts[(post.ID || '')]}" d="M15.2,11.3L15.2,11.3c0.6,0,1.2,0.3,1.2,1.2c0,0.6-0.6,0.9-1.2,0.9h-0.9c0.6,0,1.2,0.3,1.2,0.9
        c0,0.6-0.3,0.9-0.9,0.9c0,0-3.2,0-5.1,0c-2.2,0-2.8-0.5-5.4-0.9V8C6,8,9.2,4.5,9.2,1.5c0-1.2,1.7-1.6,2.3,0.5
        c0.4,1.3-0.8,4.2-0.8,4.2h6c0.6,0,1.2,0.6,1.2,1.2c0,0.6-0.4,1.5-1.2,1.5h-0.9c0.8,0,1.5,0.6,1.5,1.2c0,0.6-0.6,1.2-1.2,1.2H15.2"
        />
        <polygon class="st0" [ngClass]="{selected: db.likedPosts[(post.ID || '')]}" points="4.1,15.8 0.5,15.8 0.5,6.8 4.1,6.8 	"/>
        <g>
        <path class="st1" d="M2.8,14c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4S3,14,2.8,14L2.8,14z"/>
        </g>
        </g>
        </svg>
        </div>
        <div class="num">{{ post.Likes }}</div>
    </button>
    <button mat-button class="share btn" [matMenuTriggerFor]="shareMenu">
        <div class="svg">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 18.4 15.4" style="enable-background:new 0 0 18.4 15.4;" xml:space="preserve">
            <path class="st0" d="M17.2,7.7l-6.1-6.1v3.2c-9.2,2.2-8.5,9-8.5,9s3-3.6,8.5-4.2v4.2L17.2,7.7z"/>
            </svg>
        </div>
        <div class="num">SHARE</div>
    </button>
    <button mat-button class="author btn" *ngIf="post.authorObj" (click)="viewAuthor()">
        <img [src]="post.authorObj?.Image + '?mask=corners&corner-radius=150,150,150,150&crop=faces&h=50&w=50&fit=facearea&facepad=2.1&auto=format'" alt="">
        <span>by {{ (post.authorObj?.Name?.split(' ') || [])[0] || 'Unkown' }}</span>
    </button>
</div>

<mat-menu #shareMenu="matMenu">
    <button mat-menu-item (click)="copyLink()">
        <mat-icon>content_copy</mat-icon>
        Copy Link
    </button>
    <button mat-menu-item (click)="shareToFacebook()">
        <mat-icon>facebook</mat-icon>
        Post on Facebook
    </button>
    <button mat-menu-item (click)="shareToEmail()">
        <mat-icon>email</mat-icon>
        Email to a Friend
    </button>
</mat-menu>