<p>{{ label }}</p>
<div class="providers">
    <button mat-stroked-button aria-label="Login with Facebook" (click)="openFacebook()">
        <img src="https://cdn2.tda.website/beehive/20200826/Facebook-logo.svg" alt=""> <span>Facebook</span>
    </button>
    <button mat-stroked-button aria-label="Login with Google" (click)="openGoogle()">
        <img src="https://cdn2.tda.website/beehive/20200826/Google-logo.svg" alt=""> <span>Google</span>
    </button>
    <button mat-stroked-button aria-label="Login with a Madison Account" (click)="openMadison()">
        <img src="https://cdn2.tda.website/beehive/20200820/Madison-dk-sq.svg" alt=""> <span>Madison</span>
    </button>
</div>