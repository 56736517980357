import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DbService } from 'src/app/services/db.service';
import { RouteEventsService } from 'src/app/services/router-events.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WINDOW } from 'src/app/services/window.service';

let MCGalScrollPos = 0;

interface GalleryImage {
  id: string;
  path: string;
  width: number;
  height: number;
  cols: number;
  rows: number;
  color: string;
}

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit, OnDestroy, AfterViewInit {

  grid: string[][] = [];
  gap = 15;
  rowHeight = 0;
  gridStyle: { [prop: string]: string } = {};
  imageStyles: { [imageID: string]: { [prop: string]: string } } = {};
  IsGallery = true;
  slideClass: 'prev' | 'next' | '' | 'no-animate' = '';
  swipeStartX = 0;
  sliderPosition: number | null = null;
  KeyUpEventID: string | null = null;
  windowResizeEvID: string | null = null;
  baseUrl = '';
  imgixBase = 'https://cdn2.tda.website/beehive/';

  @Input() photos: Array<string | number>[] = [];

  constructor(
    private breakpoint$: BreakpointObserver,
    private utils: UtilsService,
    @Inject(WINDOW) private window: Window,
    private db: DbService,
    private route: ActivatedRoute,
    private router: Router,
    private routerEvents: RouteEventsService,
  ) {
    breakpoint$.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {

        this.chunkSize.next(2);

      } else if (result.breakpoints[Breakpoints.Small]) {

        this.chunkSize.next(3);

      }
      else if (result.breakpoints[Breakpoints.Medium]) {

        this.chunkSize.next(4);

      }
      else if (result.breakpoints[Breakpoints.Large]) {

        this.chunkSize.next(5);

      }
      else {

        this.chunkSize.next(6);

      }
    });
  }

  chunkSize = new BehaviorSubject(2);

  images: GalleryImage[] = [];
  image: GalleryImage | null = null;
  nextImage: GalleryImage | false = false;
  previousImage: GalleryImage | false = false;
  lastImageID = '';


  getImageID(): string {
    const alphabet = [...'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'];
    const str = this.lastImageID;
    let output = '';
    if (!str) {
      output = alphabet[0];
    } else if (str.match(/^[Z]+$/) !== null) {
      let len = str.length + 1;
      while (len) {
        output += alphabet[0];
        len--;
      }

    } else {

      const chars = [];
      for (const char of str) {
        chars.push(alphabet.indexOf(char));
      }
      for (let ii = chars.length - 1; ii >= 0; ii--) {
        const tmp = chars[ii];
        if (tmp >= 0 && tmp < (alphabet.length - 1)) {
          chars[ii]++;
          break;
        }
        else { chars[ii] = 0; }
      }
      for (const char of chars) {
        output += alphabet[char];
      }

    }
    this.lastImageID = output;
    return output;
  }

  previous(): void {
    if (this.previousImage) {
      this.slideClass = 'prev';
      this.transitionTo(`${this.baseUrl}/${this.previousImage.id}`);
    }
  }

  next(): void {
    if (this.nextImage) {
      this.slideClass = 'next';
      this.transitionTo(`${this.baseUrl}/${this.nextImage.id}`);
    }
  }

  transitionTo(url: string): void {
    setTimeout(() => {
      this.slideClass = 'no-animate';
      this.router.navigateByUrl(url);
      setTimeout(() => { this.slideClass = ''; }, 150);
    }, 150);
  }

  swipe(e: TouchEvent, type: 'start' | 'end' | 'move'): void {

    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const x = coord[0];

    if (type === 'start') {
      this.swipeStartX = x;
      this.slideClass = 'no-animate';
      return;
    }

    let distance = 0;
    if (x < this.swipeStartX) {
      if (x < 0) {
        distance = Math.abs(x) + this.swipeStartX;
      } else {
        distance = this.swipeStartX - x;
      }
    } else {
      distance = x - this.swipeStartX;
    }

    const direction: 'previous' | 'next' = x < this.swipeStartX ? 'next' : 'previous';
    const threshold = 100;

    if (type === 'end') {
      this.sliderPosition = null;
      this.slideClass = '';
      if (distance > threshold) {
        this[direction]();
      }
      return;
    }

    if (direction === 'next') {
      this.sliderPosition = -distance;
    } else {
      this.sliderPosition = distance;
    }

  }

  saveScrollPos(): void {
    MCGalScrollPos = this.window.scrollY;
  }

  restoreScrollPos(): void {
    if (this.window.scrollTo) {
      this.window.scrollTo(0, MCGalScrollPos);
    }
  }

  imgSrc(img: GalleryImage | null | false): string {
    const imageWidth = this.window.innerWidth + (this.window.innerWidth * 0.25);
    const imageHeight = this.window.innerHeight + (this.window.innerHeight * 0.25);
    if (img) {
      return this.imgixBase + img.path + '?w=' + imageWidth + '&h=' + imageHeight;
    }
    return '';
  }

  imgStyle(): { [prop: string]: string } {
    const screenWidth = this.window.innerWidth;
    const screenHeight = this.window.innerHeight;
    return { 'max-width': (screenWidth - (this.gap * 2)) + 'px', 'max-height': (screenHeight - (this.gap * 2)) + 'px' };
  }

  ngOnInit(): void {
    this.load();

    this.baseUrl = '/' + this.router.url.split('/')[1];

    if (!this.routerEvents.previousRoutePath.value.includes(this.baseUrl) && this.IsGallery) {
      MCGalScrollPos = 0;
    }

    this.route.params.subscribe(params => {
      if (params.id && params.id !== 'gallery') {
        this.IsGallery = false;
        this.image = this.images.find(el => el.id === params.id) || null;
        if (this.image) {
          const idx = this.images.indexOf(this.image);
          if (idx > 0) {
            this.previousImage = this.images[idx - 1];
          } else {
            this.previousImage = false;
          }
          if (idx < this.images.length - 1) {
            this.nextImage = this.images[idx + 1];
          } else {
            this.nextImage = false;
          }
        }
      } else {
        this.IsGallery = true;
        setTimeout(() => {
          this.restoreScrollPos();
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.KeyUpEventID) {
      this.utils.RemoveEventCallback('keypress', this.KeyUpEventID);
    }
    if (this.windowResizeEvID) {
      this.utils.removeResize(this.windowResizeEvID);
    }
    // MCGalScrollPos = 0;
  }

  ngAfterViewInit(): void {
    if (this.window.scrollTo) {
      this.window.scrollTo(0, 0);
    }
  }

  async load(): Promise<void> {

    this.chunkSize.subscribe(chunkSize => {
      this.makeGrid(chunkSize);
    });

    this.KeyUpEventID = this.utils.WhenEventHappens('keydown', (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        this.previous();
      } else if (e.key === 'ArrowRight') {
        this.next();
      } else if (e.key === 'Escape') {
        this.router.navigateByUrl(`${this.baseUrl}/`);
      }
    });

    // let WindowDebounceTimeout: NodeJS.Timeout | null = null;

    this.windowResizeEvID = this.utils.whenWindowResizes((ev: any) => { });

    // const palettes: any[] = [];

    // for (const img of this.imageData) {
    //   const url = img[0] + '?palette=json';
    //   const res = await this.db.http.get(url).toPromise() as any;
    //   const palette = res.dominant_colors as any;
    //   for (const k of Object.keys(palette)) {
    //     for (const kk of Object.keys(palette[k])) {
    //       if (kk !== 'hex') {
    //         palette[k][kk] = Math.round(palette[k][kk] * 255);
    //       }
    //     }
    //   }
    //   palettes.push(res.dominant_colors);
    // }

    // console.log(palettes);

  }

  makeRow(): string[] {
    const output = [];
    let len = this.chunkSize.value;
    while (len) {
      output.push('.');
      len--;
    }
    this.grid.push(output);
    return output;
  }

  getRowCoords(cells: number): {
    x: number;
    y: number;
  } {
    const grid = this.grid;
    if (!grid[0]) {
      this.makeRow();
    }
    if (grid[0].length < cells) {
      cells = grid[0].length;
    }
    let x = 0;
    let y = 0;
    let didFit = false;
    let rowIdx = 0;
    for (const row of grid) {
      let concurrentEmpty = 0;
      let colIdx = 0;
      for (const cell of row) {
        if (cell === '.') {
          concurrentEmpty++;
        } else {
          concurrentEmpty = 0;
        }
        if (concurrentEmpty >= cells) {
          didFit = true;
          x = colIdx - cells + 1;
          break;
        }
        colIdx++;
      }
      if (didFit) {
        y = rowIdx;
        break;
      }
      rowIdx++;
    }
    if (!didFit) {
      this.makeRow();
      x = 0;
      y = grid.length - 1;
    }
    return {
      x, y
    };
  }

  getColCoords(rows: number): {
    x: number;
    y: number;
  } {
    const grid = this.grid;
    let x = 0;
    let y = 0;
    let didFit = false;

    if (!grid[0]) {

      this.makeRow();

    } else {

      for (let colIdx = 0; colIdx < this.chunkSize.value; colIdx++) {
        let concurrentEmpty = 0;
        let rowIdx = 0;
        for (const row of grid) {
          const cell = row[colIdx];
          if (cell === '.') {
            concurrentEmpty++;
          } else {
            concurrentEmpty = 0;
          }
          if (concurrentEmpty >= rows) {
            didFit = true;
            y = rowIdx - rows + 1;
            break;
          }
          rowIdx++;
        }
        if (didFit) {
          x = colIdx;
          break;
        }
      }
      if (!didFit) {
        this.makeRow();
        x = 0;
        y = grid.length - 1;
      }
    }
    return {
      x, y
    };
  }

  fitInGrid(image: string, rows: number, cols: number, path: string): void {

    if (cols === rows) {
      let rowIdx = 0;
      let matched = false;
      loopGrid:
      for (const row of this.grid) {
        let colIdx = 0;
        for (const cell of row) {
          if (cell === '.') {
            this.grid[rowIdx][colIdx] = image;
            matched = true;
            break loopGrid;
          }
          colIdx++;
        }
        rowIdx++;
      }
      if (!matched) {
        this.makeRow();
        this.grid[this.grid.length - 1][0] = image;
      }
    } else if (cols >= rows) {
      const coords = this.getRowCoords(cols);
      if (cols > this.chunkSize.value) {
        cols = this.chunkSize.value;
      }
      for (let i = 0; i < cols; i++) {
        this.grid[coords.y][coords.x + i] = image;
      }
    } else {
      const coords = this.getColCoords(rows);
      for (let i = 0; i < rows; i++) {
        if (!this.grid[coords.y + i]) {
          this.makeRow();
        }
        this.grid[coords.y + i][coords.x] = image;
      }
    }
  }

  makeGrid(columns: number): void {
    this.grid.length = 0;
    let i = 0;

    const images: { [key: string]: GalleryImage } = {};

    // let row = this.makeRow(columns, grid);
    for (const im of this.photos) {

      const path = im[0] as string;
      const width = im[1] as number;
      const height = im[2] as number;
      const color = im[3] as string;
      const imageID = this.getImageID();

      let cols = 0;
      let rows = 0;

      if (width > height) {
        rows = 1;
        cols = Math.round(width / height);
      } else {
        cols = 1;
        rows = Math.round(height / width);
      }

      this.fitInGrid(imageID, rows, cols, path);

      images[imageID] = { id: imageID, cols, rows, height, width, path, color, };

      i++;
    }


    let rowIdx = 0;
    for (const row of this.grid) {
      let cellIdx = 0;
      for (const cell of row) {
        if (cell === '.') {

          let finalCandidate = '';
          const canditate1 = this.grid[rowIdx][cellIdx - 1];
          const canditate2 = this.grid[rowIdx][cellIdx + 1];
          const canditate3 = this.grid[rowIdx - 1] && this.grid[rowIdx - 1][cellIdx];
          const canditate4 = this.grid[rowIdx + 1] && this.grid[rowIdx + 1][cellIdx];
          if (canditate1 && images[canditate1] && images[canditate1].rows === 1) {
            finalCandidate = canditate1;
          } else if (canditate2 && images[canditate2] && images[canditate2].rows === 1) {
            finalCandidate = canditate2;
          } else if (canditate3 && images[canditate3] && images[canditate3].cols === 1) {
            finalCandidate = canditate3;
          } else if (canditate4 && images[canditate4] && images[canditate4].cols === 1) {
            finalCandidate = canditate4;
          }

          if (finalCandidate && images[finalCandidate]) {
            this.grid[rowIdx][cellIdx] = finalCandidate;
            images[finalCandidate].cols++;
          }
        }

        cellIdx++;
      }
      rowIdx++;
    }

    this.images = Object.values(images);

    this.getGridStyle();
    this.getImageStyles();

  }

  getGridStyle(): void {
    if (!this.window.visualViewport) {
      return;
    }
    const gap = this.gap;
    const vw = this.window.visualViewport.width;
    const numCols = this.chunkSize.value;
    this.rowHeight = (vw - (gap * (numCols + 1))) / numCols;
    const areas: string[] = [];
    for (const row of this.grid) {
      areas.push(row.join(' '));
    }
    const areasStr = `"${areas.join(`" "`)}"`;
    const cols: string[] = [];
    let num = this.chunkSize.value;
    while (num) {
      cols.push('1fr');
      num--;
    }
    const output = {
      display: 'grid',
      padding: `${gap}px`,
      'box-sizing': `border-box`,
      'grid-gap': `${gap}px`,
      'grid-template-areas': areasStr,
      'grid-template-rows': `repeat(${this.grid.length}, ${this.rowHeight}px)`,
      'grid-template-columns': cols.join(' '),
    };
    this.gridStyle = output;
  }

  getImageStyles(): void {
    for (const img of this.images) {

      const w = (this.rowHeight * img.cols) + ((img.cols - 1) * this.gap);
      const h = (this.rowHeight * img.rows) + ((img.rows - 1) * this.gap);
      const output = {
        'grid-area': img.id,
        'background-image': `url('${this.imgixBase + img.path}?w=${1.1 * w}&h=${1.1 * h}&fit=crop&crop=faces&q=25')`,
        'background-color': img.color,
        color: img.color,
      };
      this.imageStyles[img.id] = output;
    }
  }

  get linkWithWatermark(): string {
    if (this.image) {

      // alt logo: aHR0cHM6Ly90dWlsZGVyLmltZ2l4Lm5ldC9iZWVoaXZlLzIwMjAxMTIzL0Vhc3R3YXJkLUxvZ28tUG5nLTR4LnBuZz9oPTYw

      return `${this.imgixBase + this.image.path}?w=1920&h=1920&mark64=aHR0cHM6Ly90dWlsZGVyLmltZ2l4Lm5ldC9iZWVoaXZlLzIwMjAxMTIzL0Vhc3R3YXJkLUxvZ28tUG5nLXdoaXRlLTR4LnBuZz9oPTYw&mark-pad=50`;
    }
    return '';
  }

  download(): void {
    if (this.image) {
      this.window.location.href = `${this.linkWithWatermark}&dl`;
    }
  }

  share(): void {
    if (this.image) {
      const link = this.linkWithWatermark.replace('https://cdn2.tda.website/', 'http://cdn2.tda.website/');
      this.window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`);
    }
  }


}
