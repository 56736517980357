import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-login-providers-dialog',
  templateUrl: './login-providers-dialog.component.html',
  styleUrls: ['./login-providers-dialog.component.scss']
})
export class LoginProvidersDialogComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthService,
    private dialogRef: MatDialogRef<LoginProvidersDialogComponent>,
  ) { }

  onAuthChange = (loggedIn: boolean) => {
    if (loggedIn) {
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {
    this.auth.onStateChange(this.onAuthChange);
  }

  ngOnDestroy(): void {
    this.auth.removeStateChangeCallback(this.onAuthChange);
  }

}
