import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, InjectionToken, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatButtonToggleGroup } from '@angular/material/button-toggle'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { StripeCharge, StripeError } from 'src/app/models/StripeTypes'
import { AuthService } from 'src/app/services/auth.service'
import { AddressType, InvoiceRowType, InvoiceType } from 'src/app/services/DataStore'
import { DbService } from 'src/app/services/db.service'
import { StripePmtSource } from 'src/app/services/donationPmt.service'
import { StripeService } from 'src/app/services/stripe.service'
import { UtilsService } from 'src/app/services/utils.service'
import { StripeCard, StripeToken } from 'stripe-angular'

type BookIDs = 'WH' | 'GC';
type Routes = null | 'Checkout-1' | 'Checkout-2' | 'Login';

@Component({
  selector: 'app-magabooks',
  templateUrl: './magabooks.component.html',
  styleUrls: ['./magabooks.component.scss']
})
export class MagabooksComponent implements OnInit, OnDestroy {

  addressForm: FormGroup;
  currentRoute: Routes = null;
  @ViewChild('stripeCard') stripeCard!: StripeCard;
  cardToken!: StripeToken;
  invalidError: Error | null = null;
  isProcessing = false;
  MyPaymentSources: StripePmtSource[] = [];
  selectedPaymentSource: StripePmtSource | null = null;
  AddPaymentSource = false;
  stripeOptions: any = { hidePostalCode: true };
  tos = false;
  AuWideShippingCost = 30;
  isBrowser: boolean;

  selectedAddress: AddressType | null = null;
  AddAddress = false;
  MyAddresses: AddressType[] = [];

  GcCost = 8;
  GcPerBox = 18;
  WhPerBox = 30;

  total = 0;

  tiers = [
    {
      id: 'LE',
      price: 8.50,
      label: 'Literature Evangelist',
    },
    {
      id: 'NON-PROFIT',
      price: 10.00,
      label: 'Non-profit Organisation',
    },
    {
      id: 'COMMERCIAL',
      price: 15.00,
      label: 'Commercial Organisation',
    },
  ];

  cart = {
    GC: 0,
    WH: 0,
  };

  whOption = this.tiers[0];

  @ViewChild('toggleGroupGC') toggleGC!: MatButtonToggleGroup;
  @ViewChild('toggleGroupWH') toggleWH!: MatButtonToggleGroup;

  constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private stripeHelper: StripeService,
    private db: DbService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
  ) {

    this.isBrowser = isPlatformBrowser(platformId);

    this.addressForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      suburb: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      postcode: new FormControl('', [Validators.required]),
      shipping: new FormControl('AU', [Validators.required]),
    });
  }

  onAuthChange = (loggedIn: boolean) => {
    if (loggedIn && this.currentRoute === 'Login') {
      this.toDetails();
    }
    if (loggedIn && this.currentRoute === 'Checkout-2') {
      if (this.auth.person?.StripeCustomer) {
        const cards = this.auth.person.StripeCustomer.sources.data;
        this.MyPaymentSources = cards;
        if (!this.selectedPaymentSource) {
          this.selectedPaymentSource = cards[0];
        }
      }
    }
    if (loggedIn) {
      this.db.list('Address').then(AddressSet => {
        this.MyAddresses = Array.from(AddressSet);

        const adrRes = localStorage.getItem('magabook-address');
        if (adrRes !== null) {
          const adr = this.MyAddresses.find(el => el.ID === adrRes);
          if (adr) {
            this.selectedAddress = adr;
          }
        }

        if (this.MyAddresses.length && !this.selectedAddress) {
          this.selectedAddress = this.MyAddresses[0];
        }
      });
    }
  }

  remove(id: BookIDs): void {
    this.cart[id]--;
    this.updateTotal();
  }

  add(id: BookIDs): void {
    this.cart[id]++;
    this.updateTotal();
  }

  save(): void {
    localStorage.setItem('magabook-cart-items', JSON.stringify(this.cart));
    localStorage.setItem('magabook-wh-tier', this.whOption.id);
    if (this.selectedAddress) {
      localStorage.setItem('magabook-address', this.selectedAddress?.ID || '');
    }
  }

  async saveAddress(): Promise<void> {

    this.AddAddress = false;

    const AdrValue = this.addressForm.value;

    const Adr: AddressType = {
      Owner: this.auth.personID || '',
      name: this.addressForm.value.name,
      mobile: this.utils.normalizeMobile(AdrValue.phone) || '',
      address1: AdrValue.address1 || '',
      address2: AdrValue.address2 || '',
      postcode: AdrValue.postcode || '',
      state: AdrValue.state || '',
      suburb: AdrValue.suburb || '',
      instructions: '',
    };
    const res = await this.db.put('Address', Adr).then(AddressObj => {
      this.selectedAddress = AddressObj as AddressType;
      this.MyAddresses.push(this.selectedAddress);
    });
  }

  get numBoxes(): number {
    return this.cart.GC + this.cart.WH;
  }
  get GcBoxPrice(): number {
    return this.GcCost * this.GcPerBox;
  }
  get WhBoxPrice(): number {
    return this.whOption.price * this.WhPerBox;
  }
  get ShippingPerBox(): number {
    const shippingVal = this.addressForm.value.shipping;
    if (shippingVal && shippingVal === 'PICKUP') {
      return 0;
    }
    return this.AuWideShippingCost;
  }

  updateTotal(): void {
    this.total = 0;
    this.total += this.cart.GC * this.GcBoxPrice;
    this.total += this.cart.WH * (this.whOption.price * 30);
    this.total += this.numBoxes * this.ShippingPerBox;
    if (this.toggleGC) {
      this.toggleGC.writeValue('');
    }
    if (this.toggleWH) {
      this.toggleWH.writeValue('');
    }
    this.save();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addressForm.controls[controlName].hasError(errorName) || false;
  }

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: 'Magabooks - high quality books for LEs',
      Title: 'Magabooks - high quality books for LEs',
      Description: 'High-quality books designed in Australia, for Australia. Purchase online by the box at wholesale prices.',
      Image: 'https://cdn2.tda.website/beehive/20201125/MagabooksRender2.jpg' + this.utils.defaultImageMetaSettings,
    });
    const res = localStorage.getItem('magabook-cart-items');
    if (res !== null) {
      this.cart = JSON.parse(res);
    }

    const tierRes = localStorage.getItem('magabook-wh-tier') as BookIDs | null;
    if (tierRes !== null) {
      const tier = this.tiers.find(el => el.id === tierRes);
      if (tier) {
        this.whOption = tier;
      }
    }

    this.route.paramMap.subscribe(map => {
      const route = map.get('route') as Routes;
      if (route) {
        this.currentRoute = route;
        if (this.currentRoute === 'Checkout-1' && !this.auth.authChecked) {
          this.router.navigate(['/Magabooks/Login'], {
            replaceUrl: true,
          });
        } else if (this.currentRoute === 'Login' && this.auth.authChecked) {
          this.toDetails();
        } else {
          this.onAuthChange(this.auth.authChecked);
        }
      }
    });

    this.updateTotal();

    this.auth.onStateChange(this.onAuthChange);

  }

  toDetails(): void {
    this.router.navigateByUrl('/Magabooks/Checkout-1', {
      replaceUrl: true,
    });
  }

  ngOnDestroy(): void {
    this.auth.removeStateChangeCallback(this.onAuthChange);
  }

  handleStripeError(obj: any): boolean {
    if (obj.type === 'StripeCardError') {

      const Error = obj.raw as StripeError;

      this.snackBar.open('Card Error: ' + Error.message, 'Dismiss', {
        duration: 12000,
      });

      return true;

    }
    return false;
  }

  async pay(): Promise<void> {
    if (this.isProcessing) {
      return;
    }

    // Check if agreed to TOS
    if (!this.tos) {
      alert('Please agree with the privacy policy and terms of service before continuing.');
      this.isProcessing = false;
      return;
    }

    // Check/create payment source
    if (!this.selectedPaymentSource) {
      this.savePaymentSource();
    }

    const dt = new Date();

    // Create invoice
    const InvoiceID = 'MGBK-' + (
      dt.getFullYear().toString() +
      (dt.getMonth() + 1).toString() +
      dt.getDate().toString()
    ) + '-' + this.utils.randomChars(6).toUpperCase();

    const InvoiceRows: InvoiceRowType[] = [];
    let TotalTax = 0;

    if (this.cart.GC) {
      InvoiceRows.push(
        {
          Owner: this.auth.personID || '',
          Amount: this.GcBoxPrice,
          Description: `The Great Controversy - box of ${this.GcPerBox}`,
          ProductID: 'GC',
          Invoice: InvoiceID,
          Quantity: this.cart.GC,
          Tax: this.GcBoxPrice / 11,
          Sort: InvoiceRows.length,
        }
      );
      TotalTax += this.GcBoxPrice / 11;
    }
    if (this.cart.WH) {
      InvoiceRows.push(
        {
          Owner: this.auth.personID || '',
          Amount: this.WhBoxPrice,
          Description: `Wholesome Homemade - box of ${this.WhPerBox}`,
          ProductID: 'WH',
          Invoice: InvoiceID,
          Quantity: this.cart.WH,
          Tax: this.WhBoxPrice / 11,
          Sort: InvoiceRows.length,
        }
      );
      TotalTax += this.WhBoxPrice / 11;
    }
    InvoiceRows.push(
      {
        Owner: this.auth.personID || '',
        Amount: this.ShippingPerBox,
        Description: `Shipping: ${this.addressForm.value.shipping === 'AU' ? 'Australia-wide Regular Post' : 'Free Pickup'}`,
        ProductID: 'WH',
        Invoice: InvoiceID,
        Quantity: 1,
        Tax: this.ShippingPerBox / 11,
        Sort: InvoiceRows.length,
      }
    );
    TotalTax += this.WhBoxPrice / 11;

    if (!this.selectedAddress || !this.selectedPaymentSource) {
      alert('No address or payment details selected.');
      return;
    }

    const Invoice: InvoiceType = {
      Owner: this.auth.personID || '',
      InvoiceID,
      Paid: false,
      Person: this.auth.personID || '',
      Address: this.selectedAddress,
      PaymentSource: this.selectedPaymentSource,
      Status: 'Pending',
      Tax: TotalTax,
      Total: this.total,
      Template: 'Eastward',
    };

    this.db.addSchemaKey('Invoice', 'Template');

    this.isProcessing = true;

    const resbatch = await this.db.batchPut(
      [
        { type: 'Invoice', data: [Invoice] },
        { type: 'InvoiceRow', data: InvoiceRows }
      ]
    );

    const res = await this.stripeHelper.charge(this.selectedPaymentSource, InvoiceID) as StripeCharge;

    if (this.handleStripeError(res)) {
      this.isProcessing = false;
      return;
    }

    if (!res.paid) {

      console.error(res);

      this.snackBar.open('Payment error.', 'Dismiss', {
        duration: 12000,
      });

    }

    this.isProcessing = false;

    this.router.navigate(['/view-receipt'], {
      queryParams: { id: InvoiceID }
    });

  }

  async savePaymentSource(): Promise<void> {

    if (!this.selectedAddress) {
      alert('Cannot save payment source without and address');
      return;
    }

    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;

    try {

      const CardToken = await this.stripeCard.createToken({
        name: (this.selectedAddress.name || ''),
        address_city: (this.selectedAddress.suburb || ''),
        address_line1: (this.selectedAddress.address1 || ''),
        address_line2: (this.selectedAddress.address2 || '') || '',
        address_state: (this.selectedAddress.state || ''),
        address_zip: (this.selectedAddress.postcode || '').toString(),
      });

      if (CardToken) {

        const CardID = CardToken.card.id;

        const res = await this.stripeHelper.saveCard(CardToken, {
          line1: (this.selectedAddress.address1 || ''),
          city: (this.selectedAddress.suburb || ''),
          line2: (this.selectedAddress.address2 || '') || '',
          state: (this.selectedAddress.state || ''),
          country: 'AU',
          postal_code: (this.selectedAddress.postcode || '').toString(),
        }) as any;

        if (!this.handleStripeError(res)) {

          this.auth.person = res.Person;
          this.MyPaymentSources = this.auth.person?.StripeCustomer?.sources.data || [];

          this.selectedPaymentSource = this.utils.find(this.MyPaymentSources, 'id', CardID);

          this.AddPaymentSource = false;

          this.isProcessing = false;

        } else {
          this.isProcessing = false;
        }

      } else {

        this.snackBar.open('Invalid payment details.', 'Dismiss', {
          duration: 6000,
        });

        this.isProcessing = false;
      }

    } catch (e) {

      this.isProcessing = false;

      if ((e as any).message) {
        this.snackBar.open('ERROR: ' + (e as any).message, 'Dismiss', {
          duration: 12000,
        });
      } else {
        this.snackBar.open('ERROR: Card error.', 'Dismiss', {
          duration: 12000,
        });
      }

    }

  }

}
