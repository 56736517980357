<div class="flag">

</div>
<div class="content">
    <h1>Together we can change the world.</h1>
    <p>Join the ambassador program and receive FREE &amp; exclusive resources.</p>
    <p>
        <a routerLink="/Ambassadors" mat-stroked-button>
            MORE INFO
            <mat-icon>arrow_right</mat-icon>
        </a>
    </p>
</div>