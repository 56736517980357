import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef, AfterViewInit, Input, Optional } from '@angular/core';
import { MadisonBlogPostType, CategoryMeta, setTimeout$ } from 'src/app/services/DataStore';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DbService } from 'src/app/services/db.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { MyNotificationsComponent } from 'src/app/screens/my-notifications/my-notifications.component';
import { LoginProvidersDialogComponent } from 'src/app/dialogs/login-providers-dialog/login-providers-dialog.component';
import { WINDOW } from 'src/app/services/window.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { OpenPostImageComponent } from '../open-post-image/open-post-image.component';
import { Subscription } from 'rxjs';
import * as Hls from 'hls.js';

@Component({
  selector: 'app-news-overlay',
  templateUrl: './news-overlay.component.html',
  styleUrls: ['./news-overlay.component.scss']
})
export class NewsOverlayComponent implements OnInit, OnDestroy, AfterViewInit {

  imgSettings = '?h=1080&w=1080&auto=format&q=10&crop=faces';
  mouseMoveEvID = '';
  keypressEvID = '';
  settingTimeout = false;
  lastSentActivity = 0;
  sendActivityInterval = 1000 * 30; // 30 seconds
  categoryMeta = CategoryMeta;
  imageDialog: MatDialogRef<OpenPostImageComponent, any> | null = null;
  routerSub: Subscription | null = null;
  isOverlay = true;

  basePath = '/news-post/';

  @ViewChild('video', { static: false }) videoEl!: ElementRef;
  @ViewChild('html', { static: false }) HtmlContentEl!: ElementRef;
  @Input() postObj!: MadisonBlogPostType;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public post: MadisonBlogPostType,
    @Optional() public dialogRef: MatDialogRef<NewsOverlayComponent>,
    private db: DbService,
    private utils: UtilsService,
    private auth: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(WINDOW) private window: Window,
  ) {
  }

  ngAfterViewInit(): void {
    if (
      typeof this.post.Videos !== 'undefined' &&
      this.post.Videos &&
      typeof this.post.Videos[0] !== 'undefined'
    ) {
      const video = this.post.Videos[0];
      if (typeof video.PlaybackIDs !== 'undefined') {
        const VideoEl = this.videoEl.nativeElement as HTMLVideoElement;
        const playbackId = video.PlaybackIDs[0].id;
        const url = `https://stream.mux.com/${playbackId}.m3u8`;
        // Let native HLS support handle it if possible
        if (VideoEl.canPlayType && VideoEl.canPlayType('application/vnd.apple.mpegurl')) {
          VideoEl.src = url;
          VideoEl.play();
        } else if (Hls.isSupported && Hls.isSupported()) {
          // HLS.js-specific setup code
          const hls = new Hls();
          hls.loadSource(url);
          hls.attachMedia(VideoEl);
          VideoEl.play();
        }
      }
    }
  }

  ngOnInit(): void {

    if (!this.post) {
      this.post = this.postObj;
      this.isOverlay = false;
      this.basePath = '/view-post/';
    } else {
      this.basePath = this.router.url.split('news-post/')[0] + 'news-post/';
    }

    this.db.q('incrementBlogView', { id: this.post.ID });

    if (typeof this.post.ID !== 'undefined') {
      this.db.get('MadisonBlogPost', this.post.ID).then(post => {
        this.post = post;
        if (this.post.ContentHTML) {
          const el = this.HtmlContentEl.nativeElement as HTMLDivElement;
          el.innerHTML = this.post.ContentHTML;
        }
      });
    }

    this.mouseMoveEvID = this.utils.WhenEventHappens('mousemove', () => {
      this.setTimeout();
    });
    this.keypressEvID = this.utils.WhenEventHappens('keypress', () => {
      this.setTimeout();
    });

    this.route.queryParamMap.subscribe(val => {
      const img = val.get('img');
      if (!img && this.imageDialog) {
        this.imageDialog.close();
        this.imageDialog = null;
      } else if (img) {
        this.openImageDialog(img);
      }
    });

    if (this.isOverlay) {
      this.routerSub = this.router.events.subscribe((val) => {
        // see also
        if (val instanceof NavigationEnd && !val.url.includes(this.basePath)) {
          this.dialogRef.close();
        }
      });
    }
  }

  closeD(): void {
    if (this.isOverlay) {
      this.dialogRef.close();
    } else {
      this.router.navigateByUrl('/all-posts');
    }
  }

  openImageDialog(image: string): void {
    this.imageDialog = this.dialog.open(
      OpenPostImageComponent,
      {
        data: image,
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: ['full-screen', 'transparent-full']
      }
    );
    this.imageDialog.afterClosed().subscribe(() => {
      this.router.navigateByUrl(
        this.basePath + this.post.ID,
        { replaceUrl: true, }
      );
    });
  }

  ngOnDestroy(): void {
    this.utils.RemoveEventCallback('mousemove', this.mouseMoveEvID);
    this.utils.RemoveEventCallback('keypress', this.keypressEvID);
    if (this.isOverlay && this.routerSub !== null) {
      this.routerSub.unsubscribe();
    }
  }

  setTimeout(): void {
    if (this.auth.authChecked) {
      if (!this.settingTimeout) {
        this.settingTimeout = true;
        setTimeout$(() => { this.onActivity(); }, 1000);
      }
    }
  }

  onActivity(): void {
    this.settingTimeout = false;
    if (this.auth.authChecked) {
      const now = (new Date()).getTime();
      if (!this.lastSentActivity || now > this.lastSentActivity + this.sendActivityInterval) {
        this.lastSentActivity = now;
        this.db.q('blogActivity', {
          id: this.post.ID,
        });
      }
    }
  }

  openNotificationPrefs(): void {

    this.dialog.open(MyNotificationsComponent, {
      data: true,
    });

  }

  notificationPrefs(): void {

    if (this.auth.getSessionValue()) {
      this.openNotificationPrefs();
    } else {
      const ref = this.dialog.open(LoginProvidersDialogComponent, {
        width: '500px',
        autoFocus: false,
      });
      ref.afterClosed().subscribe(async val => {
        if (this.auth.authChecked) {
          this.openNotificationPrefs();
        }
      });
    }
  }

  cta(post: MadisonBlogPostType): void {
    this.window.open(post.CallToActionLink);
  }

  encodeUri(str: string): string {
    return encodeURIComponent(str);
  }

}
