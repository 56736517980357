<app-eastward-header-nav></app-eastward-header-nav>

<h1>News Updates</h1>
<cdk-virtual-scroll-viewport *ngIf="isBrowser" #viewport itemSize="200" class="card-container" [ngClass]="{hiddenCdk: isLoading}">
    <div *cdkVirtualFor="let chunk of renderer; let idx = index" class="row">
        <a [routerLink]="'/view-post/' + item.ID" *ngFor="let item of chunk; let i = index" [ngStyle]="cardStyle(item)" class="card mat-elevation-z3">
            <div class="title">{{ item.Title }}</div>
            <div class="ago">{{ ((item.CreatedAt | toDate) | timeAgo) }}</div>
        </a>
    </div>
</cdk-virtual-scroll-viewport>
<div class="card-container" *ngIf="!isBrowser">
    <div class="row" *ngFor="let chunk of renderer; let idx = index;">
        <a [routerLink]="'/view-post/' + item.ID" *ngFor="let item of chunk; let i = index" [ngStyle]="cardStyle(item)" class="card mat-elevation-z3">
            <div class="title">{{ item.Title }}</div>
            <div class="ago">{{ ((item.CreatedAt | toDate) | timeAgo) }}</div>
        </a>
    </div>
</div>

<div *ngIf="isLoading" class="card-container">
    <mat-spinner color="primary" diameter="50" style="position: absolute; top: 50%;left: 50%;transform: translate(-50%, -50%)"></mat-spinner>
</div>