import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, InjectionToken, OnInit, PLATFORM_ID } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import * as typeformEmbed from '@typeform/embed';

@Component({
  selector: 'app-feasibility-survey',
  templateUrl: './feasibility-survey.component.html',
  styleUrls: ['./feasibility-survey.component.scss']
})
export class FeasibilitySurveyComponent implements OnInit, AfterViewInit {

  isBrowser: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
    private utils: UtilsService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.utils.updateMeta({
      BrowserTitle: `Feasibility Survey`,
      Title: 'Feasibility Survey',
      Description: `We have an important survey for you which seeks your advice regarding our vision of a Madison school, the campaign, the Abide property, purchase terms etc... As we consider the next phase of our ministry, your input is invaluable.`,
    });
  }

  
  ngAfterViewInit(): void {

    if (this.isBrowser) {
      setTimeout(() => {
        this.document.getElementById('loaderFrame')?.remove();
      }, 1500);
      const el = this.document.getElementById('form');
      if (el) {
        typeformEmbed.makeWidget(el, 'https://madisonmissions.typeform.com/to/pPZKUUBq', {
          hideFooter: true,
          hideHeaders: true,
          opacity: 0,
        });
      }
    }

  }

}
