<app-eastward-header-nav></app-eastward-header-nav>
<div class="about">
    <header>
        <h1>About Eastward </h1>
        <h2>Youth ministry</h2>
    </header>
</div>

<div class="meet">
    <div class="grid-container">
        <div class="item g1"></div>
        <div class="item g2"></div>
        <div class="item g3"></div>
        <div class="item g4"></div>
        <div class="item g5"></div>
        <div class="item g6"></div>
        <div class="item g7"></div>
        <div class="item g8"></div>
        <div class="item g9"></div>
        <div class="item g10"></div>
        <div class="item g11"></div>
        <div class="item g12"></div>
        <div class="item g13"></div>
        <div class="item g14"></div>
        <div class="item g15"></div>
        <div class="item g16"></div>
        <div class="item g17"></div>
        <div class="item g18"></div>
        <div class="item g19"></div>
        <div class="item g20"></div>
    </div>
    <div class="text-container">
        <h2>About our Team</h2>
        <p>Every year many young adults and parents volunteer as staff and youth leaders at Music Camp, D'Sozo Camp, Cultivate Conference and the Literature Evangelism Programs. We are indebted to you. Together and with God's help we can change 
            lives.
        </p>
        <p>In addition there is a core team of individuals who make up the membership and administrative branch. All members are committed to supporting the growth of the Seventh-day Adventist Church here in Australia and abroad.</p>
    </div>
</div>

<div class="church">
    <div class="text">
        <h2>Supporting the Church</h2>
        <p>We are passionate followers of Jesus Christ and members of the Seventh-day Adventist Church. Our approach gives us opportunities to supply programs to individuals and to churches in a totally complementary manner. We seek to emulate Madison College
            and other institutions that have had a positive impact on SDA mission by working closely with local Conferences and churches to provide training and outreach opportunities.</p>
        <p>We support SDA churches through canvassing (LE) programs, health expos, musical concerts and community outreach (service projects, etc.) to help promote local churches and their ministry to their community.</p>
    </div>
    <div>
        <img src="https://cdn2.tda.website/beehive/20201106/DSC03558.jpg-cropped--2-.jpg?w=350" alt="">
    </div>
</div>


<div class="team-list">
    <header>
        <h1>Administrative Team</h1>
    </header>
    <div class="team">
        <!-- <div class="team-member">
            <div class="image">
                <img src="https://cdn2.tda.website/beehive/20200608/IMG-20200406-164600.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=2.8&bri=10&con=36&high=-50&sat=-25&vib=36&auto=format" alt="">
            </div>
            <div class="txt">
                <h2 class="name">Hamdessa Jarra</h2>
                <h3 class="role">President</h3>
            </div>
        </div> -->
        <div class="team-member">
            <div class="image">
                <img src="https://cdn2.tda.website/beehive/20200608/Emmanuel-Higgins.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=3.2&bri=13&con=10&auto=format" alt="">
            </div>
            <div class="txt">
                <h2 class="name">Emmanuel Higgins</h2>
                <h3 class="role">President</h3>
            </div>
        </div>
        <div class="team-member">
            <div class="image">
                <img src="https://cdn2.tda.website/beehive/20220725/Paul-Pannekoek.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=7.4&auto=format" alt="">
            </div>
            <div class="txt">
                <h2 class="name">Paul Pannekoek</h2>
                <h3 class="role">Treasurer</h3>
            </div>
        </div>
        <div class="team-member">
            <div class="image">
                <img src="https://cdn2.tda.website/beehive/20200608/Kayla-Reay.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=2.4&auto=format" alt="">
            </div>
            <div class="txt">
                <h2 class="name">Kayla Reay</h2>
                <h3 class="role">Secretary</h3>
            </div>
        </div>
        <div class="team-member">
            <div class="image">
                <img src="https://cdn2.tda.website/beehive/20200607/IMG-24f21d5160593b61a3a66f174ec2c017-V.jpg?mask=corners&corner-radius=150,150,150,150&crop=focalpoint&h=250&w=250&fit=crop&fp-x=0.81&fp-y=0.53&fp-z=1.8&bri=11&con=15&auto=format" alt="">
            </div>
            <div class="txt">
                <h2 class="name">Andy Hopkins</h2>
                <h3 class="role">Committee Member</h3>
            </div>
        </div>
        <div class="team-member">
            <div class="image">
                <img src="https://cdn2.tda.website/beehive/20200607/IMG-c6a84e15922d8c1df60bf302e108e706-V.jpg?mask=corners&corner-radius=150,150,150,150&crop=focalpoint&h=250&w=250&fit=crop&fp-x=0.19&fp-y=0.56&fp-z=1.5&auto=format" alt="">
            </div>
            <div class="txt">
                <h2 class="name">Tim Hopkins</h2>
                <h3 class="role">Committee Member</h3>
            </div>
        </div>
        <div class="team-member">
            <div class="image">
                <img src="https://cdn2.tda.website/beehive/20210923/Harry2.jpg?mask=corners&corner-radius=150,150,150,150&crop=faces&h=250&w=250&fit=facearea&facepad=4&auto=format" alt="">
            </div>
            <div class="txt">
                <h2 class="name">Harry Cole</h2>
                <h3 class="role">Committee Member</h3>
            </div>
        </div>
    </div>