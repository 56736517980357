import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toDate' })
export class StringToDatePipe implements PipeTransform {
    isInt(value: any): boolean {
        if (
            !isNaN(value) &&
            parseInt(value, 10).toString() === value &&
            !isNaN(parseInt(value, 10))
        ) {
            return true;
        } else {
            return false;
        }
    }

    transform(value: number | string): Date {
        if (typeof value === 'number') {
            value = value.toString();
        }
        const output = new Date(this.isInt(value) ? parseInt(value, 10) : value);
        return output;
    }
}
